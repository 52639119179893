import React, { useEffect, useState } from 'react'
import s from './CookiePopup.module.css'
import Button from '../../ux/button/Button'
import { Link, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

export const CookiePopup = () => {
  const [isVisible, setIsVisible] = useState(false)
  const navigate = useNavigate()

  const scrollToTop = () => {
    setTimeout(
      () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }),
      100
    )
  }

  useEffect(() => {
    const consent = Cookies.get('cookieConsent')
    if (!consent) {
      setIsVisible(true)
    }
  }, [])

  const handleConsent = () => {
    Cookies.set('cookieConsent', 'true', { expires: 365 })
    setIsVisible(false)
  }

  return (
    isVisible && (
      <div className={s.cookie__container}>
        <div className={s.cookie__content}>
          <p className={s.cookie__text}>
            Мы используем файлы cookie. Продолжая пользоваться данным сайтом, вы
            подтверждаете свое согласие на использование файлов cookie в
            соответствии с настоящим уведомлением.
          </p>
          <div className={s.cookie__buttons}>
            <Button
              className={s.button__agree}
              title="Подтвердить"
              onClick={handleConsent}
            />
            <Link to={'/cookies'} onClick={scrollToTop}>
              <Button
                className={s.button__more}
                color="white"
                title="Подробнее"
              />
            </Link>
          </div>
        </div>
      </div>
    )
  )
}
