import React from "react";
import Button from "../button/Button";

const Personal = ({ closePersonal, setChecked, language }) => {
  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "");
  }, []);

  return (
    <div onClick={closePersonal} className="personal-data">
      <div className="personal__container">
        <div
          onClick={(e) => e.stopPropagation()}
          className="personal__agreement"
        >
          <h2 className="personal__title">
            {language === "RU"
              ? "СОГЛАШЕНИЕ О ЗАЩИТЕ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЯ"
              : "AGREEMENT ON THE PROTECTION OF USER'S PERSONAL DATA"}
          </h2>
          <div className="personal__head">
            <h3 className="personal__preamble">
              {" "}
              {language === "RU"
                ? "ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ"
                : "PRIVACY POLICY"}
            </h3>
            <p className="personal__paragraph">
              {/* Настоящая Политика конфиденциальности определяет политику &nbsp;
              <strong>Frontiers</strong>, расположенной по адресу: г.
              Санкт-Петербург, Невский пр-кт, 71 (5 этаж) (далее – Администрация
              Сайта), в отношении обработки персональных данных и содержит
              сведения о реализуемых требованиях к защите персональных данных
              физических лиц, пользующихся услугами интернет-сайта
              http://frontiers.ru/ (далее – Сайт) и его сервисов (далее –
              Пользователи). */}

              {language === "RU"
                ? "Настоящая Политика конфиденциальности определяет политику  Frontier, расположенной по адресу: г. Санкт-Петербург, Невский пр-кт, 71 (5 этаж) (далее – Администрация Сайта), в отношении обработки персональных данных и содержит сведения о реализуемых требованиях к защите персональных данных физических лиц, пользующихся услугами интернет-сайта http://frontiers.ru/ (далее – Сайт) и его сервисов (далее – Пользователи)."
                : "This Privacy Policy defines the policy of Frontier, located at the address: St. Petersburg, Nevsky Prospekt, 71 (5th floor) (hereinafter referred to as the Site Administration), regarding the processing of personal data and contains information about the implemented requirements for the protection of personal data of individuals persons using the services of the Internet site http://frontiers.ru/ (hereinafter referred to as the Site) and its services (hereinafter referred to as Users)."}
            </p>
          </div>
          <div className="personal__basic">
            <h5 className="personal__subtitle">
              {" "}
              {language === "RU" ? "Основные понятия" : "BASIC CONCEPTS"}
            </h5>
            <p className="personal__paragraph">
              {language === "RU"
                ? "персональные данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу;"
                : "personal data - any information relating to a directly or indirectly identified or identifiable individual;"}
            </p>
            <p className="personal__paragraph">
              {language === "RU"
                ? "обработка персональных данных - любое действие (операция) илисовокупность действий (операций), совершаемых с использованиемсредств автоматизации или без использования таких средств сперсональными данными, включая сбор, запись, систематизацию,накопление, хранение, уточнение (обновление, изменение),извлечение, использование, передачу (распространение,предоставление, доступ), обезличивание, блокирование, удаление,уничтожение персональных данных."
                : "processing of personal data - any action (operation) or set of actions (operations) performed using automation tools or without the use of such means with personal data, including collection, recording, systematization, accumulation, storage, clarification (updating, changing), extraction, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction of personal data."}
            </p>
            <h5 className="personal__subtitle">
              {language === "RU"
                ? "Предоставление персональных данных"
                : "PROVIDING PERSONAL DATA"}
            </h5>
            <p className="personal__paragraph">
              {language === "RU"
                ? "Предоставляя нам свои персональные данные при использовании Сайта,Вы даете согласие на обработку Ваших персональных данных всоответствии с данной Политикой конфиденциальности."
                : "By providing us with your personal data when using the Site, you consent to the processing of your personal data in accordance with this Privacy Policy."}
            </p>
            <dl>
              <dt className="personal__paragraph">
                {language === "RU"
                  ? "В частности, дачей согласия на обработку Ваших персональных данных является:"
                  : "In particular, giving consent to the processing of your personal data is:"}
              </dt>
              <dd className="personal__paragraph">
                {language === "RU"
                  ? "· регистрация на Сайте посредством заполнения регистрационнойформы с указанием необходимой и, при желании, дополнительной информации, предусмотренной формой;"
                  : "· registration on the Site by filling out the registration form indicating the necessary and, if desired, additional information provided by the form;"}
              </dd>
              <dd className="personal__paragraph">
                {language === "RU"
                  ? "· подписка на Сайте на рассылку новостных и информационных материалов посредством заполнения формы с указанием необходимой информации, предусмотренной формой;"
                  : "· subscription on the Site to the newsletter of news and information materials by filling out a form indicating the necessary information provided by the form;"}
              </dd>
              <dd className="personal__paragraph">
                {language === "RU"
                  ? "· оформление на Сайте заявки на получение услуг с указанием необходимой информации, предусмотренной формой заявки;"
                  : "· filling out an application for services on the Website indicating the necessary information provided in the application form;"}
              </dd>
              <dd className="personal__paragraph">
                {language === "RU"
                  ? "· предоставление персональных данных посредством заполнения иных информационных полей на Сайте (при наличии);"
                  : "· providing personal data by filling out other information fields on the Site (if available);"}
              </dd>
              <dd className="personal__paragraph">
                {language === "RU"
                  ? "· предоставление персональных данных путем отправки электронных сообщений в адрес Администрации Сайта."
                  : "· providing personal data by sending electronic messages to the Site Administration."}
              </dd>
            </dl>
            <p className="personal__paragraph">
              {" "}
              {language === "RU"
                ? "Подписка на Сайте на рассылку новостных и информационных материалов посредством заполнения соответствующей формы является согласием Пользователя на получение от Администрации Сайта сообщений рекламно-информационного характера. Если Пользователь не желает получать рассылки от Администрации Сайта, он вправе отписаться от рассылки Администрации Сайта."
                : "Subscribing on the Site to the newsletter of news and information materials by filling out the appropriate form constitutes the User’s consent to receive advertising and informational messages from the Site Administration. If the User does not wish to receive mailings from the Site Administration, he has the right to unsubscribe from the Site Administration’s mailing list."}
            </p>
            <p className="personal__paragraph">
              {language === "RU"
                ? "Администрация Сайта обязуется не раскрывать третьим лицам и не распространять персональные данные без согласия Пользователя, если иное не предусмотрено федеральным законом."
                : "The Site Administration undertakes not to disclose or distribute personal data to third parties without the User’s consent, unless otherwise provided by federal law."}
            </p>
            <p className="personal__paragraph">
              {language === "RU"
                ? "Пользователь вправе отозвать согласие на обработку своих персональных данных, предоставленных Администрации Сайта. Для этого необходимо отправить соответствующее уведомление на адрес: nk@frontiers.ru"
                : "The user has the right to withdraw consent to the processing of his personal data provided to the Site Administration. To do this, you must send a corresponding notification to the address: nk@frontiers.ru"}
            </p>
            <h5 className="personal__subtitle">
              {language === "RU"
                ? "ЦЕЛИ СБОРА И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ"
                : "PURPOSES OF COLLECTION AND PROCESSING OF PERSONAL DATA"}
            </h5>
            <dl>
              <dt className="personal__paragraph">
                {language === "RU"
                  ? "Администрация Сайта обрабатывает Ваши персональные данные в целях, для которых они были предоставлены, в том числе:"
                  : "The Site Administration processes your personal data for the purposes for which they were provided, including:"}
              </dt>
              <dd className="personal__paragraph">
                {language === "RU"
                  ? "· для регистрации запроса Пользователя на Сайте;"
                  : "· to register the User’s request on the Site;"}
              </dd>
              <dd className="personal__paragraph">
                {language === "RU"
                  ? "· для регистрации Пользователя на Сайте для предоставления расширенного доступа к функционалу Сайта;"
                  : "· to register the User on the Site to provide expanded access to the functionality of the Site;"}
              </dd>
              <dd className="personal__paragraph">
                {language === "RU"
                  ? "· для направления Пользователю наших новостных и (или) информационных материалов, в т.ч. информации об Администрации Сайта, наших услугах, мероприятиях;"
                  : "· to send the User our news and (or) information materials, incl. information about the Site Administration, our services, events;"}
              </dd>
              <dd className="personal__paragraph">
                {language === "RU"
                  ? "· для ответа на вопросы и иные обращения Пользователей;"
                  : "· to answer questions and other requests from Users;"}
              </dd>
              <dd className="personal__paragraph">
                {language === "RU"
                  ? "· для рассмотрения запроса о предоставлении услуг Пользователю;"
                  : "· to consider a request for the provision of services to the User;"}
              </dd>
              <dd className="personal__paragraph">
                {language === "RU"
                  ? "· для рассмотрения запроса о предоставлении услуг Пользователю;"
                  : "· to consider a request for the provision of services to the User;"}
              </dd>
              <dd className="personal__paragraph">
                {language === "RU"
                  ? "· для выполнения обязанностей, установленных для Администрации Сайта законодательством Российской Федерации;"
                  : "· to fulfill the duties established for the Site Administration by the legislation of the Russian Federation;"}
              </dd>
              <dd className="personal__paragraph">
                {language === "RU"
                  ? "· для других целей с согласия Пользователя."
                  : "· for other purposes with the consent of the User."}
              </dd>
            </dl>
            <h5 className="personal__subtitle">
              {" "}
              {language === "RU"
                ? "ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ"
                : "PROTECTION OF PERSONAL INFORMATION"}
            </h5>
            <p className="personal__paragraph">
              {language === "RU"
                ? "Администрация Сайта принимает необходимые правовые, организационные и технические меры для защиты персональных данных Пользователей от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных."
                : "The Site Administration takes the necessary legal, organizational and technical measures to protect Users’ personal data from unauthorized or accidental access, destruction, modification, blocking, copying, provision, distribution of personal data, as well as from other unlawful actions in relation to personal data."}
            </p>
            <p className="personal__paragraph">
              {language === "RU"
                ? "Администрация Сайта ограничивает внутренний доступ к персональным данным Пользователей исключительно сотрудниками, которым это необходимо для выполнения своих служебных обязанностей, с предварительным ознакомлением таких сотрудников с настоящей Политикой конфиденциальности, а также нормативно-методическими документами по защите информации."
                : "The Site Administration limits internal access to Users’ personal data exclusively to employees who need it to perform their official duties, with such employees first familiarizing themselves with this Privacy Policy, as well as regulatory and methodological documents on information protection."}
            </p>
            <p className="personal__paragraph">
              {language === "RU"
                ? "При работе с персональными данными исключается бесконтрольное использование защищаемой информации."
                : "When working with personal data, uncontrolled use of protected information is excluded."}
            </p>
            <p className="personal__paragraph">
              {language === "RU"
                ? "Внешний доступ к персональным данным Пользователей ограничивается исключительно лицами (органами) и случаями, предусмотренными законодательством Российской Федерации."
                : "External access to Users’ personal data is limited exclusively to persons (bodies) and cases provided for by the legislation of the Russian Federation."}
            </p>
            <p className="personal__paragraph">
              {language === "RU"
                ? "Администрация Сайта принимает локальные акты по вопросам безопасности персональных данных и назначает лицо, ответственное за организацию обработки персональных данных."
                : "The Site Administration adopts local regulations on the security of personal data and appoints a person responsible for organizing the processing of personal data."}
            </p>
            <p className="personal__paragraph">
              {language === "RU"
                ? "Администрация Сайта определяет угрозы безопасности персональных данных при их обработке в информационных системах персональных данных, учитывает и обеспечивает сохранность машинных и иных носителей персональных данных, применяет прошедшие в установленном порядке процедуру оценки соответствия средства защиты информации, обеспечивает контроль за действиями, совершаемыми с персональными данными в информационной системе персональных данных."
                : "The Site Administration identifies threats to the security of personal data during their processing in personal data information systems, takes into account and ensures the safety of machine and other personal data media, applies information security tools that have passed the compliance assessment procedure in the prescribed manner, ensures control over actions taken with personal data in the information system. personal data system."}
            </p>
            <h5 className="personal__subtitle">
              {" "}
              {language === "RU"
                ? "ХРАНЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ"
                : "STORING PERSONAL DATA"}
            </h5>
            <p className="personal__paragraph">
              {language === "RU"
                ? "Хранение персональных данных Пользователей осуществляется Администрацией Сайта в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если иной срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является Пользователь, либо Пользователем при даче согласия на обработку персональных данных. Обрабатываемые персональные данные подлежат уничтожению либо обезличиванию по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом."
                : "The storage of personal data of Users is carried out by the Site Administration in a form that allows identifying the subject of personal data, no longer than required by the purposes of processing personal data, unless a different period for storing personal data is established by federal law, an agreement to which the User is a party, beneficiary or guarantor. , or by the User when giving consent to the processing of personal data. The processed personal data is subject to destruction or depersonalization upon achievement of the processing goals or in the event of the loss of the need to achieve these goals, unless otherwise provided by federal law."}
            </p>
            <h5 className="personal__subtitle">
              {language === "RU"
                ? "ПРЕКРАЩЕНИЕ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ"
                : "TERMINATION OF PROCESSING OF PERSONAL DATA"}
            </h5>
            <p className="personal__paragraph">
              {language === "RU"
                ? "Администрация Сайта прекращает обработку и уничтожает персональные данные в случае достижения цели обработки персональных данных или отзыва Пользователем согласия на обработку его персональных данных, если иное не предусмотрено договором, стороной которого, выгодоприобретателем или поручителем по которому является Пользователь, иным соглашением между Администрацией Сайта и Пользователем."
                : "The Site Administration stops processing and destroys personal data if the purpose of processing personal data is achieved or the User withdraws consent to the processing of his personal data, unless otherwise provided by an agreement to which the User is a party, beneficiary or guarantor, or another agreement between the Site Administration and the User."}
            </p>
            <p className="personal__paragraph">
              {language === "RU"
                ? "Администрация Сайта прекращает обработку персональных данных также при наступлении условий прекращения обработки персональных данных; в случае выявления неправомерной обработки персональных данных, если обеспечить правомерность обработки невозможно; по истечении срока действия согласия Пользователя на обработку персональных данных; в случае ликвидации Администрации Сайта; в иных случаях, предусмотренных законом."
                : "The Site Administration stops processing personal data also when the conditions for stopping the processing of personal data occur; in case of detection of unlawful processing of personal data, if it is impossible to ensure the legality of processing; upon expiration of the User’s consent to the processing of personal data; in case of liquidation of the Site Administration; in other cases provided by law."}
            </p>
          </div>
          <div onClick={closePersonal}>
            <button onClick={setChecked} className="button">
              {language === "RU" ? "Принять" : "Agree"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Personal;
