import React from "react";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_MAINPAGE } from "../../../apollo/query/query_mainPage";

export function AboutUs({ language }) {
  const { data } = useQuery(GET_MAINPAGE);
  const [mousePosition, setMousePosition] = useState("");

  const listenMousePosition = (event) => {
    setMousePosition(event.target.className);
  };

  let goUp = "";
  const goUpBlackLines = (mousePosition) => {
    if (mousePosition === "animation__text_first_part") {
      goUp = "up";
      return goUp;
    }
  };
  goUpBlackLines(mousePosition);

  return (
    <div className="about__us_main">
      <div className="animation__text_block">
        <div className="cube"></div>
        <div>
          <div
            className="animation__text"
            onMouseOver={language === "RU" ? listenMousePosition : null}
          >
            <p
              className={
                language === "RU"
                  ? goUp !== "up"
                    ? "animation__text_first_part"
                    : "animation__text_first_part animation__text_first_part_white"
                  : language === "ENG"
                  ? "animation__text_first_part"
                  : " "
              }
            >
              {language === "RU" ? "Инвестору" : " TO THE INVESTOR"} <br></br>
              {language === "RU" ? "Сложно найти" : " IT'S HARD TO FIND"}
              <br></br>
              {language === "RU"
                ? "прибыльные проекты,"
                : "PROFITABLE PROJECTS"}
            </p>
            <p
              className={
                language === "RU"
                  ? goUp !== "up"
                    ? "animation__text_second_part"
                    : "animation__text_second_part animation__text_second_part_black"
                  : language === "ENG"
                  ? "animation__text_second_part animation__text_second_part_black"
                  : " "

                // goUp !== "up"
                //   ? "animation__text_second_part"
                //   : "animation__text_second_part animation__text_second_part_black"
              }
            >
              {language === "RU"
                ? "ЕЩЕ СЛОЖНЕЕ ОПРЕДЕЛИТЬ,"
                : "IT'S EVEN HARDER"}
              <br></br> {language === "RU" ? null : `TO DETERMINE`}
              {language === "RU" ? null : <br></br>}
              {language === "RU" ? "можно ли доверять " : "IF YOU CAN TRUST"}
              <br></br>{" "}
              {language === "RU" ? "собственнику бизнеса" : "A BUSINESS OWNER"}
            </p>
          </div>
          {language === "RU" ? (
            <div className="animation__black_lines">
              <div
                className={
                  goUp !== "up"
                    ? "animation__1th_black_line"
                    : "animation__1th_black_line animation__1th_black_line_go_upp"
                }
              ></div>
              <div
                className={
                  goUp !== "up"
                    ? "animation__2th_black_line"
                    : "animation__2th_black_line animation__2th_black_line_go_upp"
                }
              ></div>
              <div
                className={
                  goUp !== "up"
                    ? "animation__3th_black_line"
                    : "animation__3th_black_line animation__3th_black_line_go_upp"
                }
              ></div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="right__part">
        <div
          className="conversation__image"
          style={{ backgroundImage: `url('${data?.page?.photoAbout}')` }}
        ></div>
        <p className="decoration_marks">“</p>
        <div className="about_us__text">
          <div className="text_paragraph_query">
            {language === "RU" ? data?.page?.about : data?.page?.aboutEn}
            <div className="second_screen__yellow_mark_first"></div>
            <div className="second_screen__yellow_mark_second"></div>
            <div className="second_screen__yellow_mark_third"></div>
            <div className="second_screen__yellow_mark_fourth"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
