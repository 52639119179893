import React, { useState } from "react";

import { ProjectCards } from "./projectCards/ProjectCards";
import { ProjectCardsSmallMobail } from "./projectCardsSmallMobail/ProjectCardsSmallMobail";
import { ProjectFullDescription } from "./projectFullDescription/ProjectFullDescription";
import { Provide } from "./provide/Provide";
import { ProvideSmallMobail } from "./provideSmallMobail/ProvideSmallMobail";
import { RangeSlider } from "./rangeSlider/RangeSlider";
import { countries } from "./DataProjects";

export function Projects({ width, language }) {
  const [handleValue, setHandleValue] = useState(0);
  const [selectedProjectCard, setSelectedProjectCard] = useState("");
  const [clickOnCard, setClickOnCard] = useState(false);
  const [errorHandleRange, setErrorHandleRange] = useState(false);
  // Массив который хранит выбранные страны
  const [selectedCountries, setSelectedCountries] = useState([countries[0]]);

  // Изменяет состояние выбранных стран

  const changeSelectedCountries = (countryName) => {
    if (selectedCountries.some((c) => c.country === countryName)) {
      setSelectedCountries(
        selectedCountries.filter((c) => c.country !== countryName)
      );
    } else {
      const newCountry = countries.find((c) => c.country === countryName);
      setSelectedCountries([...selectedCountries, newCountry]);
    }
  };

  return (
    <div>
      {width > 1024 ? (
        <div className="projects">
          <div className="projects__left_part">
            <h2 className="projects__title">
              {language === "RU" ? "Проекты" : "Projects"}
            </h2>
            <div className="projects__filter">
              <RangeSlider
                width={width}
                setHandleValue={setHandleValue}
                handleValue={handleValue}
                selectedCountries={selectedCountries}
                setSelectedCountries={setSelectedCountries}
                changeSelectedCountries={changeSelectedCountries}
                setErrorHandleRange={setErrorHandleRange}
                errorHandleRange={errorHandleRange}
                language={language}
              />
              <ProjectCards
                setSelectedProjectCard={setSelectedProjectCard}
                selectedCountries={selectedCountries}
                handleValue={handleValue}
                setClickOnCard={setClickOnCard}
                language={language}
              />
            </div>
          </div>
          <div className="projects__right_part">
            <div className="instruction_sticky_wrapper">
              {!clickOnCard ? (
                <Provide
                  errorHandleRange={errorHandleRange}
                  language={language}
                />
              ) : (
                <ProjectFullDescription
                  width={width}
                  selectedProjectCard={selectedProjectCard}
                  setClickOnCard={setClickOnCard}
                  handleValue={handleValue}
                  language={language}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="projects">
          <h2 className="projects__title">
            {language === "RU" ? "Проекты" : "Projects"}
          </h2>
          <RangeSlider
            // width={width}
            // setHandleValue={setHandleValue}
            // handleValue={handleValue}
            // setErrorHandleRange={setErrorHandleRange}
            // errorHandleRange={errorHandleRange}

            width={width}
            setHandleValue={setHandleValue}
            handleValue={handleValue}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            changeSelectedCountries={changeSelectedCountries}
            setErrorHandleRange={setErrorHandleRange}
            errorHandleRange={errorHandleRange}
            language={language}
          />
          {width > 375 ? (
            <div className="projects__cards_and_description">
              <ProjectCards
                setSelectedProjectCard={setSelectedProjectCard}
                selectedCountries={selectedCountries}
                handleValue={handleValue}
                setClickOnCard={setClickOnCard}
                language={language}
              />
              <div className="projects__instuction_and_full_information">
                {!clickOnCard ? (
                  <Provide
                    errorHandleRange={errorHandleRange}
                    language={language}
                  />
                ) : (
                  <ProjectFullDescription
                    width={width}
                    selectedProjectCard={selectedProjectCard}
                    setClickOnCard={setClickOnCard}
                    handleValue={handleValue}
                    language={language}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="projects__cards_and_description">
              <ProvideSmallMobail
                language={language}
                errorHandleRange={errorHandleRange}
              />
              <ProjectCardsSmallMobail
                language={language}
                handleValue={handleValue}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
