import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { ALL_PROJECTS_TO_CARD } from "../../../../apollo/query/query_projects";
import { LoaderWrapper } from "../../../../Pages/ProjectPage/LoaderWrapper/LoaderWrapper";
import { Link } from "react-router-dom";
import s from "./ProjectFullDescription.module.css";
import "./ProjectFullDescription.css";

export function ProjectFullDescription({
  width,
  selectedProjectCard,
  setClickOnCard,
  handleValue,
  language,
}) {
  const [showDescription, setShowDescription] = useState(false);
  const [useKey, setUseKey] = useState("");

  const findElementOnTap = (event, key) => {
    setUseKey(event.target.parentElement.parentElement.className);
    event.target.parentElement.parentElement.className === key
      ? setShowDescription(!showDescription)
      : setShowDescription(false);
  };

  const { data, loading, error } = useQuery(ALL_PROJECTS_TO_CARD);



  if (loading) {
    return <LoaderWrapper className={s.loader} />;
  }

  if (error)
    return (
      <div>
        <LoaderWrapper />
      </div>
    );

  return (
    <div>
      <p className={s.after_click__first_title}>
        {" "}
        {language === "RU" ? "О проекте" : "About the project"}
      </p>
      <div className={s.after_click__project_block}>
        {data.projects.map((elem) =>
          elem.uuid === selectedProjectCard ? (
            <div
              className={s.after_click__information_about_project}
              key={
                elem.uuid +
                "after_click__information_about_project" +
                Math.random()
              }
            >
              <div
                className={s.after_click__closer}
                onClick={() => setClickOnCard(false)}
              ></div>
              <p className={s.after_click__title}>
                {language === "RU" ? elem.name : elem.nameEn}
              </p>
              <p className={s.after_click__description}>
                {" "}
                {language === "RU" ? elem.description : elem.descriptionEn}
              </p>
              {elem.projectStatusNew === "active" ? (
                <div>
                  <div className={s.after_click__handle_invest}>
                    <p className={s.after_click__handle_invest__numbers}>
                      {Number(handleValue).toLocaleString("ru-RU") + "₽"}
                    </p>
                    <p className={s.after_click__handle_invest__description}>
                      {language === "RU"
                        ? "Размер инвестиций"
                        : "Investment size"}
                    </p>
                  </div>
                  <div className={s.after_click____income_money}>
                    <p className={s.after_click____income_money__numbers}>
                      {language === "RU"
                        ? //проверка на существование 5го в массиве порогов
                          elem?.investThresholds[4]
                          ? handleValue >= elem?.investThresholds[4].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[4]?.percent) /
                                  100
                              ).toLocaleString("ru-RU") + " ₽ в год"
                            : handleValue >= elem?.investThresholds[3].sum &&
                              handleValue < elem?.investThresholds[4].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[3]?.percent) /
                                  100
                              ).toLocaleString("ru-RU") + " ₽ в год"
                            : handleValue >= elem?.investThresholds[2].sum &&
                              handleValue < elem?.investThresholds[3].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[2]?.percent) /
                                  100
                              ).toLocaleString("ru-RU") + " ₽ в год"
                            : handleValue >= elem?.investThresholds[1].sum &&
                              handleValue < elem?.investThresholds[2].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[1]?.percent) /
                                  100
                              ).toLocaleString("ru-RU") + " ₽ в год"
                            : handleValue >= elem?.investThresholds[0].sum &&
                              handleValue < elem?.investThresholds[1].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[0]?.percent) /
                                  100
                              ).toLocaleString("ru-RU") + " ₽ в год"
                            : Number(
                                (handleValue *
                                  elem?.investThresholds[0]?.percent) /
                                  100
                              ).toLocaleString("ru-RU") + " ₽ в год"
                          : //проверка на существование 4го в массиве порогов
                          elem?.investThresholds[3]
                          ? handleValue >= elem?.investThresholds[3].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[3]?.percent) /
                                  100
                              ).toLocaleString("ru-RU") + " ₽ в год"
                            : handleValue >= elem?.investThresholds[2].sum &&
                              handleValue < elem?.investThresholds[3].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[2]?.percent) /
                                  100
                              ).toLocaleString("ru-RU") + " ₽ в год"
                            : handleValue >= elem?.investThresholds[1].sum &&
                              handleValue < elem?.investThresholds[2].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[1]?.percent) /
                                  100
                              ).toLocaleString("ru-RU") + " ₽ в год"
                            : handleValue >= elem?.investThresholds[0].sum &&
                              handleValue < elem?.investThresholds[1].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[0]?.percent) /
                                  100
                              ).toLocaleString("ru-RU") + " ₽ в год"
                            : Number(
                                (handleValue *
                                  elem?.investThresholds[0]?.percent) /
                                  100
                              ).toLocaleString("ru-RU") + " ₽ в год"
                          : //проверка на существование 3го в массиве порогов
                          elem?.investThresholds[2]
                          ? handleValue >= elem?.investThresholds[2].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[2]?.percent) /
                                  100
                              ).toLocaleString("ru-RU") + " ₽ в год"
                            : handleValue >= elem?.investThresholds[1].sum &&
                              handleValue < elem?.investThresholds[2].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[1]?.percent) /
                                  100
                              ).toLocaleString("ru-RU") + " ₽ в год"
                            : handleValue >= elem?.investThresholds[0].sum &&
                              handleValue < elem?.investThresholds[1].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[0]?.percent) /
                                  100
                              ).toLocaleString("ru-RU") + " ₽ в год"
                            : Number(
                                (handleValue *
                                  elem?.investThresholds[0]?.percent) /
                                  100
                              ).toLocaleString("ru-RU") + " ₽ в год"
                          : //проверка на существование 2го в массиве порогов
                          elem?.investThresholds[1]
                          ? handleValue >= elem?.investThresholds[1].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[1]?.percent) /
                                  100
                              ).toLocaleString("ru-RU") + " ₽ в год"
                            : handleValue >= elem?.investThresholds[0].sum &&
                              handleValue < elem?.investThresholds[1].sum
                            ? Number(
                                (handleValue *
                                  elem?.investThresholds[0]?.percent) /
                                  100
                              ).toLocaleString("ru-RU") + " ₽ в год"
                            : Number(
                                (handleValue *
                                  elem?.investThresholds[0]?.percent) /
                                  100
                              ).toLocaleString("ru-RU") + " ₽ в год"
                          : //если есть только 1, то его и берем
                            Number(
                              (handleValue *
                                elem?.investThresholds[0]?.percent) /
                                100
                            ).toLocaleString("ru-RU") + " ₽ в год"
                        : //проверка на существование 5го в массиве порогов
                        elem?.investThresholds[4]
                        ? handleValue >= elem?.investThresholds[4].sum
                          ? Number(
                              (handleValue *
                                elem?.investThresholds[4]?.percent) /
                                100
                            ).toLocaleString("ru-RU") + " (rub) per year"
                          : handleValue >= elem?.investThresholds[3].sum &&
                            handleValue < elem?.investThresholds[4].sum
                          ? Number(
                              (handleValue *
                                elem?.investThresholds[3]?.percent) /
                                100
                            ).toLocaleString("ru-RU") + " (rub) per year"
                          : handleValue >= elem?.investThresholds[2].sum &&
                            handleValue < elem?.investThresholds[3].sum
                          ? Number(
                              (handleValue *
                                elem?.investThresholds[2]?.percent) /
                                100
                            ).toLocaleString("ru-RU") + " (rub) per year"
                          : handleValue >= elem?.investThresholds[1].sum &&
                            handleValue < elem?.investThresholds[2].sum
                          ? Number(
                              (handleValue *
                                elem?.investThresholds[1]?.percent) /
                                100
                            ).toLocaleString("ru-RU") + " (rub) per year"
                          : handleValue >= elem?.investThresholds[0].sum &&
                            handleValue < elem?.investThresholds[1].sum
                          ? Number(
                              (handleValue *
                                elem?.investThresholds[0]?.percent) /
                                100
                            ).toLocaleString("ru-RU") + " (rub) per year"
                          : Number(
                              (handleValue *
                                elem?.investThresholds[0]?.percent) /
                                100
                            ).toLocaleString("ru-RU") + " (rub) per year"
                        : //проверка на существование 4го в массиве порогов
                        elem?.investThresholds[3]
                        ? handleValue >= elem?.investThresholds[3].sum
                          ? Number(
                              (handleValue *
                                elem?.investThresholds[3]?.percent) /
                                100
                            ).toLocaleString("ru-RU") + " (rub) per year"
                          : handleValue >= elem?.investThresholds[2].sum &&
                            handleValue < elem?.investThresholds[3].sum
                          ? Number(
                              (handleValue *
                                elem?.investThresholds[2]?.percent) /
                                100
                            ).toLocaleString("ru-RU") + " (rub) per year"
                          : handleValue >= elem?.investThresholds[1].sum &&
                            handleValue < elem?.investThresholds[2].sum
                          ? Number(
                              (handleValue *
                                elem?.investThresholds[1]?.percent) /
                                100
                            ).toLocaleString("ru-RU") + " (rub) per year"
                          : handleValue >= elem?.investThresholds[0].sum &&
                            handleValue < elem?.investThresholds[1].sum
                          ? Number(
                              (handleValue *
                                elem?.investThresholds[0]?.percent) /
                                100
                            ).toLocaleString("ru-RU") + " (rub) per year"
                          : Number(
                              (handleValue *
                                elem?.investThresholds[0]?.percent) /
                                100
                            ).toLocaleString("ru-RU") + " (rub) per year"
                        : //проверка на существование 3го в массиве порогов
                        elem?.investThresholds[2]
                        ? handleValue >= elem?.investThresholds[2].sum
                          ? Number(
                              (handleValue *
                                elem?.investThresholds[2]?.percent) /
                                100
                            ).toLocaleString("ru-RU") + " (rub) per year"
                          : handleValue >= elem?.investThresholds[1].sum &&
                            handleValue < elem?.investThresholds[2].sum
                          ? Number(
                              (handleValue *
                                elem?.investThresholds[1]?.percent) /
                                100
                            ).toLocaleString("ru-RU") + " (rub) per year"
                          : handleValue >= elem?.investThresholds[0].sum &&
                            handleValue < elem?.investThresholds[1].sum
                          ? Number(
                              (handleValue *
                                elem?.investThresholds[0]?.percent) /
                                100
                            ).toLocaleString("ru-RU") + " (rub) per year"
                          : Number(
                              (handleValue *
                                elem?.investThresholds[0]?.percent) /
                                100
                            ).toLocaleString("ru-RU") + " (rub) per year"
                        : //проверка на существование 2го в массиве порогов
                        elem?.investThresholds[1]
                        ? handleValue >= elem?.investThresholds[1].sum
                          ? Number(
                              (handleValue *
                                elem?.investThresholds[1]?.percent) /
                                100
                            ).toLocaleString("ru-RU") + " (rub) per year"
                          : handleValue >= elem?.investThresholds[0].sum &&
                            handleValue < elem?.investThresholds[1].sum
                          ? Number(
                              (handleValue *
                                elem?.investThresholds[0]?.percent) /
                                100
                            ).toLocaleString("ru-RU") + " (rub) per year"
                          : Number(
                              (handleValue *
                                elem?.investThresholds[0]?.percent) /
                                100
                            ).toLocaleString("ru-RU") + " (rub) per year"
                        : //если есть только 1, то его и берем
                          Number(
                            (handleValue * elem?.investThresholds[0]?.percent) /
                              100
                          ).toLocaleString("ru-RU") + " (rub) per year"}
                    </p>
                    <p className={s.after_click____income_money__description}>
                      {language === "RU"
                        ? "Доходность в год"
                        : "Profitability per year"}
                    </p>
                  </div>
                  <div className={s.after_click____income_percent}>
                    <p className={s.after_click____income_percent__numbers}>
                      {language === "RU"
                        ? elem?.investThresholds[4]
                          ? handleValue >= elem?.investThresholds[4].sum
                            ? elem?.investThresholds[4]?.percent + "% годовых"
                            : handleValue >= elem?.investThresholds[3].sum &&
                              handleValue < elem?.investThresholds[4].sum
                            ? elem?.investThresholds[3]?.percent + "% годовых"
                            : handleValue >= elem?.investThresholds[2].sum &&
                              handleValue < elem?.investThresholds[3].sum
                            ? elem?.investThresholds[2]?.percent + "% годовых"
                            : handleValue >= elem?.investThresholds[1].sum &&
                              handleValue < elem?.investThresholds[2].sum
                            ? elem?.investThresholds[1]?.percent + "% годовых"
                            : handleValue >= elem?.investThresholds[0].sum &&
                              handleValue < elem?.investThresholds[1].sum
                            ? elem?.investThresholds[0]?.percent + "% годовых"
                            : elem?.investThresholds[0]?.percent + "% годовых"
                          : //проверка на существование 4го в массиве порогов
                          elem?.investThresholds[3]
                          ? handleValue >= elem?.investThresholds[3].sum
                            ? elem?.investThresholds[3]?.percent + "% годовых"
                            : handleValue >= elem?.investThresholds[2].sum &&
                              handleValue < elem?.investThresholds[3].sum
                            ? elem?.investThresholds[2]?.percent + "% годовых"
                            : handleValue >= elem?.investThresholds[1].sum &&
                              handleValue < elem?.investThresholds[2].sum
                            ? elem?.investThresholds[1]?.percent + "% годовых"
                            : handleValue >= elem?.investThresholds[0].sum &&
                              handleValue < elem?.investThresholds[1].sum
                            ? elem?.investThresholds[0]?.percent + "% годовых"
                            : elem?.investThresholds[0]?.percent + "% годовых"
                          : //проверка на существование 3го в массиве порогов
                          elem?.investThresholds[2]
                          ? handleValue >= elem?.investThresholds[2].sum
                            ? elem?.investThresholds[2]?.percent + "% годовых"
                            : handleValue >= elem?.investThresholds[1].sum &&
                              handleValue < elem?.investThresholds[2].sum
                            ? elem?.investThresholds[1]?.percent + "% годовых"
                            : handleValue >= elem?.investThresholds[0].sum &&
                              handleValue < elem?.investThresholds[1].sum
                            ? elem?.investThresholds[0]?.percent + "% годовых"
                            : elem?.investThresholds[0]?.percent + "% годовых"
                          : //проверка на существование 2го в массиве порогов
                          elem?.investThresholds[1]
                          ? handleValue >= elem?.investThresholds[1].sum
                            ? elem?.investThresholds[1]?.percent + "% годовых"
                            : handleValue >= elem?.investThresholds[0].sum &&
                              handleValue < elem?.investThresholds[1].sum
                            ? elem?.investThresholds[0]?.percent + "% годовых"
                            : elem?.investThresholds[0]?.percent + "% годовых"
                          : //если есть только 1, то его и берем
                            elem?.investThresholds[0]?.percent + "% годовых"
                        : elem?.investThresholds[4]
                        ? handleValue >= elem?.investThresholds[4].sum
                          ? elem?.investThresholds[4]?.percent + " % per annum"
                          : handleValue >= elem?.investThresholds[3].sum &&
                            handleValue < elem?.investThresholds[4].sum
                          ? elem?.investThresholds[3]?.percent + " % per annum"
                          : handleValue >= elem?.investThresholds[2].sum &&
                            handleValue < elem?.investThresholds[3].sum
                          ? elem?.investThresholds[2]?.percent + " % per annum"
                          : handleValue >= elem?.investThresholds[1].sum &&
                            handleValue < elem?.investThresholds[2].sum
                          ? elem?.investThresholds[1]?.percent + " % per annum"
                          : handleValue >= elem?.investThresholds[0].sum &&
                            handleValue < elem?.investThresholds[1].sum
                          ? elem?.investThresholds[0]?.percent + " % per annum"
                          : elem?.investThresholds[0]?.percent + " % per annum"
                        : //проверка на существование 4го в массиве порогов
                        elem?.investThresholds[3]
                        ? handleValue >= elem?.investThresholds[3].sum
                          ? elem?.investThresholds[3]?.percent + " % per annum"
                          : handleValue >= elem?.investThresholds[2].sum &&
                            handleValue < elem?.investThresholds[3].sum
                          ? elem?.investThresholds[2]?.percent + " % per annum"
                          : handleValue >= elem?.investThresholds[1].sum &&
                            handleValue < elem?.investThresholds[2].sum
                          ? elem?.investThresholds[1]?.percent + " % per annum"
                          : handleValue >= elem?.investThresholds[0].sum &&
                            handleValue < elem?.investThresholds[1].sum
                          ? elem?.investThresholds[0]?.percent + " % per annum"
                          : elem?.investThresholds[0]?.percent + " % per annum"
                        : //проверка на существование 3го в массиве порогов
                        elem?.investThresholds[2]
                        ? handleValue >= elem?.investThresholds[2].sum
                          ? elem?.investThresholds[2]?.percent + " % per annum"
                          : handleValue >= elem?.investThresholds[1].sum &&
                            handleValue < elem?.investThresholds[2].sum
                          ? elem?.investThresholds[1]?.percent + " % per annum"
                          : handleValue >= elem?.investThresholds[0].sum &&
                            handleValue < elem?.investThresholds[1].sum
                          ? elem?.investThresholds[0]?.percent + " % per annum"
                          : elem?.investThresholds[0]?.percent + " % per annum"
                        : //проверка на существование 2го в массиве порогов
                        elem?.investThresholds[1]
                        ? handleValue >= elem?.investThresholds[1].sum
                          ? elem?.investThresholds[1]?.percent + " % per annum"
                          : handleValue >= elem?.investThresholds[0].sum &&
                            handleValue < elem?.investThresholds[1].sum
                          ? elem?.investThresholds[0]?.percent + " % per annum"
                          : elem?.investThresholds[0]?.percent + " % per annum"
                        : //если есть только 1, то его и берем
                          elem?.investThresholds[0]?.percent + " % per annum"}
                    </p>
                    <p
                      className={
                        s.after_click____income_percent__numbers_description
                      }
                    >
                      {language === "RU" ? "Доход" : "Income"}
                    </p>
                  </div>
                  <div className={s.after_click_protections}>
                    {(elem.support[0] !== undefined) &
                    (elem.support[0]?.need_to_invest < 10000001) ? (
                      <div>
                        <p className={s.after_click_protections__title}>
                          {language === "RU"
                            ? "Обеспечение по проекту:"
                            : "Project security:"}
                        </p>
                        {elem.support?.map((element) =>
                          handleValue >= +element?.need_to_invest ? (
                            <div
                              key={element.key + "after_click_protections"}
                              className={element.key}
                              onClick={(event) =>
                                findElementOnTap(event, element.key)
                              }
                            >
                              <div className={s.after_click_protections__list}>
                                <p
                                  className={s.after_click_protections__provide}
                                >
                                  {element.name}
                                </p>
                                <div
                                  className={
                                    s.after_click_protections__question
                                  }
                                ></div>
                                <p
                                  className={
                                    s.after_click_protections__provide_description
                                  }
                                  style={{
                                    display:
                                      useKey === element.key && showDescription
                                        ? "block"
                                        : "none",
                                  }}
                                >
                                  {element.provide_descriptoin}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div
                              className={s.after_click_protections__no_provide}
                              // style={{ display: element.count < 1 ? "block" : "none" }}
                              key={element.key + Math.random()}
                            >
                              {language === "RU"
                                ? "Для получения дополнительных гарантий увеличьте сумму инвестиций"
                                : "To obtain additional guarantees, increase the investment amount"}
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              ) : (
                <p className={s.after_click_stop_invest}>
                  {language === "RU"
                    ? "Привлечение средств временно приостановлено"
                    : "Raising funds is temporarily suspended"}
                </p>
              )}
              <div
                className={
                  elem.socialNetworks
                    ? s.after_click__links
                    : s.after_click__links_false
                }
              >
                {elem.socialNetworks && (
                  <a
                    className={s.after_click__telegramm}
                    href={elem.socialNetworks}
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                )}
                <Link to={elem.name} className={s.after_click__outside_link}>
                  {language === "RU" ? "Подробнее" : "More details"}
                </Link>
              </div>
            </div>
          ) : (
            <div key={elem.key + "empty" + Math.random()}></div>
          )
        )}
      </div>
    </div>
  );
}
