import React from "react";
import s from "./Container.module.css";

export const Container = ({ children, className, id }) => {
  return (
    <main className={className} id={id}>
      {children}
    </main>
  );
};
