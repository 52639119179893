import React from "react";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { Title } from "./faqTitle/TitleFaq";
import { Questions } from "./faqQuestions/Questions";
import LastScreen from "../home/lastScreen/LastScreen";
import s from "./Faq.module.css";
import { Layout } from "../../layouts/Layout";
import { useLanguage } from "../../providers/LanguageContext";

export const Faq = ({ width }) => {
  const { currentLanguage } = useLanguage();

  return (
    <Layout width={width}>
      <main className={s.faq__main}>
        <Title language={currentLanguage} />
        <Questions language={currentLanguage} />
      </main>
      <LastScreen language={currentLanguage} />
    </Layout>
  );
};
