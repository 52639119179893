import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { ALL_PROVIDE } from "../../../../apollo/query/query_projects";
import { LoaderWrapper } from "../../../../Pages/ProjectPage/LoaderWrapper/LoaderWrapper";

export function ProvideSmallMobail({ errorHandleRange, language }) {
  const [showDescription, setShowDescription] = useState(false);
  const [useKey, setUseKey] = useState("");
  const [openDescription, setOpenDescription] = useState(false);
  const [openProvide, setOpenProvide] = useState(false);

  const findElementOnTap = (event, key) => {
    setUseKey(event.target.parentElement.parentElement.className);
    event.target.parentElement.parentElement.className === key
      ? setShowDescription(!showDescription)
      : setShowDescription(false);
  };

  const { data, loading, error } = useQuery(ALL_PROVIDE);

  if (loading) {
    return <LoaderWrapper />;
  }

  if (error)
    return (
      <div>
        <LoaderWrapper />
      </div>
    );

  return (
    <div>
      <div
        className={
          openDescription || errorHandleRange
            ? "provide_small_mobail__block provide_small_mobail__block_open"
            : "provide_small_mobail__block"
        }
      >
        <div
          className="provide_small_mobail__block_visible"
          onClick={() => setOpenDescription(!openDescription)}
        >
          <p className="provide_small_mobail__block_title">
            {" "}
            {language === "RU"
              ? "Калькулятор доходности"
              : "Profitability calculator"}
          </p>
          <div
            className={
              openDescription
                ? "provide_small_mobile__arrow_to_down provide_small_mobile__arrow_to_up"
                : "provide_small_mobile__arrow_to_down"
            }
          ></div>
        </div>
        <div className="provide_small_mobail__instruction__text">
          <p>
            {language === "RU"
              ? "Двигайте ползунок или напишите сумму — калькулятор покажет, ориентировочный доход при сотрудничестве с разными проектами."
              : "Move the slider or write the amount - the calculator will show the estimated income when collaborating with different projects."}
          </p>
          <p>
            {language === "RU"
              ? "Не является офертой. Расчет приблизительный — детальные и актуальные условия сотрудничества узнавайте у представителя проекта."
              : "Not an offer. The calculation is approximate - please contact the project representative for detailed and current terms of cooperation."}
          </p>
          <p>
            {language === "RU"
              ? "Нажимайте на карточки с логотипами, чтобы увидеть подробности о каждом из проектов."
              : "Click on the logo cards to see details about each project."}
          </p>
          <p>
            {language === "RU"
              ? "Листайте страницы с проектами — стрелки находятся под карточками с логотипами."
              : "Scroll through the pages with projects - the arrows are located under the cards with logos."}
          </p>
          <p
            className={
              errorHandleRange
                ? "provide_small_mobail__instuction_with_handle_range_error"
                : ""
            }
          >
            {language === "RU"
              ? "Калькулятор указывает доходность для инвестиций до 10 млн ₽. Хотите узнать возможную доходность от большей суммы — оставьте заявку."
              : "The calculator indicates the return on investment up to 10 million rubles (107600 USD). If you want to know the possible profitability of a larger amount, leave a request."}
          </p>
        </div>
      </div>
      <div
        className={
          openProvide
            ? "provide_small_mobail__block provide_small_mobail__block_open"
            : "provide_small_mobail__block"
        }
      >
        <div
          className="provide_small_mobail__block_visible"
          onClick={() => setOpenProvide(!openProvide)}
        >
          <p className="provide_small_mobail__block_title">
            {" "}
            {language === "RU" ? "Виды обеспечения" : "Types of security"}
          </p>
          <div
            className={
              openProvide
                ? "provide_small_mobile__arrow_to_down provide_small_mobile__arrow_to_up"
                : "provide_small_mobile__arrow_to_down"
            }
          ></div>
        </div>
        <div className="provide_small_mobail__provide">
          {data.projectProcurings?.map((elem) => (
            <div
              key={elem.uuid + "provide"}
              className={elem.uuid}
              onClick={(event) => findElementOnTap(event, elem.uuid)}
            >
              <div className="provide_small_mobail__line_text">
                <p className="provide_small_mobail__text">{elem.name}</p>
                <div className="provide_small_mobail__quastion"></div>
              </div>
              <p
                className="provide_small_mobail__description"
                style={{
                  display:
                    useKey === elem.uuid && showDescription ? "block" : "none",
                }}
              >
                {elem.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
