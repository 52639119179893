import React, { useEffect } from 'react'
import s from './Cookies.module.css'
import { Layout } from '../../layouts/Layout'
import { Section } from '../ProjectPage/Section/Section'

export const Cookies = ({ width }) => {

  return (
    <Layout width={width}>
      <Section className={s.container}>
        <h2 className={s.title}>COOKIE</h2>
        <div className={s.text__block}>
          <p className={s.text}>
            Мы ис­поль­зу­ем фай­лы cookie на этом веб-сай­те. Файл cookie — это
            прос­той не­боль­шой файл, ко­то­рый отп­рав­ля­ет­ся вмес­те со
            стра­ни­ца­ми с это­го сай­та и хра­нит­ся в ва­шем бра­узе­ре на
            ва­шем жест­ком дис­ке с ва­ше­го компь­юте­ра. Мы ис­поль­зу­ем
            фай­лы cookie, что­бы пом­нить ва­ши наст­рой­ки и и
            обес­пе­чи­ва­ют бо­лее эф­фек­тив­ную ра­бо­ту сай­та. Вы мо­же­те
            отк­лю­чить эти фай­лы cookie че­рез наст­рой­ки бра­узе­ра.
            Об­ра­ти­те вни­ма­ние, что это мо­жет не­га­тив­но пов­ли­ять на
            поль­зо­ва­тель­ский ин­тер­фейс на­ше­го веб-сай­та.
          </p>
          <p className={s.text}>
            Фай­лы cookie не со­дер­жат ни­ка­кой лич­ной ин­фор­ма­ции о Вас и
            не мо­гут ис­поль­зо­вать­ся для иден­ти­фи­ка­ции от­дель­но­го
            поль­зо­ва­те­ля. Файл cookie час­то со­дер­жит уни­каль­ный
            иден­ти­фи­ка­тор, предс­тав­ля­ющий со­бой ано­ним­ный но­мер
            (ге­не­ри­ру­емый слу­чай­ным об­ра­зом), сох­ра­ня­емый на Ва­шем
            уст­рой­стве. Не­ко­то­рые фай­лы уда­ля­ют­ся по окон­ча­нии
            Ва­ше­го се­ан­са ра­бо­ты на сай­те; дру­гие ос­та­ют­ся на Ва­шем
            компь­юте­ре доль­ше.
          </p>

          <p className={s.text}>
            Че­рез наш сайт cookie раз­ме­ща­ет­ся у аме­ри­канс­кой ком­па­нии
            Google в рам­ках сер­ви­са Analytics. Мы ис­поль­зу­ем эту ус­лу­гу
            для по­лу­че­ния от­че­тов о том, как по­се­ти­те­ли ис­поль­зу­ют
            наш сайт. Google мо­жет пре­дос­та­вить эту ин­фор­ма­цию треть­им
            ли­цам, ес­ли Google юри­ди­чес­ки обя­за­на это сде­лать, или ес­ли
            третьи сто­ро­ны об­ра­ба­ты­ва­ют ин­фор­ма­цию от име­ни Google.
            На это мы не име­ем ни­ка­ко­го вли­яния.
          </p>

          <p className={s.text}>
            Ин­фор­ма­ция, со­би­ра­емая Google, ано­ним­на как мож­но боль­ше.
            IP-ад­рес ка­те­го­ри­чес­ки не за­дан. Ин­фор­ма­ция пе­ре­да­ет­ся
            Google и хра­нит­ся Google на сер­ве­рах в Со­еди­нен­ных Шта­тах.
            Вы име­ете пра­во зап­ра­ши­вать дос­туп и исп­рав­ле­ние или
            уда­ле­ние ва­ших дан­ных. Для это­го ис­поль­зуй­те
            <a
              className={s.link}
              target="_blank"
              rel="noopener noreferrer"
              href="https://safety.google/"
            >
              {' '}
              кон­такт­ную стра­ни­цу Google.
            </a>
          </p>

          <p className={s.text}>
            Вы мо­же­те уда­лять толь­ко фай­лы cookie са­мос­то­ятель­но, так
            как они хра­нят­ся на ва­шем компь­юте­ре. Об­ра­ти­тесь к
            ру­ко­водс­тву сво­его бра­узе­ра
          </p>
        </div>
      </Section>
    </Layout>
  )
}
