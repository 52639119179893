import React from "react";
import { useState, useEffect } from "react";
import s from "./Header.module.css";
import { Menu } from "./Menu/Menu";
import { Link } from "react-router-dom";
import { DropdownLanguage } from "./DropdownLanguage/DropdownLanguage";
import { MenuHeader } from "./MenuHeader/MenuHeader";
import { useLanguage } from "../../providers/LanguageContext";

export function Header({ width, language }) {
  const [openBurger, setOpenBurger] = useState(false);
  const { currentLanguage } = useLanguage();

  //Разблокировка скрола.
  useEffect(() => {
    !openBurger && (document.body.style.overflow = "unset");
  }, [openBurger]);

  const goToSection = () => {
    const element = document.getElementById("form");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header className={s.header_container} id="header">
      <div className={s.always_visible}>
        {/* <a className={s.logo} href="https://frontiers.ru/"> */}
        <Link
          className={s.logo}
          to={`/`}
          onClick={() =>
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
          }
        />

        {width > 430 ? (
          <DropdownLanguage
            className={s.header__dropdown}
            openBurger={openBurger}
          />
        ) : null}

        {/* 
        

        <button className={s.button} onClick={goToSection} href="#form">
          {currentLanguage === "RU" ? " Получить консультацию" : "Consultation"}
        </button> */}

        {/* Бургер-меню старое*/}

        {width > 1024 ? (
          <>
            <MenuHeader className={s.header__menu} />
            <button className={s.button} onClick={goToSection} href="#form">
              {currentLanguage === "RU"
                ? " Получить консультацию"
                : "Consultation"}
            </button>
          </>
        ) : (
          <>
            <div
              className={openBurger ? s.closer : s.opener}
              onClick={() => setOpenBurger(!openBurger)}
            >
              <div className={s.top_line}></div>
              <div className={s.botomn_line}></div>
            </div>
          </>
        )}
      </div>

      <Menu
        setOpenBurger={setOpenBurger}
        openBurger={openBurger}
        width={width}
        language={language}
      />
    </header>
  );
}
