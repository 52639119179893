import React from "react";
import { Loader } from "../Loader/Loader";
import s from "./LoaderWrapper.module.css";
import clsx from "clsx";

export const LoaderWrapper = ({ className }) => {
  return (
    <div className={clsx(s.loader__wrapper, className)}>
      <Loader />
    </div>
  );
};
