import React from "react";
import s from "./NewsBlock.module.css";
import { convertToDD_MMM_YYYY } from "../../helper";

export const NewsBlock = ({ elem, language }) => {
  return (
    <article className={s.flex__container}>
      <div
        className={s.img}
        style={{ backgroundImage: `url(${elem.picture})` }}
      ></div>
      <div className={s.text__block}>
        <div className={s.top__part}>
          <h3 className={s.title}>
            {" "}
            {language === "RU" ? elem.title : elem.titleEn}
          </h3>
          <p className={s.description}>
            {" "}
            {language === "RU" ? elem.decription : elem.descriptionEn}
          </p>
        </div>
        <div className={s.bottomn__part}>
          <div className={s.data_and_source}>
            <p className={s.data}>
              {convertToDD_MMM_YYYY(elem.datemarker, language)}
            </p>
            <p className={s.source}>
              {" "}
              {language === "RU" ? elem.sourse : elem.sourceEn}
            </p>
          </div>
          <a
            className={s.link}
            href={elem.link}
            target="_blank"
            rel="noreferrer"
          >
            {language === "RU" ? "читать" : "read"}
          </a>
        </div>
      </div>
    </article>
  );
};
