import React, { useEffect, useState } from "react";
import Form from "../../../ux/form/Form";
import { useQuery } from "@apollo/client";
import { GET_MAINPAGE } from "../../../apollo/query/query_mainPage";
// import './LastScreen.css'
import s from "./LastScreen.module.css";

export default function LastScreen({ language }) {
  const { data } = useQuery(GET_MAINPAGE);
  // const [text, setText] = useState("");
  // const [text1, setText1] = useState("");
  // const [text2, setText2] = useState("");
  // const [text3, setText3] = useState("");

  // useEffect(() => {
  //   if (!data) return;
  //   setText(data?.page?.quote);
  //   const text = data?.page?.quote;
  //   setText1(text.split(" ").slice(0, 8).join(" "));
  //   setText2(text.split(" ").slice(8, 10).join(" "));
  //   setText3(text.split(" ").slice(10).join(" "));
  // }, []);
  return (
    <>
      <section id="form" className={s.wrapper}>
        <div className={s.lastscreen}>
          <div className={s.lastscreen__wrapper}>
            <div className={s.lastscreen__left_content}>
              <p className={s.lastscreen__title}>
                {" "}
                {language === "RU" ? data?.page?.title : data?.page?.titleEn}
              </p>
              <p className={s.lastscreen__decoration_marks}>“</p>
              <p className={s.lastscreen__description}>
                {language === "RU"
                  ? data?.page?.quote.split(" ").slice(0, 8).join(" ")
                  : data?.page?.quoteEn.split(" ").slice(0, 8).join(" ")}
                &nbsp;
                <span className={s.lastscreen__yelow_line}>
                  {language === "RU"
                    ? data?.page?.quote?.split(" ").slice(8, 10).join(" ")
                    : data?.page?.quoteEn?.split(" ").slice(8, 10).join(" ")}
                  &nbsp;
                </span>
                {language === "RU"
                  ? data?.page?.quote?.split(" ").slice(10).join(" ")
                  : data?.page?.quoteEn?.split(" ").slice(10).join(" ")}
              </p>
            </div>
            <div className={s.lastscreen__right_content}>
              <div className={s.lastscreen__text_form}>
                <h2>
                  {language === "RU"
                    ? data?.page?.caption
                    : data?.page?.captionEn}
                </h2>
              </div>
              <Form language={language} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
