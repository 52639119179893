import React from 'react'
import { Header } from '../../components/Header/Header'
import { Footer } from '../../components/Footer/Footer'
import { PageTitle } from '../../ux/pageTitle/PageTitle'
import { LinkItem } from './LinkItem/LinkItem'
// import { Title } from "./faqTitle/TitleFaq";
// import { Questions } from "./faqQuestions/Questions";
import LastScreen from '../home/lastScreen/LastScreen'
import s from './DocumentsPage.module.css'
import { Layout } from '../../layouts/Layout'

export const DocumentsPage = () => {
  return (
    <Layout >
      <main className={s.main}>
        <PageTitle firstLineText={'Юридические'} secondLineText={'документы'} />
        <LinkItem />
      </main>
    </Layout>
  )
}
