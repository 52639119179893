export const DataContacts = [
  {
    phone: `89633083308`,
    phone_vew: `+7 963 308 3308`,
    mail: `nk@frontiers.ru`,
    address: `Санкт-Петербург, 
    Невский пр-кт, 71 (5 этаж)`,
    addressEn: `St. Petersburg, Nevsky Prospekt, 71 (5th floor)`,
    tg: `https://t.me/frontiersinvest`,
    instagramm: `https://instagram.com/frontiers_invest`,
    youtube: `https://www.youtube.com/@frontiers7452/featured  `,
  },
];
