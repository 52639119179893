import React from 'react'
import s from './MenuHeader.module.css'
import { Link } from 'react-router-dom'
import { useLanguage } from '../../../providers/LanguageContext'

export const MenuHeader = ({ className }) => {
  // const scrollToTop = () =>
  //   window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

  const scrollToTop = () => {
    setTimeout(
      () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }),
      100
    )
  }
  const { currentLanguage } = useLanguage()

  return (
    <nav className={s.navbar + ' ' + className}>
      <ul className={s.menu_list}>
        <Link className={s.list_item} to={'/'} onClick={scrollToTop}>
          <li>{currentLanguage === 'RU' ? 'О нас' : 'About'}</li>
        </Link>

        <Link className={s.list_item} to={'/projects'} onClick={scrollToTop}>
          <li>{currentLanguage === 'RU' ? 'Проекты' : 'Projects'}</li>
        </Link>
        <Link className={s.list_item} to={'/media'} onClick={scrollToTop}>
          <li>{currentLanguage === 'RU' ? 'Медиа' : 'Media'}</li>
        </Link>
        {/* <Link className={s.list_item} to={'/business'} onClick={scrollToTop}>
          <li>{currentLanguage === 'RU' ? 'Бизнесу' : 'For business'}</li>
        </Link> */}
        <Link className={s.list_item} to={'/faq'} onClick={scrollToTop}>
          <li>FAQ</li>
        </Link>
      </ul>
    </nav>
  )
}
