import emirates_img from "./img/flags/emirates_flag.svg";
import rus_img from "./img/flags/rus_flag.svg";
import indonesia_img from "./img/flags/indonesia_flag.svg";

export const DataProjects = [
  {
    key: "project1",
    title: "Поколение кофе",
    logo: "./img/projects/generation_coffee_logo.svg",
    grey_logo: "./img/projects/generation_coffee_grey_logo.svg",
    background: "./img/projects/generation_coffe_1920.webp",
    min: 300000,
    mid: 300000,
    max: 300000,
    ultra: 300000,
    income_min: 28,
    income_mid: 28,
    income_max: 28,
    income_ultra: 28,
    description:
      "Сеть автоматизированных кофеен с удобной системой учёта и минимальным процентом брака. Услугами проекта пользуются «Вкусвилл», «Сбер» и «Пятёрочка»",
    inshure: [
      {
        key: "project1_inshure1",
        count: 0,
        title: "Поколение кофе",
        need_to_invest: 300000,
        provide: "Залог оборудования",
        provide_descriptoin:
          "Компания оформляет в залог оборудование, которое будет реализовано с молотка, если возникнет задолженность",
      },
    ],
    url: "https://pokolenie.coffee/",
    stop: false,
  },
  {
    key: "project2",
    title: "&Place!",
    logo: "./img/projects/place_logo.svg",
    grey_logo: "./img/projects/place_grey_logo.svg",
    background: "./img/projects/plase_1920.webp",
    min: 500000,
    mid: 500000,
    max: 500000,
    ultra: 500000,
    income_min: 28,
    income_mid: 28,
    income_max: 28,
    income_ultra: 28,
    description:
      "Сеть коворкингов в центре Петербурга. Всегда заняты полностью — это гибкий формат для компаний, которые хотят современное рабочее место без аренды офиса",
    inshure: [
      {
        key: "project2_inshure1",
        count: 0,
        title: "&Place!",
        need_to_invest: 5000000,
        provide: "Поручительство основателя",
        provide_descriptoin:
          "Основатель верит в проект и готов отвечать лично, если что-то пойдёт не так",
      },
    ],
    url: "https://andplaceinvest.ru/ ",
    stop: false,
  },
  // {
  //   key: "project3",
  //   title: "ООО ПРОМЛЕСТОРГ",
  //   logo: "./img/projects/forest_logo.svg",
  //   grey_logo: "./img/projects/forest_grey_logo.svg",
  //   background: "./img/projects/forest_1920.webp",
  //   min: 500000,
  //   mid: 1000000,
  //   max: 1000000,
  //   ultra: 1000000,
  //   income_min: 27,
  //   income_mid: 30,
  //   income_max: 30,
  //   income_ultra: 30,
  //   description: "Лесопильное производство с понятной бизнес-моделью и быстрым масштабированием — девять новых филиалов за пять лет работы",
  //   inshure: [
  //     {
  //       key: "project3_inshure1",
  //       title: "ООО ПРОМЛЕСТОРГ",
  //       count: 0,
  //       need_to_invest: 500000,
  //       provide: "Залог товара",
  //       provide_descriptoin: "Часть товара в обороте компании. Если возникает задолженность, этот товар выходит из оборота — и продаётся с молотка"
  //     },
  //     {
  //       key: "project3_inshure2",
  //       count: 1,
  //       need_to_invest: 5000000,
  //       provide: "Залог оборудования",
  //       provide_descriptoin: "Компания оформляет в залог оборудование, которое будет реализовано с молотка, если возникнет задолженность"
  //     }
  //   ],

  //   url: "http://promlestorg-invest.ru/  "
  // },

  {
    key: "project4",
    title: "PartyTime",
    logo: "./img/projects/party_logo.svg",
    grey_logo: "./img/projects/party_grey_logo.svg",
    background: "./img/projects/party_1920.webp",
    min: 1000000,
    mid: 1000000,
    max: 1000000,
    ultra: 1000000,
    income_min: 25,
    income_mid: 25,
    income_max: 25,
    income_ultra: 25,
    description:
      "Cовременное, оборудованное мини-кинотеатрами, караоке и играми пространство для проведения частных вечеринок в Москве и Санкт-Петербурге.",
    inshure: [
      {
        key: "project4_inshure1",
        count: 0,
        title: "PartyTime",
        need_to_invest: 1100000000,
        provide: "Залог товара",
        provide_descriptoin:
          "Часть товара в обороте компании. Если возникает задолженность, этот товар выходит из оборота — и продаётся с молотка",
      },
    ],
    url: "https://t.me/andplace",
    stop: false,
  },
  {
    key: "project5",
    title: "ТПК Автопром",
    logo: "./img/projects/autoprom_logo.svg",
    grey_logo: "./img/projects/autoprom_logo_grey.svg",
    background: "./img/projects/autoprom_1920.webp",
    min: 500000,
    mid: 1000000,
    max: 3000000,
    ultra: 3000000,
    income_min: 25,
    income_mid: 28,
    income_max: 30,
    income_ultra: 30,
    description:
      "Компания занимается производством и реализацией тяжеловозных прицепов и полуприцепов. Вся техника разрабатывается собственным конструкторским бюро.",
    inshure: [
      {
        key: "project5_inshure1",
        count: 0,
        title: "ТПК Автопром",
        need_to_invest: 1100000000,
        provide: "Залог товара",
        provide_descriptoin:
          "Часть товара в обороте компании. Если возникает задолженность, этот товар выходит из оборота — и продаётся с молотка",
      },
    ],
    url: "https://tpkavtoprom.ru/",
    stop: false,
  },

  {
    key: "project6",
    title: "Cinnabon",
    logo: "./img/projects/cinnabon_logo.svg",
    grey_logo: "./img/projects/cinnabon_logo_grey.svg",
    background: "./img/projects/cinnabon_1920.webp",
    min: 30000000,
    mid: 30000000,
    max: 30000000,
    ultra: 30000000,
    income_min: 25,
    income_mid: 25,
    income_max: 25,
    income_ultra: 25,
    description:
      "Всемирно известный бренд самых вкусных булочек с корицей! Свежесть, аромат и аппетитный вид нашей выпечки вызывают неизменный восторг у людей всех возрастов в 52 странах мира!",
    inshure: [
      {
        key: "project6_inshure1",
        count: 0,
        title: "Cinnabon",
        need_to_invest: 1100000000,
        provide: "Залог товара",
        provide_descriptoin:
          "Часть товара в обороте компании. Если возникает задолженность, этот товар выходит из оборота — и продаётся с молотка",
      },
    ],
    url: "https://cinnaboninvest.ru",
    stop: true,
  },

  {
    key: "project7",
    title: "Гринвилл",
    logo: "./img/projects/greenvill_logo.svg",
    grey_logo: "./img/projects/greenvill_logo_grey.svg",
    background: "./img/projects/greenvill_1920.webp",
    min: 400000,
    mid: 750000,
    max: 750000,
    ultra: 750000,
    income_min: 23,
    income_mid: 25,
    income_max: 25,
    income_ultra: 25,
    description:
      "Компания занимается производством и переработкой сельскохозяйственной продукции собственного производства, а также ее транспортировкой, хранением и реализацией.",
    inshure: [
      {
        key: "project7_inshure1",
        count: 0,
        title: "Гринвилл",
        need_to_invest: 1100000000,
        provide: "Залог товара",
        provide_descriptoin:
          "Часть товара в обороте компании. Если возникает задолженность, этот товар выходит из оборота — и продаётся с молотка",
      },
    ],
    url: "",
    stop: false,
  },

  {
    key: "project8",
    title: "МиниСклад",
    logo: "./img/projects/sklad_logo.svg",
    grey_logo: "./img/projects/sklad_logo_gray.svg",
    background: "./img/projects/sklad_1920.webp",
    min: 300000,
    mid: 300000,
    max: 300000,
    ultra: 300000,
    income_min: 25,
    income_mid: 25,
    income_max: 25,
    income_ultra: 25,
    description:
      "Одна из первых петербургских компаний, вышедших на профессиональный рынок индивидуальных складских услуг для жителей нашего города.",
    inshure: [
      {
        key: "project8_inshure1",
        count: 0,
        title: "МиниСклад",
        need_to_invest: 1000000,
        provide: "Поручительство основателя",
        provide_descriptoin:
          "Основатель верит в проект и готов отвечать лично, если что-то пойдёт не так",
      },
    ],
    url: "https://minisklad-invest.ru/",
    stop: false,
  },

  {
    key: "project9",
    title: "НС Торг",
    logo: "./img/projects/nctorg_logo.svg",
    grey_logo: "./img/projects/nctorg_grey_logo.svg",
    background: "./img/projects/nstorg_1920.webp",
    min: 30000000,
    mid: 30000000,
    max: 30000000,
    ultra: 30000000,
    income_min: 25,
    income_mid: 25,
    income_max: 25,
    income_ultra: 25,
    description:
      "Компания производит и реализует бумагу для офисной техники под маркой «Дед Мороз». Около 50% продукции идёт на внешние рынки — благодаря сотрудничеству с Центром поддержки экспорта Омской области.",
    inshure: [
      {
        key: "project9_inshure1",
        count: 0,
        title: "НС Торг",
        need_to_invest: 1100000000,
        provide: "Залог товара",
        provide_descriptoin:
          "Часть товара в обороте компании. Если возникает задолженность, этот товар выходит из оборота — и продаётся с молотка",
      },
    ],
    url: "https://www.bumagaoffice.com/",
    stop: true,
  },
];

export const countries = [
  { country: "Россия", countryEn: "Russia", flagUrl: rus_img },
  { country: "ОАЭ", countryEn: "UAE", flagUrl: emirates_img },
  { country: "Индонезия", countryEn: "Indonesia", flagUrl: indonesia_img },
];
