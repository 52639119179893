import { useQuery } from "@apollo/client";
import { GET_PROJECT } from "../../../apollo/query/query_projects";
import { Link } from "react-router-dom";
import s from "./Banner.module.css";
import { Section } from "../Section/Section";
import { Loader } from "../Loader/Loader";

export const Banner = ({ project, language }) => {
  const { loading, error, data } = useQuery(GET_PROJECT, {
    variables: {
      filter: {
        name: project,
      },
    },
  });

  if (loading) return <Loader />;
  if (error) return <div>error</div>;

  return (
    <>
      <Section className={s.section__margin}>
        <div className={s.back__wrapper}>
          <div className={s.back__img}></div>
          <Link className={s.back__link} to="/">
            {language === "RU" ? "НАЗАД" : "BACK"}
          </Link>
        </div>
        <div className={s.banner__wholeWrapper}>
          <div className={s.banner__loader}>
            <Loader />
          </div>
          <div
            className={s.banner__wrapper}
            style={{
              backgroundImage: `url('${data.projects[0].projectImages.bannerSite}')`,
            }}
          >
            <div
              className={s.projectSlider__link__project__logo}
              style={{
                WebkitMaskImage: `url('${data.projects[0].projectImages.logo}')`,
                maskImage: `url('${data.projects[0].projectImages.logo}')`,
              }}
            ></div>
          </div>
        </div>
      </Section>
    </>
  );
};
