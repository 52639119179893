import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { ALL_PROJECTS_TO_CARD } from "../../../../apollo/query/query_projects";
import { LoaderWrapper } from "../../../../Pages/ProjectPage/LoaderWrapper/LoaderWrapper";
import s from "./ProjectCards.module.css";

export function ProjectCards({
  setClickOnCard,
  handleValue,
  setSelectedProjectCard,
  selectedCountries = [],
  language,
}) {
  const { data, loading, error } = useQuery(ALL_PROJECTS_TO_CARD);
  const [filteredProjects, setFilteredProjects] = useState([]);

  // Фильтруем страны и сортируем по активности
  useEffect(() => {
    if (data?.projects?.length > 0 && selectedCountries?.length > 0) {
      const selectedCountryNames = selectedCountries.map((c) => c.country);
      const sortedAndFilteredProjects = data.projects
        .filter((project) => selectedCountryNames.includes(project.country))
        .sort(
          (a, b) =>
            (b.projectStatusNew === "active") -
            (a.projectStatusNew === "active")
        );
      setFilteredProjects(sortedAndFilteredProjects);
    } else {
      // Если нет выбранных стран, то проекты не показываем
      setFilteredProjects([]);
    }
  }, [data, selectedCountries]);

  const clickOnProjectCard = (uuid) => {
    setSelectedProjectCard(uuid);
    setClickOnCard(true);
  };

  if (loading) {
    return <LoaderWrapper className={s.loader} />;
  }

  if (error)
    return (
      <div>
        <LoaderWrapper className={s.loader} />
      </div>
    );

  return (
    <div>
      <div className={s.projects_filter__cadrs_block}>
        {filteredProjects.map((elem) =>
          (elem?.projectStatusNew !== "archive") &
          (elem?.projectStatusNew !== "draft") ? (
            +handleValue < +elem.minInvestment ||
            elem?.projectStatusNew === "suspended" ? (
              <div
                className={s.project_filter__card}
                key={elem.uuid + "project_filter__card"}
                onClick={() => clickOnProjectCard(elem.uuid)}
              >
                <div className={s.project_filter_card__grey}>
                  <div
                    className={s.project_filter_card__grey_logo}
                    style={{
                      backgroundImage: `url('${elem.projectImages.logo}')`,
                    }}
                  ></div>
                  {elem.projectStatusNew === "active" ? (
                    <div className={s.project_filter_card__min_invest}>
                      <p className={s.project_filter_card_min_invest__numbers}>
                        {Number(elem?.minInvestment).toLocaleString("ru-RU") +
                          " ₽"}
                      </p>
                      <p
                        className={
                          s.project_filter_card_min_invest__description
                        }
                      >
                        {language === "RU"
                          ? "Мин. размер инвестиций"
                          : "Min. investment size"}
                      </p>
                    </div>
                  ) : elem.projectStatusNew === "suspended" ? (
                    <p className={s.projects_filter__cadrs_block_stop}>
                      {language === "RU"
                        ? "Привлечение средств"
                        : "Raising funds"}
                      <br></br>{" "}
                      {language === "RU"
                        ? "временно приостановлено"
                        : "is temporarily suspended"}
                    </p>
                  ) : (
                    <div
                      className={s.projects_filter__cadrs_block_archive}
                    ></div>
                  )}
                </div>
              </div>
            ) : (
              <div
                className={s.project_filter__card}
                key={elem.uuid + "project_filter__card_color"}
                onClick={() => clickOnProjectCard(elem.uuid)}
              >
                <div
                  className={s.project_filter_card__color}
                  style={{
                    backgroundImage: `url('${elem.projectImages.background}')`,
                  }}
                >
                  <div
                    className={s.project_filter_card__logo}
                    style={{
                      backgroundImage: `url('${elem.projectImages.logo}')`,
                    }}
                  ></div>
                  <div className={s.project_filter_card__income}>
                    {language === "RU" ? (
                      <p
                        className={s.project_filter_card_income_percent_numbers}
                      >
                        {
                          //проверка на существование 5го в массиве порогов
                          elem?.investThresholds[4]
                            ? handleValue >= elem?.investThresholds[4].sum
                              ? elem?.investThresholds[4]?.percent + "% годовых"
                              : handleValue >= elem?.investThresholds[3].sum &&
                                handleValue < elem?.investThresholds[4].sum
                              ? elem?.investThresholds[3]?.percent + "% годовых"
                              : handleValue >= elem?.investThresholds[2].sum &&
                                handleValue < elem?.investThresholds[3].sum
                              ? elem?.investThresholds[2]?.percent + "% годовых"
                              : handleValue >= elem?.investThresholds[1].sum &&
                                handleValue < elem?.investThresholds[2].sum
                              ? elem?.investThresholds[1]?.percent + "% годовых"
                              : handleValue >= elem?.investThresholds[0].sum &&
                                handleValue < elem?.investThresholds[1].sum
                              ? elem?.investThresholds[0]?.percent + "% годовых"
                              : elem?.investThresholds[0]?.percent + "% годовых"
                            : //проверка на существование 4го в массиве порогов
                            elem?.investThresholds[3]
                            ? handleValue >= elem?.investThresholds[3].sum
                              ? elem?.investThresholds[3]?.percent + "% годовых"
                              : handleValue >= elem?.investThresholds[2].sum &&
                                handleValue < elem?.investThresholds[3].sum
                              ? elem?.investThresholds[2]?.percent + "% годовых"
                              : handleValue >= elem?.investThresholds[1].sum &&
                                handleValue < elem?.investThresholds[2].sum
                              ? elem?.investThresholds[1]?.percent + "% годовых"
                              : handleValue >= elem?.investThresholds[0].sum &&
                                handleValue < elem?.investThresholds[1].sum
                              ? elem?.investThresholds[0]?.percent + "% годовых"
                              : elem?.investThresholds[0]?.percent + "% годовых"
                            : //проверка на существование 3го в массиве порогов
                            elem?.investThresholds[2]
                            ? handleValue >= elem?.investThresholds[2].sum
                              ? elem?.investThresholds[2]?.percent + "% годовых"
                              : handleValue >= elem?.investThresholds[1].sum &&
                                handleValue < elem?.investThresholds[2].sum
                              ? elem?.investThresholds[1]?.percent + "% годовых"
                              : handleValue >= elem?.investThresholds[0].sum &&
                                handleValue < elem?.investThresholds[1].sum
                              ? elem?.investThresholds[0]?.percent + "% годовых"
                              : elem?.investThresholds[0]?.percent + "% годовых"
                            : //проверка на существование 2го в массиве порогов
                            elem?.investThresholds[1]
                            ? handleValue >= elem?.investThresholds[1].sum
                              ? elem?.investThresholds[1]?.percent + "% годовых"
                              : handleValue >= elem?.investThresholds[0].sum &&
                                handleValue < elem?.investThresholds[1].sum
                              ? elem?.investThresholds[0]?.percent + "% годовых"
                              : elem?.investThresholds[0]?.percent + "% годовых"
                            : //если есть только 1, то его и берем
                              elem?.investThresholds[0]?.percent + "% годовых"
                        }
                      </p>
                    ) : (
                      <p
                        className={s.project_filter_card_income_percent_numbers}
                      >
                        {
                          //проверка на существование 5го в массиве порогов
                          elem?.investThresholds[4]
                            ? handleValue >= elem?.investThresholds[4].sum
                              ? elem?.investThresholds[4]?.percent +
                                "% per annum"
                              : handleValue >= elem?.investThresholds[3].sum &&
                                handleValue < elem?.investThresholds[4].sum
                              ? elem?.investThresholds[3]?.percent +
                                "% per annum"
                              : handleValue >= elem?.investThresholds[2].sum &&
                                handleValue < elem?.investThresholds[3].sum
                              ? elem?.investThresholds[2]?.percent +
                                "% per annum"
                              : handleValue >= elem?.investThresholds[1].sum &&
                                handleValue < elem?.investThresholds[2].sum
                              ? elem?.investThresholds[1]?.percent +
                                "% per annum"
                              : handleValue >= elem?.investThresholds[0].sum &&
                                handleValue < elem?.investThresholds[1].sum
                              ? elem?.investThresholds[0]?.percent +
                                "% per annum"
                              : elem?.investThresholds[0]?.percent +
                                "% per annum"
                            : //проверка на существование 4го в массиве порогов
                            elem?.investThresholds[3]
                            ? handleValue >= elem?.investThresholds[3].sum
                              ? elem?.investThresholds[3]?.percent +
                                "% per annum"
                              : handleValue >= elem?.investThresholds[2].sum &&
                                handleValue < elem?.investThresholds[3].sum
                              ? elem?.investThresholds[2]?.percent +
                                "% per annum"
                              : handleValue >= elem?.investThresholds[1].sum &&
                                handleValue < elem?.investThresholds[2].sum
                              ? elem?.investThresholds[1]?.percent +
                                "% per annum"
                              : handleValue >= elem?.investThresholds[0].sum &&
                                handleValue < elem?.investThresholds[1].sum
                              ? elem?.investThresholds[0]?.percent +
                                "% per annum"
                              : elem?.investThresholds[0]?.percent +
                                "% per annum"
                            : //проверка на существование 3го в массиве порогов
                            elem?.investThresholds[2]
                            ? handleValue >= elem?.investThresholds[2].sum
                              ? elem?.investThresholds[2]?.percent +
                                "% per annum"
                              : handleValue >= elem?.investThresholds[1].sum &&
                                handleValue < elem?.investThresholds[2].sum
                              ? elem?.investThresholds[1]?.percent +
                                "% per annum"
                              : handleValue >= elem?.investThresholds[0].sum &&
                                handleValue < elem?.investThresholds[1].sum
                              ? elem?.investThresholds[0]?.percent +
                                "% per annum"
                              : elem?.investThresholds[0]?.percent +
                                "% per annum"
                            : //проверка на существование 2го в массиве порогов
                            elem?.investThresholds[1]
                            ? handleValue >= elem?.investThresholds[1].sum
                              ? elem?.investThresholds[1]?.percent +
                                "% per annum"
                              : handleValue >= elem?.investThresholds[0].sum &&
                                handleValue < elem?.investThresholds[1].sum
                              ? elem?.investThresholds[0]?.percent +
                                "% per annum"
                              : elem?.investThresholds[0]?.percent +
                                "% per annum"
                            : //если есть только 1, то его и берем
                              elem?.investThresholds[0]?.percent + "% per annum"
                        }
                      </p>
                    )}
                    <div className={s.project_filter_card__income_money}>
                      {language === "RU" ? (
                        <p
                          className={s.project_filter_card_income_money_numbers}
                        >
                          {
                            //проверка на существование 5го в массиве порогов
                            elem?.investThresholds[4]
                              ? handleValue >= elem?.investThresholds[4].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[4]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : handleValue >=
                                    elem?.investThresholds[3].sum &&
                                  handleValue < elem?.investThresholds[4].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[3]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : handleValue >=
                                    elem?.investThresholds[2].sum &&
                                  handleValue < elem?.investThresholds[3].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[2]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : handleValue >=
                                    elem?.investThresholds[1].sum &&
                                  handleValue < elem?.investThresholds[2].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[1]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : handleValue >=
                                    elem?.investThresholds[0].sum &&
                                  handleValue < elem?.investThresholds[1].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                              : //проверка на существование 4го в массиве порогов
                              elem?.investThresholds[3]
                              ? handleValue >= elem?.investThresholds[3].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[3]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : handleValue >=
                                    elem?.investThresholds[2].sum &&
                                  handleValue < elem?.investThresholds[3].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[2]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : handleValue >=
                                    elem?.investThresholds[1].sum &&
                                  handleValue < elem?.investThresholds[2].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[1]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : handleValue >=
                                    elem?.investThresholds[0].sum &&
                                  handleValue < elem?.investThresholds[1].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                              : //проверка на существование 3го в массиве порогов
                              elem?.investThresholds[2]
                              ? handleValue >= elem?.investThresholds[2].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[2]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : handleValue >=
                                    elem?.investThresholds[1].sum &&
                                  handleValue < elem?.investThresholds[2].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[1]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : handleValue >=
                                    elem?.investThresholds[0].sum &&
                                  handleValue < elem?.investThresholds[1].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                              : //проверка на существование 2го в массиве порогов
                              elem?.investThresholds[1]
                              ? handleValue >= elem?.investThresholds[1].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[1]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : handleValue >=
                                    elem?.investThresholds[0].sum &&
                                  handleValue < elem?.investThresholds[1].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                              : //если есть только 1, то его и берем
                                Number(
                                  (handleValue *
                                    elem?.investThresholds[0]?.percent) /
                                    100
                                ).toLocaleString("ru-RU") + " ₽ в год"
                          }
                        </p>
                      ) : (
                        <p
                          className={s.project_filter_card_income_money_numbers}
                        >
                          {
                            //проверка на существование 5го в массиве порогов
                            elem?.investThresholds[4]
                              ? handleValue >= elem?.investThresholds[4].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[4]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ per year"
                                : handleValue >=
                                    elem?.investThresholds[3].sum &&
                                  handleValue < elem?.investThresholds[4].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[3]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ per year"
                                : handleValue >=
                                    elem?.investThresholds[2].sum &&
                                  handleValue < elem?.investThresholds[3].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[2]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ per year"
                                : handleValue >=
                                    elem?.investThresholds[1].sum &&
                                  handleValue < elem?.investThresholds[2].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[1]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ per year"
                                : handleValue >=
                                    elem?.investThresholds[0].sum &&
                                  handleValue < elem?.investThresholds[1].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ per year"
                                : Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ per year"
                              : //проверка на существование 4го в массиве порогов
                              elem?.investThresholds[3]
                              ? handleValue >= elem?.investThresholds[3].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[3]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ per year"
                                : handleValue >=
                                    elem?.investThresholds[2].sum &&
                                  handleValue < elem?.investThresholds[3].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[2]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ per year"
                                : handleValue >=
                                    elem?.investThresholds[1].sum &&
                                  handleValue < elem?.investThresholds[2].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[1]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ per year"
                                : handleValue >=
                                    elem?.investThresholds[0].sum &&
                                  handleValue < elem?.investThresholds[1].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ per year"
                                : Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ per year"
                              : //проверка на существование 3го в массиве порогов
                              elem?.investThresholds[2]
                              ? handleValue >= elem?.investThresholds[2].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[2]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ per year"
                                : handleValue >=
                                    elem?.investThresholds[1].sum &&
                                  handleValue < elem?.investThresholds[2].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[1]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ per year"
                                : handleValue >=
                                    elem?.investThresholds[0].sum &&
                                  handleValue < elem?.investThresholds[1].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ per year"
                                : Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ per year"
                              : //проверка на существование 2го в массиве порогов
                              elem?.investThresholds[1]
                              ? handleValue >= elem?.investThresholds[1].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[1]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ per year"
                                : handleValue >=
                                    elem?.investThresholds[0].sum &&
                                  handleValue < elem?.investThresholds[1].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ per year"
                                : Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ per year"
                              : //если есть только 1, то его и берем
                                Number(
                                  (handleValue *
                                    elem?.investThresholds[0]?.percent) /
                                    100
                                ).toLocaleString("ru-RU") + " ₽ per year"
                          }
                        </p>
                      )}
                    </div>
                    <div className={s.project_filter_card__arrow_to_down}></div>
                  </div>
                </div>
              </div>
            )
          ) : (
            <div
              className={s.projects_filter__cadrs_block_archive}
              key={elem.uuid + "project_filter__card_archive"}
            ></div>
          )
        )}
      </div>
    </div>
  );
}
