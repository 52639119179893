import React from "react";
import s from "./TitleFaq.module.css";

export const Title = ({ language }) => {
  return (
    <>
      <h2 className={s.big_faq}>FAQ</h2>
      <div className={s.title_description}>
        <p className={s.title_description__first_line}>
          {" "}
          {language === "RU" ? "В этом разделе" : " IN THIS SECTION"}
        </p>
        <p className={s.title_description__second_line}>
          {" "}
          {language === "RU"
            ? "вы найдете ответы"
            : "YOU WILL FIND THE ANSWERS"}
        </p>
        <p className={s.title_description__third_line}>
          {language === "RU"
            ? "на часто задаваемые вопросы"
            : "ON THE FREQUENTLY ASKED QUESTIONS"}
        </p>
      </div>
    </>
  );
};
