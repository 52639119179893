import React from "react";
import { MediaSectionTitle } from "../MediaSectionTItle/MediaSectionTitle";
import { Section } from "../../ProjectPage/Section/Section";
import s from "./EventsSection.module.css";
import { VideoSliderBottom } from "../VideoSliderBottom/VideoSliderBottom";

export const EventsSection = ({
  width,
  clientWidth,
  isTurnDevice,
  language,
}) => {
  return (
    <>
      <Section className={s.wrapper}>
        <MediaSectionTitle
          text1={
            language === "RU"
              ? "контент с мероприятиями"
              : " CONTENT FROM EVENTS"
          }
          text2={
            language === "RU"
              ? "И МИНИ-ОТЧЕТЫ О ПРОЕКТАХ"
              : "AND MINI-REPORTS ABOUT PROJECTS"
          }
        />
        <VideoSliderBottom
          width={width}
          clientWidth={clientWidth}
          isTurnDevice={isTurnDevice}
          language={language}
        />
      </Section>
    </>
  );
};
