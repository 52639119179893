import React from "react";
import s from "./SliderNavigator.module.css";
import clsx from "clsx";

export const SliderNavigator = ({
  className,
  prevHandler,
  nextHandler,
  counterPage,
  arrLength,
}) => {
  return (
    <div className={clsx(s.navigation, className)}>
      <div className={s.navigation_prevBtn} onClick={prevHandler}>
        <div className={s.navigation_prevBtn_image}></div>
      </div>
      <div className={s.navigation__values}>
        <span>{counterPage}</span>
        <span>/</span>
        <span>{arrLength}</span>
      </div>
      <div className={s.navigation_nextBtn} onClick={nextHandler}>
        <div className={s.navigation_nextBtn_image}></div>
      </div>
    </div>
  );
};
