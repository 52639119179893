import React from "react";
import { useQuery } from "@apollo/client";
import { GET_PROJECT } from "../../../apollo/query/query_projects";
import { Section } from "../Section/Section";
import { SectionTitle } from "../SectionTitle/SectionTitle";
import { Loader } from "../Loader/Loader";
import s from "./AboutProject.module.css";
import clsx from "clsx";

export const AboutProject = ({ project, language }) => {
  const { loading, error, data } = useQuery(GET_PROJECT, {
    variables: {
      filter: {
        name: project,
      },
    },
  });

  if (loading) return <Loader />;
  if (error) return <div>error</div>;

  return (
    <Section className={s.section__margin__bottom}>
      <SectionTitle
        text={language === "RU" ? "О проекте" : "About the project"}
      />
      <div className={s.about__wrapper}>
        <div className={s.about__text}>
          <p className={s.about__text_paragraph}>
            {language === "RU"
              ? data.projects[0].fullDescription
              : data.projects[0].fullDescriptionEn}
          </p>
        </div>
        <div className={s.about__income}>
          {data.projects[0].minInterest && (
            <div className={s.about__income__numWrapper}>
              <h3 className={s.about__income_numbers}>
                {language === "RU"
                  ? `${data.projects[0].minInterest} % годовых`
                  : `${data.projects[0].minInterest} % per year`}
              </h3>
              <span className={s.about__income_numbersDescription}>
                {language === "RU"
                  ? "доходность компании"
                  : "profitability of the company"}
              </span>
            </div>
          )}

          {data.projects[0].income && (
            <div className={s.about__income__numWrapper}>
              <h3 className={s.about__income_numbers}>
                <div>
                  <div>
                    {(+data.projects[0].income).toLocaleString("ru-RU") + " ₽"}
                  </div>
                </div>
              </h3>
              <span className={s.about__income_numbersDescription}>
                {language === "RU"
                  ? "Уже заработали инвесторы"
                  : "Investors have already earned money"}
              </span>
            </div>
          )}

          <div
            className={clsx(
              s.about__income__numWrapper,
              s.about__income__numWrapper_invest
            )}
          >
            {data.projects[0].minInvestment && (
              <h3 className={s.about__income_numbers}>
                {(+data.projects[0].minInvestment).toLocaleString("ru-RU") +
                  " ₽"}
              </h3>
            )}
            <span className={s.about__income_numbersDescription}>
              {language === "RU"
                ? "Мин. сумма инвестиций"
                : "Min. amount of investment"}
            </span>
          </div>
        </div>
      </div>
    </Section>
  );
};
