import { gql } from "@apollo/client";

export const GET_MAINPAGE = gql`
  query getMainPage {
    page {
      id
      button
      heading
      headingEn
      photo
      about
      aboutEn
      photoAbout
      title
      titleEn
      quote
      quoteEn
      caption
      captionEn
      leftText
      leftTextEn
    }
  }
`;
