import React from "react";
import RightArrow from "./right-arrow.svg";
import s from "./LinkItem.module.css";
import { data } from "./data";

export const LinkItem = () => {
  return (
    <>
      <ol className={s.wrapper}>
        {data.map((el) => (
          <li className={s.container} key={el.orederNum}>
            <div className={s.item_inner_wrapper}>
              <div className={s.item_number}>{"0".concat(el.orederNum)}</div>
              <p className={s.item_text}>{el.title}</p>
            </div>
            <a className={s.item_link} href={el.href} download>
              <img className={s.item_img} src={RightArrow} alt="Скачать" />
            </a>
          </li>
        ))}
      </ol>
    </>
  );
};
