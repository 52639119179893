import React, { useState } from "react";
import s from "./TextCard.module.css";

export const TextCard = ({
  picture,
  source,
  name,
  text,
  index,
  isOpen,
  date,
  textLength,
  openFullText,
  language,
}) => {
  // const [firstLetter] = useState(name.split("")[0]);
  const [firstLetter] = useState(name.split("")[0]);
  const [secondLetter] = useState(
    name?.split(" ")[1] === undefined ? "" : name?.split(" ")[1]?.split("")[0]
  );
  const [firstRandom] = useState(Math.floor(Math.random() * 3));
  const [secondRandom] = useState(Math.floor(Math.random() * 3));

  const colors = ["#e66465", "#9198e5", "#c2a2e8"];
  const colors2 = ["#d07b97", "#8d92e3", "#85e1ce"];
  const [firstColor] = useState(colors[firstRandom]);
  const [secondColor] = useState(colors2[secondRandom]);

  return (
    <div
      className={s.projectSlider__link}
      key={"reviewsList".concat(index)}
      to={`/${name}`}
      id={index}
    >
      <div
        className={
          index === 0
            ? s.projectSlider__link__project_main
            : s.projectSlider__link__project
        }
        key={"reviewsList".concat(index)}
      >
        <div className={s.head}>
          {picture === "" ? (
            <div
              className={s.head_picture}
              style={{
                // backgroundImage: `url('${picture}')`,
                // backgroundColor: "#00B1E1",
                backgroundImage: `linear-gradient(${firstColor}, ${secondColor})`,
              }}
            >
              <div className={s.head_initials}>
                {firstLetter}
                {secondLetter}
              </div>
            </div>
          ) : (
            <div
              className={s.head_picture}
              style={{
                backgroundImage: `url('${picture}')`,
              }}
            ></div>
          )}
          <div className={s.head_description}>
            <span className={s.head_description_date}>{date}</span>
            <span className={s.head_description_name}>{name}</span>
            <div className={s.head_description_source}>
              <span className={s.head_description_source_text}>
                {language === "RU" ? "Взят из:" : "Taken from:"}&nbsp;
              </span>
              <span className={s.head_description_source_from}>{source}</span>
            </div>
          </div>
        </div>
        <div className={s.text_wrapper}>
          <p className={s.lastscreen__decoration_marks}>“</p>
          <p
            // id={"p".concat(index)}
            id={index}
            className={
              // index === idIsOpenText && isOpenText
              isOpen ? s.text_review_full : s.text_review
            }
          >
            {text}
          </p>
          {textLength > 333 ? (
            <button
              className={s.text_button}
              onClick={(e) => openFullText(e, index)}
            >
              {language === "RU" ? "читать весь отзыв" : "Read more"}
            </button>
          ) : (
            <div></div>
          )}
        </div>
        {/* <ReactPlayerLib
      width={index === counterPage - 1 ? 622 : 296}
      height={index === counterPage - 1 ? 450 : 225}
      main={index === counterPage - 1}
      video={el.video}
      picture={el.picture}
      videoSlider
      visibleVideo={visibleVideo}
      size={index === counterPage - 1 ? "main" : "small"}
      visiblCube={visiblCube}
      index={index}
      makePlay={makePlay}
      makePlayAfterTimeOut={makePlayAfterTimeOut}
      makeInVisibleButtonPlay={makeInVisibleButtonPlay}
      idPlay={idPlay}
    /> */}
        <div className={s.line}></div>
      </div>
    </div>
  );
};
