import { gql } from "@apollo/client";

export const GET_PROJECT = gql`
  query Projects($filter: ProjectFilter) {
    projects(filter: $filter) {
      id
      uuid
      name
      nameEn
      images
      minInvestment
      minInterest
      requisites
      requisitesEn
      investForms
      investFormsEn
      support
      url
      target
      targetEn
      fullDescription
      fullDescriptionEn
      contracts {
        pcYearly
      }
      projectImages {
        id
        uuid
        bannerSite
        bannerApp
        background
        logo
        gallery
      }
    }
  }
`;

export const ALL_PROJECTS = gql`
  query Projects {
    projects {
      id
      uuid
      name
      nameEn
      images
      requisites
      requisitesEn
      support
      url
      projectStatusNew
      status
      projectImages {
        id
        uuid
        logo
        bannerApp
        bannerSite
        background
        gallery
      }
    }
  }
`;

export const ALL_PROJECTS_TO_CARD = gql`
  query ALL_PROJECTS_TO_CARD {
    projects {
      uuid
      projectStatusNew
      name
      nameEn
      country
      countryEn
      minInvestment
      minInterest
      investThresholds
      projectImages {
        logo
        background
      }
      support
      description
      descriptionEn
      url
      socialNetworks
    }
  }
`;

export const GET_ALL_INVESTFORMS = gql`
  query InvestForms {
    investForms {
      id
      uuid
      name
      nameEn
      description
      descriptionEn
    }
  }
`;
export const ALL_PROVIDE = gql`
  query ALL_SUPPORT {
    projectProcurings {
      id
      uuid
      name
      nameEn
      description
      descriptionEn
    }
  }
`;
