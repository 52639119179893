import React from "react";
import s from "./PageTitle.module.css";

export const PageTitle = ({
  documentsPage,
  firstLineText,
  secondLineText,
  descriptionFirstLine,
  descriptionSecondLine,
  descriptionThirdLine,
}) => {
  return (
    <>
      <h2 className={s.firstLineText}>{firstLineText}</h2>
      <h2 className={s.secondLineText}>{secondLineText}</h2>
      <div className={s.title_description}>
        <p className={s.title_description__first_line}>
          {descriptionFirstLine}
        </p>
        <p className={s.title_description__second_line}>
          {descriptionSecondLine}
        </p>
        <p className={s.title_description__third_line}>
          {descriptionThirdLine}
        </p>
      </div>
    </>
  );
};
