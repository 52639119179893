import React from 'react'
import { Header } from '../components/Header/Header'
import { Footer } from '../components/Footer/Footer'
import s from './Layout.module.css'
import {CookiePopup } from '../components/CookiePopup/CookiePopup'
import { useLanguage } from '../providers/LanguageContext'

export const Layout = ({ children, width }) => {
  const { currentLanguage } = useLanguage();

  return (
    <>
      <Header width={width} language={currentLanguage} />
      {children}
      <Footer width={width} language={currentLanguage} />
      <CookiePopup language={currentLanguage} />
    </>
  )
}

