import React, { useEffect, useState } from "react";
import s from "./MenuFooter.module.css";
import { DataContacts } from "../../Contacts/DataContacts";
import { SocialLinks } from "../../SocialLinks/SocialLinks";
import { useQuery } from "@apollo/client";
import { GET_FOOTER } from "../../../apollo/query/query_footer";

export function MenuFooter({ setOpenBurger, openBurger, className, language }) {
  const { data } = useQuery(GET_FOOTER);
  const [info, setInfo] = useState([]);

  useEffect(() => {
    if (!data) return;
    setInfo(data?.footer);
  }, [data]);

  //Разблокировка скрола
  useEffect(() => {
    !openBurger && (document.body.style.overflow = "unset");
  }, [openBurger]);

  const goToSection = () => {
    const element = document.getElementById("form");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setOpenBurger(!openBurger);
  };

  return (
    <div className={`${s.contacts} ${className}`}>
      <a href={`tel:${DataContacts[0].phone}`} className={s.phone}>
        {DataContacts[0].phone_vew}
      </a>
      <a className={s.mail} href={`mailto:${DataContacts[0].mail}`}>
        {DataContacts[0].mail}
      </a>
      <address className={s.address}>
        {language === "RU"
          ? DataContacts[0].address
          : DataContacts[0].addressEn}
      </address>
      <div className={s.margin_top_30}>
        <SocialLinks info={info} />
      </div>
      <button className={s.button} onClick={goToSection} href="#form">
        {language === "RU" ? "Получить консультацию" : "Get a consultation"}
      </button>
    </div>
  );
}
