import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_ALLFAQ } from '../../../apollo/query/query_faq'
import s from './Questions.module.css'
import { OpeningText } from '../../../ux/openingText/OpeningText'

export const Questions = ({ language }) => {
  const { data, loading, error } = useQuery(GET_ALLFAQ)
  return (
    <>
      <div className={s.questions__main}>
        <div className={s.questions__container}>
          {data?.allDataFaq.map((elem, index) => (
            <OpeningText language={language} elem={elem} key={elem.uuid} />
          ))}
        </div>
      </div>
    </>
  )
}
