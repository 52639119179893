import clsx from 'clsx'
import React from 'react'
import s from './Section.module.css'

export const Section = ({ children, className, id }) => {
  return (
    <div id={id && id} className={clsx(s.section, className)}>
      {children}
    </div>
  )
}
