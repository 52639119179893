import React from "react";
// import { useState, useEffect, useRef } from "react"

// import "./App.css"
import { Header } from "../../components/Header/Header";
// import { ScrollBar } from "./scrollBar/ScrollBar"
// import FirstScreen from "./firstScreen/FirstScreen";
import { FirstScreen } from "./firstScreen/FirstScreen";
import { AboutUs } from "./aboutUs/AboutUs";
import { Nambers } from "./numbers/Numbers";
import { Team } from "./team/Team";
import LastScreen from "./lastScreen/LastScreen";
import { Footer } from "../../components/Footer/Footer";
import { Projects } from "./projects/Projects";
import { TeamSection } from "./teamSection/TeamSection";
import TradingViewWidget from "./TradingViewWidget/TradingViewWidget";
import { CookiePopup } from "../../components/CookiePopup/CookiePopup";
import { useLanguage } from "../../providers/LanguageContext";

export function Home({ width }) {
  const { currentLanguage } = useLanguage();

  // const [activePage, setActivePage] = useState(1)
  // const animatingPage = useRef(false);

  // useEffect(() => {
  //   const onWheel = (event) => {
  //     if (animatingPage.current) return;
  //     const direction = getDirection(event.deltaY);

  //     if (!direction) return;
  //     if (direction === "up" && activePage === 1) return;
  //     if (direction === "down" && activePage === 6) return;

  //     setActivePage((prev) => (direction === "up" ? prev - 1 : prev + 1));
  //     animatingPage.current = true;
  //   };

  //   document.addEventListener("wheel", onWheel);
  //   return () => document.removeEventListener("wheel", onWheel);
  // }, [animatingPage, activePage, width]);

  // function getDirection(deltaY) {
  //   return deltaY > 0 ? "down" : deltaY < 0 ? "up" : null;
  // }

  // const afterTransition = (event) => {
  //   if (event.target !== event.currentTarget) return;
  //   setTimeout(() => (animatingPage.current = false), 500);
  // };

  return (
    <>
      {/* <ScrollBar a
      \эctivePage={activePage} /> */}
      <Header width={width} language={currentLanguage} />

      {/* Виджет с трейдингом */}
      {/* <TradingViewWidget /> */}
      <div
        className="container js-container"
        // onTransitionEnd={afterTransition}
        // style={{
        //   transform:
        //     width > 1024 ? `translateY(${(activePage - 1) * -100}%)` : "none",
        // }}
        // data-page={activePage}
      >
        <div className="js-page div1" id="main">
          <FirstScreen width={width} language={currentLanguage} />
        </div>
        <div className="js-page div2" id="about_us">
          <AboutUs language={currentLanguage} />
        </div>
        <div className="js-page div3" id="projects">
          <Projects width={width} language={currentLanguage} />
        </div>
        {/* <div className="js-page div4" id="numbers">
          <Nambers width={width} language={currentLanguage} />
        </div> */}
        {/* <div className="js-page div5" id="team">
          <Team width={width} />
          <TeamSection width={width} language={currentLanguage} />
        </div> */}
        <div className="js-page div6" id="form">
          <LastScreen language={currentLanguage} />
          <Footer language={currentLanguage} />
        </div>
      </div>
      <CookiePopup language={currentLanguage} />
    </>
  );
}
