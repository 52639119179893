import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
  GET_PROJECT,
  GET_ALL_INVESTFORMS,
} from "../../../apollo/query/query_projects";
import { Section } from "../Section/Section";
import { SectionTitle } from "../SectionTitle/SectionTitle";
import s from "./Purpose.module.css";
import { Loader } from "../Loader/Loader";

export const Purpose = ({ project, language }) => {
  const { loading, error, data } = useQuery(GET_PROJECT, {
    variables: {
      filter: {
        name: project,
      },
    },
  });

  const {
    loading: investFormLoading,
    error: investFormError,
    data: investFormData,
  } = useQuery(GET_ALL_INVESTFORMS);

  const [descriptionArray, setDescriptionArray] = useState([]);
  const [booleanArr, setBooleanArr] = useState([]);

  useEffect(() => {
    if (!data) return; // возвращаем false пока не придут данные
    if (!investFormData) return; // возвращаем false пока не придут данные

    let tempArr = []; // вспомогательный массив для рендера названия и описания
    let booleanArrHelper = []; // вспомогательный массив для количества объектов с описанием

    const makeDescriptionArray = (nameOfForm) => {
      //функция формирования массива с описанием и массива для переключения видимости описания
      investFormData.investForms.forEach((element) => {
        // перебор инвестформа из запроса сервера
        if (element.name === nameOfForm) {
          // если название инвестии совпадает с названием инвестии из проекта
          tempArr.push({
            // формируем вспомогательный массив с описанием
            name: element.name,
            nameEn: element.nameEn,
            description: element.description,
            descriptionEn: element.descriptionEn,
          });
          booleanArrHelper.push(false); // формируем вспомогательный массив для видимости описания, только количество элементов
        }
        setDescriptionArray(tempArr); // обновляем состояние для описание
        setBooleanArr(booleanArrHelper); //  обновляем состояние для признака видимости описания
      });
    };

    if (data.projects[0].investForms.length === 0) {
      makeDescriptionArray("Процентный заём");
    } else {
      data.projects[0].investForms.forEach((el) => {
        //перебираем массив с формой инвестиций, который приходит с сервера, чтобы отправить аргумент для формирования массива объектов с целью и описанием
        makeDescriptionArray(el.name);
      });
    }
  }, [data, investFormData]);

  const toggleDescription = (e, i) => {
    //открытие закрытие описания формы инвестиций
    const newArr = booleanArr.map((el, index) => (index === i ? !el : el)); //проверяем интдекс в массиве, если индекс равен меняем переменную отображения
    setBooleanArr(newArr); //меняeм состояние для рендера
  };

  if (loading || investFormLoading) return <Loader />;
  if (error || investFormError) return <div>error</div>;

  return (
    <Section className={s.section__margin__bottom}>
      <SectionTitle text={language === "RU" ? "Цели" : "Goals"} />
      <div className={s.info__list}>
        {data.projects[0].target !== "" && (
          <div className={s.info__requisites_item}>
            <div className={s.info__requisites_title}>
              {" "}
              {language === "RU" ? "Цели:" : "Goals:"}
            </div>
            <div className={s.info__requisites_value}>
              {language === "RU"
                ? data.projects[0].target
                : data.projects[0].targetEn}
            </div>
          </div>
        )}

        <div className={s.info__requisites_item}>
          <div className={s.info__requisites_title}>
            {language === "RU"
              ? "Форма инвестиций:"
              : "Form of attracting investments"}
          </div>
          <ul className={s.invest__form__wrapper}>
            {descriptionArray.map((el, i) => (
              <li
                className={
                  booleanArr[i] ? s.invest__item__active : s.invest__item
                }
                key={i}
              >
                <div className={s.invest__item__title}>
                  <span className={s.info__requisites_value}>
                    {" "}
                    {language === "RU" ? el.name : el.nameEn}
                  </span>
                  <div
                    className={booleanArr[i] ? s.x__img : s.x__img__hide}
                    onClick={(e) => toggleDescription(e, i)}
                  ></div>
                </div>

                <p
                  className={s.description}
                  // className={
                  //   booleanArr[i] ? s.description : s.description__hide
                  // }
                >
                  {language === "RU" ? el.description : el.descriptionEn}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Section>
  );
};
