import React from "react";
import { Section } from "../Section/Section";
import { SectionTitle } from "../SectionTitle/SectionTitle";
import clsx from "clsx";
import s from "./Gallery.module.css";
import { SliderGallery } from "../SliderGallery/SliderGallery";

export const Gallery = ({
  width,
  project,
  isGallery,
  arrGallery,
  isTurnDevice,
  clientWidth,
  language
}) => {
  return (
    <Section className={s.section__margin__bottom}>
      <SectionTitle text={ language === "RU" ? "Галерея" : "Gallery"} />
      <div className={s.gallery__navigation}>
        <button
          className={clsx(
            s.gallery__navigation__button,
            s.gallery__navigation__button_active
          )}
        >
          {language === "RU" ? "Фото" : "Photo"}
        </button>
        {/* <button className={s.gallery__navigation__button}>Видео</button> */}
      </div>
      <SliderGallery
        project={project}
        width={width}
        isGallery={isGallery}
        arrGallery={arrGallery}
        isTurnDevice={isTurnDevice}
        clientWidth={clientWidth}
        language={language}
      />
    </Section>
  );
};
