import React, { useState, useRef, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_INTERVIEWS } from "../../../apollo/query/query_media";
import { ReactPlayerLib } from "../ReactPlayerLib/ReactPlayerLib";
import { SliderNavigator } from "../SliderNavigator/SliderNavigator";
import { convertToDD_MMM_YYYY } from "../helper";
import s from "./VideoSlider.module.css";

export const VideoSlider = ({ width, clientWidth, isTurnDevice, language }) => {

  const slider = useRef(null);
  let [position, setPosition] = useState(0);
  const [slideWidth, setSlideWidth] = useState(
    clientWidth > 1025
      ? 16.98
      : clientWidth > 769
      ? 38.477
      : clientWidth > 376
      ? 48.635
      : 94.41
  );
  const [counterPage, setCounterPage] = useState(1);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50;
  const { data } = useQuery(GET_ALL_INTERVIEWS);
  const [videoList, setVideoList] = useState([]);
  const [visiblCube, setVisibleCube] = useState(false);
  const [id, setId] = useState("");
  const [idPlay, setIdPlay] = useState("");
  const [visibleVideo, setVisibleVideo] = useState(false);

  useEffect(() => {
    if (!data) return;
    const temp = [...data?.mediaInterviews];
    const tempSorted = [
      ...temp.sort((a, b) => new Date(b.datemarker) - new Date(a.datemarker)),
    ];
    const tempNumOrder = tempSorted.map((el, index) => {
      return { ...el, numOreder: index + 1 };
    });
    setVideoList([...tempNumOrder]);
  }, [data]);

  const [counter, setCounter] = useState(1);
  const moveFirstToLast = () => {
    const tempElement = videoList[0];
    setCounter(videoList[1].numOreder);
    const tempList = videoList.slice(1);
    const newArray = [...tempList, tempElement];
    setVideoList(newArray);
  };
  const moveLast = () => {
    const tempElement = videoList[videoList.length - 1];
    const tempList = videoList.slice(0, videoList.length - 1);
    setCounter(tempElement.numOreder);
    const newArray = [tempElement, ...tempList];
    setVideoList(newArray);
  };

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  useEffect(() => {
    setPosition(0);
    setCounterPage(1);

    if (isTurnDevice) {
      slider.current.childNodes.forEach(
        (el) => (el.style = `transform: translateX(${0}vw`)
      );
    }

    if (clientWidth > 1024) {
      setSlideWidth(16.98);
    } else if (clientWidth > 769) {
      setSlideWidth(38.477);
    } else if (clientWidth > 376) {
      setSlideWidth(48.635);
    } else {
      setSlideWidth(94.41);
    }
  }, [width]);

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe)
      isLeftSwipe ? nextHandler() : prevHandler();
  };

  const nextHandler = () => {
    // if (counterPage === videoList?.length) return;
    // setCounterPage(counterPage + 1);
    // setPosition((position = position - 1 * slideWidth));
    // slider.current.childNodes.forEach(
    //   (el) => (el.style = `transform: translateX(${position}vw)`)
    // );
    moveFirstToLast();
  };

  const prevHandler = () => {
    // if (counterPage === 1) return setPosition(0);
    // setCounterPage(counterPage - 1);
    // setPosition((position = position + 1 * slideWidth));
    // slider.current.childNodes.forEach(
    //   (el) => (el.style = `transform: translateX(${position}vw)`)
    // );
    moveLast();
  };

  const makePlay = (e, firstStartBottom) => {
    if (firstStartBottom) {
      return;
    } else {
      setVisibleCube(true);
      setId(e.target.id);
      setIdPlay(e.target.parentElement.id);
      // setTimeout(() => {
      //   setVisibleCube(false);
      // }, 1000);
    }
  };

  const makeInVisibleButtonPlay = (e) => {
    setVisibleCube(false);
    setId(e.target.id);
    setIdPlay(e.target.parentElement.id);
  };

  return (
    <>
      <div className={s.wrapper} id="interview">
        <div
          className={s.projectSlider}
          ref={slider}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          {videoList?.map((el, index) => (
            <div
              className={
                index === counterPage - 1
                  ? s.wrapper_videoCard_main
                  : s.wrapper_videoCard_small
              }
              key={"videoGallery".concat(index)}
              to={`somewhere`}
              id={index}
            >
              <div
                className={
                  index === counterPage - 1
                    ? s.wrapper_videoCard_inner_main
                    : s.wrapper_videoCard_inner_small
                }
                key={"videoGalleryWrapper".concat(index)}
              >
                <ReactPlayerLib
                  width={
                    clientWidth > 1025
                      ? index === counterPage - 1
                        ? "32.4vw"
                        : "15.42vw"
                      : clientWidth > 769
                      ? index === counterPage - 1
                        ? "55.66vw"
                        : "36.52vw"
                      : clientWidth > 376
                      ? index === counterPage - 1
                        ? "46.09vw"
                        : "46.09vw"
                      : index === counterPage - 1
                      ? "89.33vw"
                      : "89.33vw"
                  }
                  height={
                    clientWidth > 1025
                      ? index === counterPage - 1
                        ? "23.44vw"
                        : "11.72vw"
                      : clientWidth > 769
                      ? index === counterPage - 1
                        ? "44.24vw"
                        : "27.34vw"
                      : clientWidth > 376
                      ? index === counterPage - 1
                        ? "36.459vw"
                        : "36.459vw"
                      : index === counterPage - 1
                      ? "69.33vw"
                      : "69.33vw"
                  }
                  main={index === counterPage - 1}
                  video={el.video}
                  picture={el.picture}
                  videoSlider
                  visibleVideo={visibleVideo}
                  size={index === counterPage - 1 ? "main" : "small"}
                  visiblCube={visiblCube}
                  index={index}
                  makePlay={makePlay}
                  // makePlayAfterTimeOut={makePlayAfterTimeOut}
                  makeInVisibleButtonPlay={makeInVisibleButtonPlay}
                  idPlay={idPlay}
                  clientWidth={clientWidth}
                />
                <div
                  className={
                    index === counterPage - 1
                      ? s.description_wrapper_main
                      : s.description_wrapper
                  }
                >
                  <h4 className={s.description_title}>
                    {" "}
                    {language === "RU" ? el.title : el.titleEn}
                  </h4>
                  <p className={s.description_text}>
                    {" "}
                    {language === "RU" ? el.decription : el.descriptionEn}
                  </p>
                  <span className={s.description_date}>
                    {convertToDD_MMM_YYYY(el?.datemarker, language)}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <SliderNavigator
          prevHandler={prevHandler}
          nextHandler={nextHandler}
          counterPage={counter}
          arrLength={data?.mediaInterviews?.length}
          className={s.navigation_position}
        />
      </div>
    </>
  );
};
