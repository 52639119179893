import React from "react";
import { Header } from "../../components/Header/Header";
import { Projects } from "../home/projects/Projects";
import { Footer } from "../../components/Footer/Footer";
import s from "./ProjectsPage.module.css";
import LastScreen from "../home/lastScreen/LastScreen";
import { Layout } from "../../layouts/Layout";
import { useLanguage } from "../../providers/LanguageContext";

export function ProjectsPage({ width }) {
  const { currentLanguage } = useLanguage();
  return (
    <Layout width={width}>
      <div className={s.kostil}>
        <Projects width={width} language={currentLanguage} />
      </div>
      <LastScreen />
    </Layout>
  );
}
