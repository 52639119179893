import React from "react";
import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./App.css";
import { Home } from "./Pages/home/Home";
import { ProjectPage } from "./Pages/ProjectPage/ProjectPage";
import { NotFound } from "./Pages/NotFound/NotFound";
import { Media } from "./Pages/Media/Media";
import { Faq } from "./Pages/faq/Faq";
import { ProjectsPage } from "./Pages/projectsPage/ProjectsPage";
import { DocumentsPage } from "./Pages/documentsPage/DocumentsPage";
import { Business } from "./Pages/Business/Business";
import { usePageTracking } from "./hooks/usePageTracking";
import { Cookies } from "./Pages/Cookies/Cookies";
import { BusinessAlternative } from "./Pages/BusinessAlternative/BusinessAlternative";
import { useLocation } from "react-router-dom";

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const [isTurnDevice, setIsTurnDevice] = useState(false);
  const [clientWidth, setClientWidth] = useState(
    document.documentElement.scrollWidth
  );

  const location = useLocation();

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    setIsTurnDevice(true);
    setClientWidth(document.documentElement.clientWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  usePageTracking(); // хук для отправки аналитики

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="all" />
        <meta
          name="description"
          content="Эксперты по бизнес-проектам с низкими рисками и хорошей доходностью: знаем, куда лучше инвестировать деньги начинающему и опытному инвестору, чтобы приумножить капитал"
          data-rh="true"
        />
        <meta
          name="keywords"
          content="прибыльные инвестиции в малый и средний бизнес, вложение денег в России, пассивный доход, управление инвестиционными рисками, инструменты для приумножения накоплений, инвест консалтинг"
        />
        <title>
          {location.pathname === "/business"
            ? "Frontiers — привлекаем деньги на развитие бизнеса"
            : "Frontiers — помогаем безопасно вложить деньги в России"}
        </title>
        {/* <!-- Yandex.Metrika counter --> */}
        <script type="text/javascript">
          {`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
              m[i].l=1*new Date();
              for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
              k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
              (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

              ym(96020637, "init", {
                defer:true,
                    clickmap:true,
                    trackLinks:true,
                    accurateTrackBounce:true,
                    webvisor:true
              });`}
        </script>

        {/* Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-T4GJ74LPCS"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-T4GJ74LPCS');`}
        </script>
      </Helmet>
      <Routes>
        <Route path="/" element={<Home width={width} />} />
        <Route
          path="/:project"
          element={
            <ProjectPage
              width={width}
              isTurnDevice={isTurnDevice}
              clientWidth={clientWidth}
            />
          }
        />
        <Route
          path="/projects/:project"
          element={
            <ProjectPage
              width={width}
              isTurnDevice={isTurnDevice}
              clientWidth={clientWidth}
            />
          }
        />
        <Route
          path="/media"
          element={
            <Media
              width={width}
              clientWidth={clientWidth}
              isTurnDevice={isTurnDevice}
            />
          }
        />
        <Route path="/faq" element={<Faq width={width} />} />
        <Route path="/projects" element={<ProjectsPage width={width} />} />
        {/* <Route
          path="/business"
          element={
            <BusinessAlternative width={width} clientWidth={clientWidth} />
          }
        /> */}
        {/* <Route
          path="/businessalt"
          element={
            <BusinessAlternative width={width} clientWidth={clientWidth} />
          }
        /> */}
        <Route path="/cookies" element={<Cookies width={width} />} />
        <Route path="/documents" element={<DocumentsPage width={width} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      {/* <Routes>
        <Route>
          <Route index element={<Home width={width} />} />
          <Route
            path="/:project"
            element={
              <ProjectPage
                width={width}
                isTurnDevice={isTurnDevice}
                clientWidth={clientWidth}
              />
            }
          />
          <Route
            path="projects/:project"
            element={
              <ProjectPage
                width={width}
                isTurnDevice={isTurnDevice}
                clientWidth={clientWidth}
              />
            }
          />
          <Route
            path="/media"
            element={
              <Media
                width={width}
                clientWidth={clientWidth}
                isTurnDevice={isTurnDevice}
              />
            }
          />
          <Route path="/faq" element={<Faq width={width} />} />
          <Route path="/projects" element={<ProjectsPage width={width} />} />
          <Route
            path="/business"
            element={<Business width={width} clientWidth={clientWidth} />}
          />
          <Route path="/cookies" element={<Cookies width={width} />} />
          <Route path="/documents" element={<DocumentsPage width={width} />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes> */}
    </>
  );
}
export default App;
