import React from "react";
import { Section } from "../Section/Section";
import { SectionTitle } from "../SectionTitle/SectionTitle";
import { SliderProjects } from "../SliderProjects/SliderProjects";
import s from "./OtherProjects.module.css";

export const OtherProjects = ({
  width,
  isTurnDevice,
  clientWidth,
  language,
}) => {
  return (
    <Section className={s.section__margin}>
      <SectionTitle
        text={language === "RU" ? "Другие проекты" : "Other projects"}
      />
      {/* <Slider projectSlider={true} photoSlider={false} width={width} /> */}
      <SliderProjects
        width={width}
        isTurnDevice={isTurnDevice}
        clientWidth={clientWidth}
      />
    </Section>
  );
};
