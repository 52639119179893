export const reviewsList = [
  {
    name: "Андрей Кучевский",
    picture:
      // "https://cdn.pixabay.com/photo/2023/06/07/18/03/flowers-8047833_1280.jpg",
      "",
    source: "Telegram",
    text: "Хочу порекомендовать компанию Frontiers как надежного партнера в мире инвестиций. Инвестирую уже порядка двух лет с данной компанией как в займах так и в долевом формате. Высокая доходность, выплаты без задержек.",
    date: "13 июля 2023 г.",
  },
  {
    name: "Артем Андреевич",
    picture:'',
      // "https://cdn.pixabay.com/photo/2023/07/23/09/09/mountains-8144681_1280.jpg",
    source: "Telegram",
    text: "Инвестировал по несколько раз во все проекты. Нравится, что можно диверсифицировать свой портфель и посмотреть множество вариантов. ",
    date: "6 июня 2023 г.",
  },
  {
    name: "Андрей Злобин",
    picture: "",
    // "https://cdn.pixabay.com/photo/2023/07/21/20/33/bird-8142295_1280.jpg",
    source: "Telegram",
    text: "С компанией Frontiers инвестирую уже давно, очень отзывчивые менеджеры, всегда оперативно отвечают на все вопросы.",
    date: "4 мая 2023 г.",
  },
  {
    name: "Наталья",
    picture: "",
    // "https://cdn.pixabay.com/photo/2023/06/07/18/03/flowers-8047833_1280.jpg",
    source: "Telegram",
    text: "Инвестировал в проекты PartyTime и ТПК Автопром. Все выплаты без задержек. ",

    date: "13 июля 2023 г.",
  },
  {
    name: "Александр",
    picture: "",
    // "https://cdn.pixabay.com/photo/2023/07/23/09/09/mountains-8144681_1280.jpg",
    source: "Telegram",
    text: "Хочу поделиться личным опытом инвестирования: -Автопром, -Коворкинги, ПатиТайм,  все выплаты точно в срок и без задержек.",
    date: "6 июня 2023 г.",
  },
];
