import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_FOOTER } from "../../apollo/query/query_footer";
import { Link } from "react-router-dom";
// import { DataContacts } from "../Contacts/DataContacts";
// import { SocialLinks } from "../SocialLinks/SocialLinks";
import Personal from "../../ux/form/PersonalData";
import { SocialLinks } from "../SocialLinks/SocialLinks";

export function Footer({ language }) {
  const { data, loading, error } = useQuery(GET_FOOTER);
  const [info, setInfo] = useState([]);

  const [isPersonal, setIsPersonal] = useState(false);
  const [checked, setChecked] = useState(false);
  const openPersonal = () => {
    setIsPersonal(true);
  };
  const closePersonal = () => {
    setIsPersonal(false);
  };

  useEffect(() => {
    if (!data) return;
    setInfo(data?.footer);
  }, [data]);

  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber === undefined) return;
    const cleaned = phoneNumber.replace(/\D/g, "");
    const formatted = `+${cleaned.slice(0, 1)} ${cleaned.slice(
      1,
      4
    )} ${cleaned.slice(4, 7)} ${cleaned.slice(7)}`;
    return formatted;
  };

  const replaceCountryCode = (phoneNumber) => {
    if (phoneNumber === undefined) return;
    const replaced = phoneNumber.replace(/^\+7/, "8");
    return replaced;
  };

  const scrollToTop = () =>
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  return (
    <>
      {" "}
      <footer className="lastscreen__footer">
        <div className="lastscreen__footer_wrapper_info">
          <div className="lastscreen__frontiers_group">
            <Link
              className="lastscreen__contact_group_frontiers"
              to={`/`}
              onClick={scrollToTop}
            >
              FRONTIERS
            </Link>
            <div className="footer_social_links">
              <SocialLinks info={info} />
            </div>
            <a onClick={openPersonal} className="lastscreen__footer_text">
              {language === "RU"
                ? "Политика конфиденциальности"
                : "Privacy Policy "}
            </a>
            <p className="lastscreen__footer_text">
              © {new Date().getFullYear()} Frontiers Group
            </p>
          </div>
          <div className="lastscreen__contact_group">
            <p className="lastscreen__contact_group_contact">
              {language === "RU" ? "КОНТАКТЫ" : "CONTACTS"}
            </p>
            <address className="lastscreen__footer_text">
              {language === "RU"
                ? info?.contactsAddress
                : info?.contactsAddressEn}
            </address>
            <a
              href={`tel:${replaceCountryCode(info?.contactsPhone)}`}
              className="lastscreen__footer_text"
            >
              {formatPhoneNumber(info.contactsPhone)}
            </a>
            <a
              className="lastscreen__footer_text"
              href={`mailto:${data?.footer?.contactsMail}`}
            >
              {data?.footer?.contactsMail}
            </a>

            <div className="lastscreen__design_dev">
              <p className="lastscreen__design_dev_text">
                {language === "RU"
                  ? "Дизайн и разработка"
                  : "Design and development"}
              </p>
              <a href="https://kojo.one" target="_blank" rel="noreferrer">
                <div className="kojo__logo_hidden"> KOJO.ONE</div>
                <img
                  className="kojo__logo"
                  src="./img/kojo_logo.svg"
                  alt="KOJO.ONE"
                ></img>
              </a>
            </div>
          </div>
          <div className="lastscreen__info">
            <div className="lastscreen__footer_info_toplineWrapper">
              <div className="lastscreen__footer_info_img"></div>
              <span className="lastscreen__footer_info_warn">
                {language === "RU"
                  ? data?.footer?.textHeader
                  : data?.footer?.textHeaderEn}
              </span>
            </div>
            <textarea
              className="lastscreen__footer_info_textArea"
              readOnly
              rows={12}
              value={
                language === "RU"
                  ? data?.footer?.textText
                  : data?.footer?.textTextEn
              }
            />
          </div>
        </div>
      </footer>
      {isPersonal && (
        <Personal language={language} closePersonal={closePersonal} setChecked={setChecked} />
      )}
    </>
  );
}
