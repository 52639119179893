import { gql } from "@apollo/client";

export const GET_NUMBERS = gql`
  query get_Numbers($filter: MediaNumbersFilter!) {
    mediaNumbers(filter: $filter) {
      uuid
      year
      investorProfit
      investorProfitEn
      returnOnInvestment
      returnOnInvestmentEn
      reinvest
      reinvestEn
      investorInvested
      investorInvestedEn
      transactionsCompleted
      transactionsCompletedEn
      averageInvestorCheck
      averageInvestorCheckEn
    }
  }
`;
