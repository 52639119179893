import React, { useState, useRef, useEffect } from 'react'
import { ALL_PROJECTS } from '../../../apollo/query/query_projects'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import s from './SliderProjects.module.css'

export const SliderProjects = ({ width, isTurnDevice, clientWidth }) => {
  const projectSliderRef = useRef(null)
  let [positionProject, setPositionProject] = useState(0)
  const [counterPageProject, setCounterPageProject] = useState(1)
  //30.185vh - размер слайда + размер геп
  const [slideWidth] = useState(16.98)
  const [slideWidthMediumScreen] = useState(32.422)
  const [slideWidthSmallScreen] = useState(32.9427083333333)
  const [slideWidthExtraSmallScreen] = useState(89.096)
  const [counterPage, setCounterPage] = useState(1)
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)
  const minSwipeDistance = 50
  const [currentSlide, setCurrentSlide] = useState(0)
  const [picturePerSlide, setPicturePerSlide] = useState(
    width > 1024 ? 5 : width > 376 ? 3 : 1
  )

  // для сброса слайдера при повороте экрана
  useEffect(() => {
    setPositionProject(0)
    setCounterPageProject(1)

    // if (isTurnDevice) {
    //   projectSliderRef.current.childNodes.forEach(
    //     (el) => (el.style = `transform: translateX(${0}px`)
    //   );
    // }

    if (isTurnDevice) {
      projectSliderRef?.current?.childNodes?.forEach(
        (el) => (el.style = `transform: translateX(${0}px`)
      )
    }
  }, [width])

  // clientWidth вместо innerWidth,т.к нет скролл бара
  useEffect(() => {
    setPicturePerSlide(clientWidth > 1025 ? 5 : clientWidth > 376 ? 3 : 1)
  }, [width])

  const onTouchStart = (e) => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientX)
  }

  const [dataSlider, setDataSlider] = useState([])

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe || isRightSwipe)
      isLeftSwipe ? nextProjectHandler() : prevHandlerProject()
  }

  const { loading, error, data } = useQuery(ALL_PROJECTS)

  const returnToTop = () => {
    const el = document.getElementById('returnToTop')
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (!data) return
    setDataSlider([
      ...data.projects.filter(
        (el) =>
          el.projectStatusNew !== 'draft' && el.projectStatusNew !== 'archive'
      ),
    ])
  }, [data])

  const nextProjectHandler = () => {
    let slideIncrement
    let slideWidthValue

    if (clientWidth > 1024) {
      slideIncrement = 5
      slideWidthValue = slideWidth
    } else if (clientWidth <= 1024 && clientWidth > 768) {
      slideIncrement = 3
      slideWidthValue = slideWidthMediumScreen
    } else if (clientWidth <= 768 && clientWidth > 376) {
      slideIncrement = 3
      slideWidthValue = slideWidthSmallScreen
    } else {
      slideIncrement = 1
      slideWidthValue = slideWidthExtraSmallScreen
    }

    // const totalPages = Math.ceil(data.projects.length / slideIncrement);
    // const currentPosition = -(
    //   slideWidthValue *
    //   (data.projects.length - slideIncrement)
    // );

    const totalPages = Math.ceil(dataSlider.length / slideIncrement)
    const currentPosition = -(
      slideWidthValue *
      (dataSlider.length - slideIncrement)
    )

    if (positionProject === currentPosition) {
      setCounterPageProject(totalPages)
      return null
    }

    if (counterPageProject < totalPages) {
      setCounterPageProject(counterPageProject + 1)
    }

    // if (counterPageProject + 1 === totalPages) {
    //   setPositionProject(
    //     (positionProject =
    //       positionProject -
    //       (data.projects.length - slideIncrement * counterPageProject) *
    //         slideWidthValue)
    //   );
    if (counterPageProject + 1 > totalPages) {
      return
    } else {
      if (counterPageProject + 1 === totalPages) {
        setPositionProject(
          (positionProject =
            positionProject -
            (dataSlider.length - slideIncrement * counterPageProject) *
              slideWidthValue)
        )
      } else {
        setPositionProject(
          (positionProject = positionProject - slideIncrement * slideWidthValue)
        )
      }
    }
    const translateUnit = clientWidth > 1024 ? 'vw' : 'vw'

    projectSliderRef.current.childNodes.forEach(
      (el) =>
        (el.style = `transform: translateX(${positionProject}${translateUnit})`)
    )
  }

  const prevHandlerProject = () => {
    let slideIncrement
    let slideWidthValue

    if (clientWidth > 1024) {
      slideIncrement = 5
      slideWidthValue = slideWidth
    } else if (clientWidth <= 1024 && clientWidth > 768) {
      slideIncrement = 3
      slideWidthValue = slideWidthMediumScreen
    } else if (clientWidth <= 768 && clientWidth > 376) {
      slideIncrement = 3
      slideWidthValue = slideWidthSmallScreen
    } else {
      slideIncrement = 1
      slideWidthValue = slideWidthExtraSmallScreen
    }

    // const totalPages = Math.ceil(data.projects.length / slideIncrement);
    const totalPages = Math.ceil(dataSlider.length / slideIncrement)
    const currentPosition = 0

    if (positionProject === 0) {
      setCounterPageProject(1)
      return null
    }
    if (counterPageProject === 1) return setPositionProject(0)

    if (positionProject === currentPosition) {
      setCounterPageProject(totalPages)
      return null
    }

    setCounterPageProject(counterPageProject - 1)

    // if (counterPageProject - 1 === totalPages - 1) {
    //   setPositionProject(
    //     (positionProject =
    //       positionProject +
    //       (data.projects.length % slideIncrement === 0
    //         ? slideIncrement
    //         : data.projects.length % slideIncrement) *
    //         slideWidthValue)
    //   );
    if (counterPageProject - 1 === totalPages - 1) {
      setPositionProject(
        (positionProject =
          positionProject +
          (dataSlider.length % slideIncrement === 0
            ? slideIncrement
            : dataSlider.length % slideIncrement) *
            slideWidthValue)
      )
    } else {
      setPositionProject(
        (positionProject = positionProject + slideIncrement * slideWidthValue)
      )
    }

    const translateUnit = clientWidth > 1024 ? 'vw' : 'vw'

    projectSliderRef.current.childNodes.forEach(
      (el) =>
        (el.style = `transform: translateX(${positionProject}${translateUnit})`)
    )
  }

  if (loading) return <div>loading</div>
  if (error) return <div>error</div>

  return (
    <>
      {data && (
        <>
          <div
            className={s.projectSlider}
            ref={projectSliderRef}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
          >
            {/* {data.projects.map((el) => ( */}
            {dataSlider.map((el) => (
              <Link
                className={s.projectSlider__link}
                key={el.name}
                to={`/${el.name}`}
              >
                <div
                  className={s.projectSlider__link__project}
                  key={el.id}
                  onClick={returnToTop}
                >
                  <div
                    className={s.projectSlider__link__project__background}
                    style={{
                      backgroundImage: `url('${el.projectImages.background}')`,
                    }}
                  ></div>

                  <div
                    className={s.projectSlider__link__project__logo}
                    style={{
                      WebkitMaskImage: `url(${el.projectImages.logo})`,

                      maskImage: `url(${el.projectImages.logo})`,
                    }}
                  ></div>
                </div>
              </Link>
            ))}
          </div>
        </>
      )}

      <div className={s.navigation}>
        <div className={s.navigation_wrapper}>
          <div className={s.navigation_prevBtn} onClick={prevHandlerProject}>
            <div className={s.navigation_prevBtn_image}></div>
          </div>
          <div className={s.navigation__values}>
            <span>{counterPageProject}</span>
            <span id="goToForm">/</span>
            {/* <span>{Math.ceil(data.projects.length / picturePerSlide)}</span> */}
            <span>{Math.ceil(dataSlider.length / picturePerSlide)}</span>
          </div>
          <div className={s.navigation_nextBtn} onClick={nextProjectHandler}>
            <div className={s.navigation_nextBtn_image}></div>
          </div>
        </div>
      </div>
    </>
  )
}
