import React, { useEffect } from "react";
import ReactPlayer from "react-player";
import s from "./ReactPlayerLib.module.css";
import { useState } from "react";

export const ReactPlayerLib = ({
  width,
  height,
  video,
  picture,
  main,
  videoSliderBottom,
  videoSlider,
  size,
  visible,
  visibleVideo,
  id,
  index,
  visiblCube,
  makePlay,
  makeInVisibleButtonPlay,
  idPlay,
  makePlayAfterTimeOut,
  clientWidth,
  playerHandler,
  test,
  ...rest
}) => {
  const [toggle, setToggle] = useState(false);
  const [light, setLight] = useState(true);
  const [visibleVideoTopOnce, setVisibleVideoTopOnce] = useState(true);
  const [visibleOnce, setVisibleOnce] = useState(true);
  const [firstStartBottom, setFirstStartBottom] = useState(true);
  const [overlay, setOverlay] = useState(true);
  const [youtubeControls, setYoutobeControls] = useState(0);

  const clickHandler = () => {
    if (clientWidth > 1024) {
      setVisibleOnce(false);
      setLight(false);
      setToggle(!toggle);
      setFirstStartBottom(false);
      setOverlay(!overlay);
      setYoutobeControls(1);
    } else {
      setVisibleOnce(false);
      setLight(false);
      setToggle(!toggle);
      setFirstStartBottom(!firstStartBottom);
      setOverlay(!overlay);
      setYoutobeControls(1);
    }
    // setVisible(!visible);
    // setVisibleOnce(false);
  };

  const clickHandlerAgain = () => {
    setLight(false);
    setToggle(!toggle);
    // setVisible(!visible);
  };

  // кнопка появляется при выключенном плеере
  useEffect(() => {
    if (toggle === false) setFirstStartBottom(true);
  }, [toggle]);

  return (
    <div
      key={"overlay".concat(index)}
      className={
        videoSliderBottom
          ? size === "main"
            ? s.overlay_main
            : size === "middle"
            ? s.overlay_middle
            : size === "small"
            ? s.overlay_small
            : ""
          : ""
      }
      // onClick={(e) => cubeFunction(e)}
      onMouseEnter={(e) => makePlay(e, firstStartBottom)}
      onMouseLeave={(e) => makeInVisibleButtonPlay(e)}
      onMouseMove={(e) => makePlayAfterTimeOut(e)}
    >
      {/* <div className={s.cube}>
        {index}
        {idPlay}
        {index === idPlay}
      </div> */}
      <div
        className={videoSliderBottom && overlay ? s.wrapper_overlay : s.wrapper}
        key={"wrapper_overlay".concat(index)}
        onClick={clickHandler}
      >
        {/* {String(index) === idPlay && test && (
          <div className={s.cube}>
            {index}
            {idPlay}
            {index === idPlay}
            {visiblCube ? "true" : "false"}
            {test ? "true" : "false"}
          </div>
        )} */}
        <ReactPlayer
          key={"ReactPlayer".concat(index)}
          id={index}
          url={`${video}#t=1.5`}
          playing={toggle}
          // controls={toggle}
          controls={true}
          width={width}
          height={height}
          volume={1}
          // light={light ? picture : false}
          playIcon={false}
          loop={true}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "black",
          }}
          config={{
            file: {
              attributes: {
                style: {
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                },
              },
            },
            youtube: {
              playerVars: {
                color: "white",
                controls: 1,
                fs: 1,
                modestbranding: 0,
                showinfo: 0,
                disablekb: 0,
                rel: 0,
                start: 0,
              },
            },
          }}
        />
      </div>
      {/* кнопки верхнего слайдера */}
      {videoSlider && firstStartBottom && (
        <div
          className={size === "main" ? s.play_Top : s.play_small_Top}
          onClick={clickHandler}
          key={"play_top".concat(index)}
        ></div>
      )}
      {videoSlider &&
        String(index) === idPlay &&
        visiblCube &&
        clientWidth > 1024 && (
          <div
            className={size === "main" ? s.play_Top : s.play_small_Top}
            onClick={clickHandler}
            key={"play_top_again".concat(index)}
          ></div>
        )}
      {/* кнопки нижнего слайдера */}
      {videoSliderBottom && firstStartBottom && (
        <div
          className={
            size === "main"
              ? s.play_wrapper_main_videoSliderBottom
              : size === "middle"
              ? s.play_wrapper_middle_videoSliderBottom
              : s.play_wrapper_small_videoSliderBottom
          }
        >
          <div
            className={size === "main" ? s.play_bottom : s.play_small_bottom}
            onClick={clickHandler}
          ></div>
        </div>
      )}
      {videoSliderBottom && String(index) === idPlay && visiblCube && (
        <div
          className={
            size === "main"
              ? s.play_wrapper_main_videoSliderBottom
              : size === "middle"
              ? s.play_wrapper_middle_videoSliderBottom
              : s.play_wrapper_small_videoSliderBottom
          }
          key={"play_wrapper_bottom".concat(index)}
        >
          <div
            className={size === "main" ? s.play_bottom : s.play_small_bottom}
            onClick={clickHandler}
            key={"play_bottom".concat(index)}
          ></div>
        </div>
      )}
    </div>
  );
};
