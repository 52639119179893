import React from "react";
import { useQuery } from "@apollo/client";
import { GET_PROJECT } from "../../../apollo/query/query_projects";
import { Section } from "../Section/Section";
import { SectionTitle } from "../SectionTitle/SectionTitle";
import { Loader } from "../Loader/Loader";
import s from "./InfoList.module.css";

export const InfoList = ({ project, language }) => {
  const { loading, error, data } = useQuery(GET_PROJECT, {
    variables: {
      filter: {
        name: project,
      },
    },
  });

  if (loading) return <Loader />;
  if (error) return <div>error</div>;

  const goToForm = () => {
    const el = document.getElementById("goToForm");
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  };


  return (
    <Section className={s.section__margin__bottom}>
      <SectionTitle text={language === "RU" ? "Данные" : "Data"} />
      <ul className={s.info__list}>
        <li className={s.info__requisites_item}>
          <div className={s.info__requisites_title}>
            {" "}
            {language === "RU" ? "Название:" : "Name:"}
          </div>
          <div className={s.info__requisites_value}>
            {language === "RU"
              ? data.projects[0].name
              : data.projects[0].nameEn}
          </div>
        </li>
        <li className={s.info__requisites_item}>
          <div className={s.info__requisites_title}>
            {" "}
            {language === "RU" ? "Юрлицо" : "Entity"}
          </div>
          <div className={s.info__requisites__value_wrapper}>
            <div className={s.info__requisites_value}>
              {language === "RU"
                ? data.projects[0].requisites.name
                : data.projects[0].requisites.nameEn}
            </div>
            <div className={s.info__requisites_value}>
              {/* АНГЛ ПЕРЕДЕЛАТЬ  */}
              <span>ОГРН </span> {data.projects[0].requisites.OGRN}
            </div>
            <div className={s.info__requisites_value}>
              {/* АНГЛ ПЕРЕДЕЛАТЬ  */}
              <span>ИНН </span>
              {data.projects[0].requisites.INN}
            </div>
          </div>
        </li>
        <li className={s.info__requisites_item}>
          <div className={s.info__requisites_title}>
            {" "}
            {language === "RU" ? "Адрес:" : "Address:"}
          </div>
          <div className={s.info__requisites_wrapper}>
            <div className={s.info__requisites_value}>
              {language === "RU"
                ? data.projects[0].requisites.legal_address
                : data.projects[0].requisites.legal_addressEn}
            </div>
          </div>
        </li>
        <li className={s.info__requisites_item}>
          <div className={s.info__requisites_title}>
            {language === "RU"
              ? "Место ведения деятельности:"
              : "Place of business:"}
          </div>
          <div className={s.info__requisites_value}>
            {language === "RU"
              ? data.projects[0].requisites.address
              : data.projects[0].requisites.addressEn}
          </div>
        </li>
        {data.projects[0]?.support[0]?.name !== undefined && (
          <li className={s.info__requisites_item}>
            <div className={s.info__requisites_title}>
              {language === "RU"
                ? "Обеспечение по проектам:"
                : "Project collateral:"}
            </div>
            <div className={s.info__requisites_wrapper}>
              {data.projects[0].support.map((el) => (
                <div className={s.info__requisites_value} key={el.uuid}>
                  {language === "RU" ? el.name : el.nameEn}
                </div>
              ))}
            </div>
          </li>
        )}
        <li className={s.info__requisites_item}>
          <div className={s.info__requisites_title}>
            {language === "RU" ? "Договоры по проекту:" : "Project agreements:"}
          </div>
          <div className={s.info__requisites_wrapper}>
            <button className={s.info__callManager} onClick={goToForm}>
              {language === "RU"
                ? "Запросить у менеджера"
                : "Request from the manager"}
            </button>
          </div>
        </li>
        {/* <li className={s.info__requisites_item}>
          <div className={s.info__requisites_title}>Отчетность по проекту:</div>
          <div className={s.info__requisites_wrapper}>
            <div className={s.info__requisites_links}>
              <div className={s.info__links_download}>
                <div className={s.info__links_pdf}></div>
                <a className={s.info__links_text} href="">
                  Скачать pdf
                </a>
              </div>
              <div className={s.info__links_download_excel}>
                <div className={s.info__links_excel}></div>
                <a className={s.info__links_text} href="">
                  Скачать xls
                </a>
              </div>
            </div>
          </div>
        </li> */}
      </ul>
    </Section>
  );
};
