import React, { useState, useRef, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_MEDIAREPORTS } from "../../../apollo/query/query_media";
import { ReactPlayerLib } from "../ReactPlayerLib/ReactPlayerLib";
import { SliderNavigator } from "../SliderNavigator/SliderNavigator";
import { convertToDD_MMM_YYYY } from "../helper";
import s from "./VideoSliderBottom.module.css";

export const VideoSliderBottom = ({
  width,
  clientWidth,
  isTurnDevice,
  language,
}) => {
  // const slider = useRef(null);
  const slider = useRef(null);
  //   const projectSlider = useRef(null)
  let [position, setPosition] = useState(0);
  const [slideWidth, setSlideWidth] = useState(
    clientWidth > 1025
      ? 25.47
      : clientWidth > 769
      ? 46.679
      : clientWidth > 376
      ? 48.63
      : 94.41
  );
  const [counterPage, setCounterPage] = useState(1);
  const [picturePerSlide, setPicturePerSlide] = useState(3);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50;
  const player = useRef(null);
  const [visible, setVisible] = useState(false);
  const [visiblCube, setVisibleCube] = useState(false);
  const [id, setId] = useState("");
  const [idPlay, setIdPlay] = useState("");
  const { data } = useQuery(GET_ALL_MEDIAREPORTS);
  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    if (!data) return;
    const temp = [...data?.mediaReports];
    setVideoList([
      ...temp.sort((a, b) => new Date(b.datemarker) - new Date(a.datemarker)),
    ]);
  }, [data]);

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe)
      isLeftSwipe ? nextHandler() : prevHandler();
  };

  useEffect(() => {
    setPosition(0);
    setCounterPage(1);

    if (isTurnDevice) {
      slider.current.childNodes.forEach(
        (el) => (el.style = `transform: translateX(${0}vw`)
      );
    }

    if (clientWidth > 1024) {
      setSlideWidth(25.47);
    } else if (clientWidth > 769) {
      setSlideWidth(46.679);
    } else if (clientWidth > 376) {
      setSlideWidth(48.63);
    } else {
      setSlideWidth(94.41);
    }
  }, [width]);

  const nextHandler = () => {
    if (counterPage === videoList.length) return;
    setCounterPage(counterPage + 1);
    setPosition((position = position - 1 * slideWidth));
    slider.current.childNodes.forEach(
      // (el) => (el.style = `transform: translateX(${position}px)`)
      (el) => (el.style = `transform: translateX(${position}vw)`)
    );
  };

  const prevHandler = () => {
    if (counterPage === 1) return setPosition(0);
    setCounterPage(counterPage - 1);
    setPosition((position = position + 1 * slideWidth));
    slider.current.childNodes.forEach(
      // (el) => (el.style = `transform: translateX(${position}px)`)
      (el) => (el.style = `transform: translateX(${position}vw)`)
    );
  };

  const makePlay = (e, firstStartBottom) => {
    if (firstStartBottom) {
      return;
    } else {
      setVisibleCube(true);
      setId(e.target.id);
      setIdPlay(e.target.parentElement.id);
      // setTimeout(() => {
      //   setVisibleCube(false);
      // }, 1000);
    }
  };

  const makePlayAfterTimeOut = (e) => {
    // console.log("makePlayAfterTimeOut", idPlay, e.target);
  };

  const makeInVisibleButtonPlay = (e) => {
    setVisibleCube(false);
    setId(e.target.id);
    setIdPlay(e.target.parentElement.id);
  };

  return (
    <>
      <>
        <div className={s.wrapper} id="events">
          <div
            className={s.projectSlider}
            ref={slider}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
          >
            {videoList?.map((el, index) => (
              <div
                className={s.projectSlider__link}
                key={"projectSlider__link".concat(index)}
              >
                <div
                  className={
                    clientWidth > 376
                      ? index === counterPage - 1
                        ? s.projectSlider__link__project_wide
                        : index === counterPage
                        ? s.projectSlider__link__project_middle
                        : s.projectSlider__link__project
                      : s.projectSlider__link__project
                  }
                >
                  <ReactPlayerLib
                    makePlay={makePlay}
                    makePlayAfterTimeOut={makePlayAfterTimeOut}
                    makeInVisibleButtonPlay={makeInVisibleButtonPlay}
                    idPlay={idPlay}
                    id={id}
                    index={index}
                    visiblCube={visiblCube}
                    visible={visible}
                    width={
                      clientWidth > 1025
                        ? index === counterPage - 1
                          ? "32.4vw"
                          : "23.91vw"
                        : clientWidth > 769
                        ? index === counterPage - 1
                          ? "55.66vw"
                          : "36.52vw"
                        : clientWidth > 376
                        ? index === counterPage - 1
                          ? "46.09vw"
                          : "46.09vw"
                        : index === counterPage - 1
                        ? "89.33vw"
                        : "89.33vw"
                    }
                    height={
                      clientWidth > 1025
                        ? index === counterPage - 1
                          ? "32.4vw"
                          : index === counterPage
                          ? "16.2vw"
                          : "20.83vw"
                        : clientWidth > 769
                        ? index === counterPage - 1
                          ? "45.90vw"
                          : "27.83vw"
                        : clientWidth > 376
                        ? index === counterPage - 1
                          ? "46.09vw"
                          : "46.09vw"
                        : index === counterPage - 1
                        ? "89.33vw"
                        : "89.33vw"
                    }
                    video={el.video}
                    picture={el.picture}
                    videoSliderBottom
                    size={
                      index === counterPage - 1
                        ? "main"
                        : index === counterPage
                        ? "middle"
                        : "small"
                    }
                  />
                  {
                    <div
                      className={
                        index === counterPage - 1
                          ? s.description_wrapper_main
                          : index === counterPage
                          ? s.description_wrapper_middle
                          : s.description_wrapper
                      }
                    >
                      <h4
                        className={
                          index === counterPage - 1
                            ? s.description_title_main
                            : s.description_title
                        }
                      >
                        {language === "RU" ? el.title : el.titleEn}
                      </h4>
                      <span className={s.description_date}>
                        {convertToDD_MMM_YYYY(el.datemarker, language)}
                      </span>
                    </div>
                  }
                </div>
              </div>
            ))}
          </div>
          <SliderNavigator
            prevHandler={prevHandler}
            nextHandler={nextHandler}
            counterPage={counterPage}
            arrLength={videoList.length}
            className={s.navigation_position}
          />
        </div>
      </>
    </>
  );
};
