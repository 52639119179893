import React from "react";
import { Section } from "../../ProjectPage/Section/Section";
import s from "./MediaSectionTitle.module.css";
import clsx from "clsx";

export const MediaSectionTitle = ({ text1, text2, text3, className }) => {
  return (
    <Section className={clsx(s.section_width, className)}>
      <div>
        <h2 className={s.firstLine}>{text1}</h2>
        <h2 className={s.secondLine}>{text2}</h2>
        <h2 className={s.thirdLine}>{text3}</h2>
      </div>
    </Section>
  );
};
