// import { ApolloClient, InMemoryCache} from "@apollo/client"

// const client = new ApolloClient({
//   uri: process.env.REACT_APP_API_URL_CLIENT,
//   cache: new InMemoryCache()
// })

// export default client

import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  concat,
} from '@apollo/client'
import { offsetLimitPagination } from '@apollo/client/utilities'

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL_CLIENT, // адрес сервера
  credentials: 'same-origin', 
})

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: localStorage.getItem('token') || null,
    },
  }))

  return forward(operation)
})

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          feed: offsetLimitPagination(),
        },
      },
    },
  }),
})

export default client