import React from "react";
import s from "./SocialLinks.module.css";

export function SocialLinks({ info }) {
  return (
    <div className={s.social_links}>
      <a className={s.youtube} href={info?.socnetYoutube}>
        {" "}
      </a>
      <a className={s.telegramm} href={info?.socnetTelegram}>
        {" "}
      </a>
      <a className={s.instagramm} href={info?.socnetInstagram}>
        {" "}
      </a>
      <a className={s.VK_icon} href={info?.socnetVK}>
        {" "}
      </a>
    </div>
  );
}
