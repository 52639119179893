import React from "react";
import s from "./OpeningText.module.css";

// елемент раскрывающихся блоков (раскрывашка). Принимает элемент из мапа

export const OpeningText = ({ elem, language }) => {
  return (
    <>
      <details className={s.container}>
        <summary className={s.title}>
          {language === "RU" ? elem.title : elem.titleEn}
        </summary>
        <p className={s.description}>
          {" "}
          {language === "RU" ? elem.decription : elem.descriptionEn}
        </p>
      </details>
    </>
  );
};
