import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import Button from "../button/Button";
import Personal from "./PersonalData";
import ModalSuccess from "./ModalSuccess";
import ModalError from "./ModalError";
import Preloader from "./Preloader";
import emailjs from "emailjs-com";
import clsx from "clsx";

export default function Form({ formType = "main", className, language }) {
  const [isPersonal, setIsPersonal] = useState(false);
  const [isActiveModalSuccess, setActiveModalSuccess] = useState(false);
  const [isActiveModalError, setActiveModalError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [checked, setChecked] = useState(false);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onSubmit",
  });

  // Параметры для EmailJS
  const emailParams = {
    serviceID: "service_2z3jzsf",
    userID: "DAh5We756DeI6KBom",
    templateID: formType === "main" ? "template_lz10n1n" : "template_5tbrq58",
  };

  // Параметры для Bitrix
  // const bitrixURL =
  //   formType === 'main' ? 'https://server99.ru/api/bitrix24/send' : ''

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    const url = new URL(window.location.origin);

    try {
      // Отправка данных в BITRIX --- сейчас, если форма со странички "бизнес", то не отправляем на битрикс
      if (formType === "main") {
        const bitrixResponse = await axios.post(
          "https://server99.ru/api/bitrix24/send",
          {
            name,
            phone,
            message,
            utm: {
              UTM_SOURCE: url.searchParams.get("utm_source"),
              UTM_MEDIUM: url.searchParams.get("utm_medium"),
              UTM_CONTENT: url.searchParams.get("utm_content"),
            },
          }
        );
      }

      // Отправка данных в EmailJS
      const form = e.target;
      const emailResponse = await emailjs.sendForm(
        emailParams.serviceID,
        emailParams.templateID,
        form,
        emailParams.userID //
      );
      setActiveModalSuccess(true);

      // Асинхронно закрываем модальное окно успеха через 5 секунд
      setTimeout(() => setActiveModalSuccess(false), 5000);
    } catch (error) {
      console.error("Error:", error);
      setActiveModalError(true);
      setTimeout(() => setActiveModalError(false), 5000);
    }

    setIsLoading(false);
    reset();
  };

  // const onSubmit = async () => {
  //   setIsLoading(true)

  //   const url = new URL(window.location.origin)
  //   try {
  //     axios.post("https://server99.ru/api/bitrix24/send", {
  //       name,
  //       phone,
  //       message,
  //       utm: {
  //         UTM_SOURCE: url.searchParams.get("utm_source"),
  //         UTM_MEDIUM: url.searchParams.get("utm_medium"),
  //         UTM_CONTENT: url.searchParams.get("utm_content"),
  //       },
  //     });

  //     setIsLoading(false)
  //     setActiveModalSuccess(true)
  //     setTimeout(setActiveModalSuccess, 5000)
  //   } catch (error) {
  //     console.log(error)
  //     setIsLoading(false)
  //     setActiveModalError(true)
  //     setTimeout(setActiveModalError, 5000)
  //   }
  //   setChecked(false)
  //   reset()
  // }

  const openPersonal = () => {
    setIsPersonal(true);
  };
  const closePersonal = () => {
    setIsPersonal(false);
  };

  const clouseModal = () => {
    setActiveModalSuccess(false);
    setActiveModalError(false);
  };

  return (
    <>
      <form
        name="form"
        className={clsx("form", className)}
        onSubmit={handleSubmit(onSubmit)}
        id="goToForm"
      >
        <div className="form__line">
          <label htmlFor="name" className="form_labels">
            {language === "RU" ? "ИМЯ" : "NAME"}
          </label>
          <input
            name="name"
            {...register("name", {
              required:
                language === "RU"
                  ? "Это поле обязательно"
                  : "This field is required",
              minLength: {
                value: 2,
                message:
                  language === "RU"
                    ? "Минимум 2 символа"
                    : "Minimum of 2 characters",
              },
              maxLength: {
                value: 50,
                message:
                  language === "RU"
                    ? "Максимум 50 символов"
                    : "Maximum of 50 characters",
              },
            })}
            id="name"
            className="form__input"
            maxLength="40"
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div>
          {errors?.name && (
            <p className="form__focus">{errors?.name?.message || "Error!"}</p>
          )}
        </div>
        <div className="form__borders"></div>
        <div className="form__line">
          <label htmlFor="phone" className="form_labels">
            {language === "RU" ? "ВАШ ТЕЛЕФОН" : "YOUR PHONE NUMBER"}
          </label>
          <input
            name="phone"
            {...register("phone", {
              required:
                language === "RU"
                  ? "Это поле обязательно"
                  : "This field is required",
              pattern: {
                value: /^\+?[78][-\(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/,
                message:
                  language === "RU"
                    ? "Введите в формате +79000000000"
                    : "Enter in the format +79000000000",
              },
            })}
            id="phone"
            className="form__input_phone"
            type="tel"
            placeholder="+79000000000"
            maxLength="12"
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div>
          {errors?.phone && (
            <p className="form__focus">{errors?.phone?.message || "Error!"}</p>
          )}
        </div>
        <div className="form__borders"></div>
        <div className="form__line">
          <label htmlFor="message" className="form_labels">
            {language === "RU" ? "СООБЩЕНИЕ" : "MESSAGE"}
          </label>
          <textarea
            name="message"
            {...register("message")}
            id="message"
            className="form__textarea"
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div>
          {errors?.message && (
            <p className="form__focus">
              {errors?.message?.message || "Error!"}
            </p>
          )}
        </div>
        <div className="form__borders"></div>
        <div className="agree_and_button">
          <div className="form__policy">
            <label className="form__consent">
              <input
                name="consent"
                type="checkbox"
                checked={checked}
                onChange={() => setChecked(!checked)}
                {...register("consent", {
                  required:
                    language === "RU"
                      ? "Подтвердите свое согласие для отправки формы"
                      : "Confirm your consent to submit the form",
                })}
                id="consent"
                className="form__checkbox"
              />
              <span
                className="form__checkfake"
                onClick={() => setChecked(!checked)}
              ></span>
            </label>
            <p className="form__policy_text">
              {language === "RU"
                ? "Я соглашаюсь на обработку"
                : "I agree to processing"}
              &nbsp;<br></br>
              <span
                onClick={openPersonal}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  lineHeight: "200%",
                }}
              >
                {language === "RU" ? "персональных данных" : "personal data"}
              </span>
            </p>
          </div>
          <div className="form__btn">
            <Button
              title={language === "RU" ? "ОТПРАВИТЬ" : "SEND"}
              type="submit"
              disabled={!isValid}
            />
          </div>
        </div>
        <div className="form__focus_consent">
          {errors?.consent && <p>{errors?.consent?.message || "Error!"}</p>}
        </div>
      </form>
      {isActiveModalSuccess && <ModalSuccess clouseModal={clouseModal} />}
      {isActiveModalError && <ModalError clouseModal={clouseModal} />}
      {isPersonal && (
        <Personal closePersonal={closePersonal} setChecked={setChecked} />
      )}
      {isLoading && <Preloader />}
    </>
  );
}
