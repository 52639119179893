import React from "react";
import s from "./Button.module.css";
import clsx from "clsx";

export default function Button({ title, color, className, onClick }) {
  return (
    <>
      <button
        onClick={onClick}
        className={clsx(
          className,
          s.button,
          color === "white" && s.button__white
        )}
      >
        {title}
      </button>
    </>
  );
}
