import { gql } from "@apollo/client";

export const GET_ALLFAQ = gql`
  query allDataFaq {
    allDataFaq {
      id
      uuid
      title
      titleEn
      decription
      descriptionEn
      orderNum
    }
  }
`;
