import React, { useEffect } from "react";
import s from "./Menu.module.css";
import { MenuFooter } from "../MenuFooter/MenuFooter";
import { Link } from "react-router-dom";
import { DropdownLanguage } from "../DropdownLanguage/DropdownLanguage";

export function Menu({ openBurger, setOpenBurger, width, language }) {
  //Блокировка скрола
  useEffect(() => {
    openBurger && (document.body.style.overflow = "hidden");
  }, [openBurger]);

  //Разблокировка скрола
  useEffect(() => {
    !openBurger && (document.body.style.overflow = "unset");
  }, [openBurger]);

  const scrollToTop = () =>
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  return (
    <div className={openBurger ? s.menu_open : s.menu_close}>
      <nav className={s.navbar}>
        <ul className={s.menu_list}>
          <li className={s.string}>
            <Link to="/" className={s.text} onClick={scrollToTop}>
              {language === "RU" ? "О нас" : "About us"}
            </Link>
            <p className={s.number}>(01)</p>
          </li>
          <li className={s.string}>
            <Link to="/projects" className={s.text} onClick={scrollToTop}>
              {language === "RU" ? "Проекты" : "Projects"}
            </Link>
            <p className={s.number}>(02)</p>
          </li>
          <li className={s.string}>
            <Link to="/media" className={s.text} onClick={scrollToTop}>
              {language === "RU" ? "Медиа" : "Media"}
            </Link>
            <p className={s.number}>(03)</p>
          </li>
          {/* <li className={s.string}>
            <Link to="/business" className={s.text} onClick={scrollToTop}>
              {language === "RU" ? "Бизнесу" : "For business"}
            </Link>
            <p className={s.number}>(04)</p>
          </li> */}
          <li className={s.string}>
            <Link to="/faq" className={s.text} onClick={scrollToTop}>
              {language === "RU" ? "FAQ" : "FAQ"}
            </Link>
            <p className={s.number}>(05)</p>
          </li>

          {/* <li className={s.string}>
            <Link to="/faq" className={s.text} onClick={scrollToTop}>
              Документы
            </Link>
            <p className={s.number}>(06)</p>
          </li> */}

          {/* <li className={s.string}>
            <Link to="/faq" className={s.text} onClick={scrollToTop}>
              Контакты
            </Link>
            <p className={s.number}>(07)</p>
          </li> */}
        </ul>
      </nav>
      {width < 430 && openBurger ? (
        <DropdownLanguage
          className={s.menu__dropdown}
          openBurger={openBurger}
        />
      ) : null}
      <MenuFooter
        className={s.menu}
        openBurger={openBurger}
        setOpenBurger={setOpenBurger}
        language={language}
      />
    </div>
  );
}
