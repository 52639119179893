import React from "react";
import { MediaSectionTitle } from "../MediaSectionTItle/MediaSectionTitle";
import { VideoSlider } from "../VideoSlider/VideoSlider";
import { Section } from "../../ProjectPage/Section/Section";
import s from "./InterviewSection.module.css";

export const InterviewSection = ({
  width,
  clientWidth,
  isTurnDevice,
  language,
}) => {
  return (
    <>
      <Section className={s.wrapper}>
        <MediaSectionTitle
          text1={language === "RU" ? "интервью" : "interview"}
          text2={language === "RU" ? "c основателями" : "WITH THE FOUNDERS"}
          text3={language === "RU" ? "и собственниками" : "AND THE OWNERS"}
        />
        <VideoSlider
          width={width}
          clientWidth={clientWidth}
          isTurnDevice={isTurnDevice}
          language={language}
        />
      </Section>
    </>
  );
};
