import React, { useState, useRef, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_COMMENTS } from "../../../apollo/query/query_media";
import { reviewsList } from "./data";
import { TextCard } from "../textCard/TextCard";
import { SliderNavigator } from "../SliderNavigator/SliderNavigator";
import { convertToDD_MMM_YYYY } from "../helper";
import s from "./TextSlider.module.css";

export const TextSlider = ({ clientWidth, width, isTurnDevice, language }) => {
  const slider = useRef(null);
  let [position, setPosition] = useState(0);
  const [slideWidth, setSlideWidth] = useState(
    clientWidth > 1024
      ? 31.25
      : clientWidth > 769
      ? 55.08
      : clientWidth > 376
      ? 48.697
      : 89.333
  );

  const [counterPage, setCounterPage] = useState(1);
  const [sliderToggler, setSliderToggler] = useState(true);
  const [tempArr, setTempArr] = useState([]);
  const [arrLength] = useState(reviewsList.length);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [comments, setComments] = useState([]);
  const { data } = useQuery(GET_ALL_COMMENTS);
  const minSwipeDistance = 50;


  useEffect(() => {
    if (!data || data?.mediaReviewsText === null) return;
    const temp = [...data?.mediaReviewsText];
    const tempComments = [
      ...temp.sort((a, b) => new Date(b.datemarker) - new Date(a.datemarker)),
    ];
    setTempArr(
      tempComments.map((el, index) => ({ ...el, isOpen: false, id: index }))
    );
    setComments([
      ...temp.sort((a, b) => new Date(b.datemarker) - new Date(a.datemarker)),
    ]);
  }, [data]);

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe)
      isLeftSwipe ? nextHandler() : prevHandler();
  };

  useEffect(() => {
    setPosition(0);
    setCounterPage(1);

    if (isTurnDevice) {
      slider.current.childNodes.forEach(
        (el) => (el.style = `transform: translateX(${0}vw`)
      );
    }

    if (clientWidth > 1024) {
      setSlideWidth(31.25);
    } else if (clientWidth > 769) {
      setSlideWidth(55.08);
    } else if (clientWidth > 376) {
      setSlideWidth(48.697);
    } else {
      setSlideWidth(89.333);
    }
  }, [clientWidth]);

  const nextHandler = () => {
    if (counterPage === tempArr.length) return;
    setCounterPage(counterPage + 1);
    setPosition((position = position - 1 * slideWidth));
    slider.current.childNodes.forEach(
      // (el) => (el.style = `transform: translateX(${position}px)`)
      (el) => (el.style = `transform: translateX(${position}vw)`)
    );
  };

  const prevHandler = () => {
    if (counterPage === 1) return setPosition(0);
    setCounterPage(counterPage - 1);
    setPosition((position = position + 1 * slideWidth));
    slider.current.childNodes.forEach(
      // (el) => (el.style = `transform: translateX(${position}px)`)
      (el) => (el.style = `transform: translateX(${position}vw)`)
    );
  };

  const openFullText = (e, index) => {
    tempArr[index].isOpen === true
      ? (tempArr[index].isOpen = false)
      : (tempArr[index].isOpen = true);
    setTempArr([...tempArr]);
  };

  const changeSliderToggler = (e) => {
    if (e.target.id === "Текстовые отзывы" && sliderToggler === false) {
      setSliderToggler(true);
    }
    if (e.target.id === "Видео-отзывы" && sliderToggler === true) {
      setSliderToggler(false);
    }
  };

  return (
    <>
      <div className={s.wrapper} id="media_review">
        <div className={s.toggler_text}>
          <span
            className={
              sliderToggler
                ? s.toggler_text_underline
                : s.toggler_text_noUnderline
            }
            onClick={(e) => changeSliderToggler(e)}
            id={"Текстовые отзывы"}
          >
            {language === "RU" ? "Текстовые отзывы" : "Text reviews"}
          </span>
          <span
            className={
              !sliderToggler
                ? s.toggler_text_underline
                : s.toggler_text_noUnderline
            }
            onClick={(e) => changeSliderToggler(e)}
            id={"Видео-отзывы"}
          >
            {language === "RU" ? "Видео-отзывы" : "Video reviews"}
          </span>
        </div>
        <div
          className={s.projectSlider}
          ref={slider}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          {tempArr.map((el, index) => (
            <TextCard
              name={
                language === "RU"
                  ? `${el.firstname} ${el.lastname}`
                  : `${el.firstnameEn} ${el.lastnameEn} `
              }
              date={convertToDD_MMM_YYYY(el.datemarker, language)}
              picture={el.avatar}
              source={el.sourse}
              index={index}
              isOpen={el.isOpen}
              text={language === "RU" ? el.comment : el.commentEn}
              textLength={el.comment.length}
              openFullText={openFullText}
              key={"TextCard".concat(index)}
            />
          ))}
        </div>
        <div className={s.navigation}>
          <SliderNavigator
            prevHandler={prevHandler}
            nextHandler={nextHandler}
            counterPage={counterPage}
            arrLength={comments?.length}
            className={s.navigation_position}
          />
        </div>
      </div>
    </>
  );
};
