import React, { useState, useRef, useEffect } from "react";
import s from "./DropdownLanguage.module.css";
import eng_img from "../img/eng_flag.png"; // загрузить нормальную svg
import rus_img from "../img/rus_flag.png";
import arrow_down from "../img/arrow-down.svg";
import arrow_up from "../img/arrow-up.svg";
import { useLanguage } from "../../../providers/LanguageContext";

export const DropdownLanguage = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { currentLanguage, toggleLanguage } = useLanguage();

  const languages = [
    { lang: "RU", img: rus_img },
    { lang: "ENG", img: eng_img },
  ];

  const handleLanguageChange = (lang) => {
    toggleLanguage(lang);
    setIsOpen(false);
  };

  const openHandler = () => {
    setIsOpen(!isOpen);
  };

  const currentLangData = languages.find(
    (lang) => lang.lang === currentLanguage
  );

  // Закрытие dropdown при клике вне его
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div
      className={`${s.dropdown} ${className} ${isOpen ? s.dropdown__open : ""}`}
      ref={dropdownRef}
    >
      <div className={s.dropdown__item} onClick={openHandler}>
        <div className={s.lang__wrapper}>
          <img
            className={s.lang__img}
            src={currentLangData.img}
            alt={currentLangData.lang}
          />
          <span className={s.dropdown__text}>{currentLangData.lang}</span>
        </div>
        <button className={s.dropdown__button}>
          <img
            className={s.arrow__icon}
            src={isOpen ? arrow_up : arrow_down}
            alt="toggle"
          />
        </button>
      </div>

      {isOpen && (
        <div className={s.dropdown__list}>
          {languages
            .filter((lang) => lang.lang !== currentLanguage)
            .map((lang) => (
              <div
                key={lang.lang}
                className={s.dropdown__item}
                onClick={() => handleLanguageChange(lang.lang)}
              >
                <div className={s.lang__wrapper}>
                  <img className={s.lang__img} src={lang.img} alt={lang.lang} />
                  <span className={s.dropdown__text}>{lang.lang}</span>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
