import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { ALL_PROVIDE } from "../../../../apollo/query/query_projects";
import { LoaderWrapper } from "../../../../Pages/ProjectPage/LoaderWrapper/LoaderWrapper";
import s from "./Provide.module.css";

export function Provide({ errorHandleRange, language }) {
  const [showDescription, setShowDescription] = useState(false);
  const [useKey, setUseKey] = useState("");

  const findElementOnTap = (event, key) => {
    setUseKey(event.target.parentElement.parentElement.className);
    event.target.parentElement.parentElement.className === key
      ? setShowDescription(!showDescription)
      : setShowDescription(false);
  };

  const { data, loading, error } = useQuery(ALL_PROVIDE);


  if (loading) {
    return <LoaderWrapper className={s.loader} />;
  }

  if (error) {
    return (
      <div>
        <LoaderWrapper />
      </div>
    );
  }

  return (
    <div className={s.instuction_and_provide__container}>
      <div className={s.instuction}>
        <p className={s.instruction__title}>
          {language === "RU"
            ? "Калькулятор доходности"
            : "Profitability calculator"}
        </p>
        <div className={s.instruction__text}>
          <p>
            {language === "RU"
              ? "Двигайте ползунок или напишите сумму — калькулятор покажет, ориентировочный доход при сотрудничестве с разными проектами."
              : "Move the slider or write the amount - the calculator will show the estimated income when collaborating with different projects."}
          </p>
          <p>
            {language === "RU"
              ? "Не является офертой. Расчет приблизительный — детальные и актуальные условия сотрудничества узнавайте у представителя проекта."
              : "Not an offer. The calculation is approximate - please contact the project representative for detailed and current terms of cooperation."}
          </p>
          <p>
            {language === "RU"
              ? "Нажимайте на карточки с логотипами, чтобы увидеть подробности о каждом из проектов."
              : "Click on the logo cards to see details about each project."}
          </p>
          <p>
            {language === "RU"
              ? "Листайте страницы с проектами — стрелки находятся под карточками с логотипами."
              : "Scroll through the pages with projects - the arrows are located under the cards with logos."}
          </p>

          <p
            className={
              errorHandleRange ? s.instuction_with_handle_range_error : ""
            }
          >
            {language === "RU"
              ? "Калькулятор указывает доходность для инвестиций до 10 млн ₽. Хотите узнать возможную доходность от большей суммы — оставьте заявку."
              : "The calculator indicates the return on investment up to 10 million rubles (107600 USD). If you want to know the possible profitability of a larger amount, leave a request."}
          </p>
        </div>
      </div>

      <div className={s.poride__block}>
        <p className={s.provide__title}>
          {" "}
          {language === "RU" ? "виды обеспечения" : "TYPES OF SECURITY"}
        </p>

        {/* ПРИЛЕТАЕТ С БЭКА */}
        {data.projectProcurings.map((elem) => (
          <div
            key={elem.uuid + "provide"}
            className={elem.uuid}
            onClick={(event) => findElementOnTap(event, elem.uuid)}
          >
            <div className={s.provide__line_text}>
              <p className={s.provide__text}>
                {" "}
                {language === "RU" ? elem.name : elem.nameEn}{" "}
              </p>
              <div className={s.provide__quastion}></div>
            </div>

            <p
              className={s.provide__description}
              style={{
                display:
                  useKey === elem.uuid && showDescription ? "block" : "none",
              }}
            >
              {language === "RU" ? elem.description : elem.descriptionEn}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
