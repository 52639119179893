import React from "react";
import { Section } from "../../ProjectPage/Section/Section";
import s from "./Navigation.module.css";
import { HashLink } from "react-router-hash-link";
import clsx from "clsx";

export const Navigation = ({ clientWidth, language }) => {
  const goToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div id="topMedia">
      <Section className={s.section_wrapper}>
        {clientWidth > 376 ? (
          <div>
            <h2 className={s.title}>
              {language === "RU" ? "Узнайте больше" : "Learn more about"}
            </h2>
          </div>
        ) : (
          <div className={s.small_screen_firstLine}>
            <h2 className={s.title}>
              {language === "RU" ? "Узнайте" : "Learn"}
            </h2>
            <h2 className={clsx(s.title, s.title_small_screen)}>
              {language === "RU" ? "больше" : "more"}
            </h2>
          </div>
        )}
        <div className={s.secondLine_wrapper}>
          <div className={s.button_position}>
            <ul className={s.button_wrapper}>
              <li>
                {/* <HashLink
                  smooth
                  to="/media#media_interview"
                  className={clsx(s.button, s.button1)}
                  onClick={() => goToSection("interview")}
                >
                  Интервью
                </HashLink> */}
                <a
                  // smooth
                  to="/media#media_interview"
                  className={clsx(s.button, s.button1)}
                  onClick={() => goToSection("interview")}
                >
                  {language === "RU" ? "Интервью" : "Interview"}
                </a>
              </li>
              <li>
                {/* <HashLink
                  to="/media#media_news"
                  className={clsx(s.button, s.button2)}
                  onClick={() => goToSection("news")}
                >
                  СМИ о нас
                </HashLink> */}
                <a
                  // smooth
                  to="/media#media_interview"
                  className={clsx(s.button, s.button2)}
                  onClick={() => goToSection("news")}
                >
                  {language === "RU" ? "Статьи" : "Articles"}
                </a>
              </li>
              <li>
                {/* <HashLink
                  smooth
                  to="/media#media_events"
                  className={clsx(s.button, s.button3)}
                  onClick={() => goToSection("events")}
                >
                  Мероприятия
                </HashLink> */}
                <a
                  // smooth
                  to="/media#media_events"
                  className={clsx(s.button, s.button3)}
                  onClick={() => goToSection("events")}
                >
                  {language === "RU" ? "Мероприятия" : "Events"}
                </a>
              </li>
              <li>
                {/* <HashLink
                  smooth
                  to="/media#media_raview"
                  className={clsx(s.button, s.button4)}
                  onClick={() => goToSection("media_review")}
                >
                  Отзывы
                </HashLink> */}
                <a
                  // smooth
                  to="/media#media_raview"
                  className={clsx(s.button, s.button4)}
                  onClick={() => goToSection("media_review")}
                >
                  {language === "RU" ? "Отзывы" : "Reviews"}
                </a>
              </li>
            </ul>
          </div>
          <div className={s.secontLine_title}>
            <h1 className={s.title}>
              {language === "RU" ? "о Frontiers" : "Frontiers"}
            </h1>
          </div>
        </div>
      </Section>
    </div>
  );
};
