import React, {useState, useEffect} from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_OUTLETS } from "../../../apollo/query/query_media"
import { NewsBlock } from "./newsBlock/NewsBlock";
import { MediaSectionTitle } from "../MediaSectionTItle/MediaSectionTitle";
import { Section } from "../../ProjectPage/Section/Section";
import s from "./NewsFeed.module.css";

export const NewsFeed = ({ language}) => {
  const { data } = useQuery(GET_ALL_OUTLETS)
  const [ outletsList, setOutletList] = useState([])

  useEffect(() => {
    if (!data) return;
    const temp = [...data?.mediaOutlets];
    setOutletList([...temp?.sort((a, b) => new Date(b.datemarker) - new Date(a.datemarker))]);
  }, [data]);

  
  return (
    <>
      <Section className={s.wrapper}>
        <MediaSectionTitle
          className={s.mediaSectionTitle}
          text1={language === "RU" ? "пишут о нас" : "THEY WRITE ABOUT US"}
          text2={language === "RU" ? "пишут о нас" : "WE WRITE FOR YOU"}
        />
        <div className={s.block__newsfeed} id="news">
          <div className={s.main__newsfeed_flex}>
            <div className={s.main__newsfeed_container}>
              {outletsList?.map((elem) => (
                <NewsBlock language={language} elem={elem} key={elem.uuid} />
              ))}
            </div>
            {/* <button>загрузить еще</button> */}
          </div>
        </div>
      </Section>
    </>
  );
};
