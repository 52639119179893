import React, { useState, useEffect } from "react";
import s from "./DropdownCountryItem.module.css";
import checkbox_active from "../../img/checkbox-active.svg";
import checkbox_unactive from "../../img/checkbox-unactive.svg";

export const DropdownCountryItem = ({
  changeSelectedCountries,
  flag,
  country,
  countryEn,
  isChecked,
  className,
  language,
}) => {
  const [checked, setChecked] = useState(isChecked);

  const handleOnChange = () => {
    setChecked(!checked);
    changeSelectedCountries(country);
  };

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <div className={s.dropdown__item + " " + className}>
      <div className={s.dropdown__checkbox} onClick={handleOnChange}>
        <input
          className={s.dropdown__checkbox_input}
          type="checkbox"
          value=""
          checked={checked}
          onChange={handleOnChange}
        />
        <img
          className={s.dropdown__checkbox_img}
          src={isChecked ? checkbox_active : checkbox_unactive}
          alt="checkbox"
        />
      </div>
      <div className={s.dropdown__country_wrapper}>
        <img className={s.dropdown__flag} src={flag} alt="" />
        <p className={s.dropdown__country}>
          {" "}
          {language === "RU" ? country : countryEn}
        </p>
      </div>
    </div>
  );
};
