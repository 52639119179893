import React, { useState } from "react";
// import { DataProjects } from "../DataProjects"

import { useQuery } from "@apollo/client";
import { ALL_PROJECTS_TO_CARD } from "../../../../apollo/query/query_projects";
import { LoaderWrapper } from "../../../ProjectPage/LoaderWrapper/LoaderWrapper";
import { Link } from "react-router-dom";

//КОД НУЖНО ОТРЕФАКТОРИТЬ, КУЧА МУСОРА 

export function ProjectCardsSmallMobail({ handleValue, language }) {
  const [showDescription, setShowDescription] = useState(false);
  const [useKey, setUseKey] = useState("");
  const [useProjectKey, setUseProjectKey] = useState("");
  const [openFullProjectDescription, setOpenFullProjectDescription] =
    useState(false);

  const { data, loading, error } = useQuery(ALL_PROJECTS_TO_CARD);

  if (loading) {
    return <LoaderWrapper />;
  }

  if (error)
    return (
      <div>
        <LoaderWrapper />
      </div>
    );

  const findElementOnTap = (event, key) => {
    setUseKey(event.target.parentElement.parentElement.className);
    event.target.parentElement.parentElement.className === key
      ? setShowDescription(!showDescription)
      : setShowDescription(false);
  };

  const findProjectOnTap = (event, uuid) => {
    event?.target?.parentElement.parentElement.parentElement.parentElement
      .className === uuid
      ? setUseProjectKey(
          event.target.parentElement.parentElement.parentElement.parentElement
            .className
        )
      : event?.target?.parentElement.parentElement.parentElement.className ===
        uuid
      ? setUseProjectKey(
          event.target.parentElement.parentElement.parentElement.className
        )
      : event?.target?.parentElement.parentElement.className === uuid
      ? setUseProjectKey(event.target.parentElement.parentElement.className)
      : setUseProjectKey("");

    event?.target?.parentElement.parentElement.parentElement.parentElement
      .className === uuid ||
    event?.target?.parentElement.parentElement.parentElement.className ===
      uuid ||
    event?.target?.parentElement.parentElement.className === uuid
      ? setOpenFullProjectDescription(!openFullProjectDescription)
      : setOpenFullProjectDescription(false);

    // console.log(useProjectKey)
    // console.log(uuid)
    // console.log(openFullProjectDescription)
  };

  // console.log(data.projects[3].support)

  return (
    <div>
      <div className="small_mobail__project_cards__block">
        {data.projects.map((elem) =>
          (elem?.projectStatusNew !== "archive") &
          (elem?.projectStatusNew !== "draft") ? (
            +handleValue < +elem.minInvestment ||
            elem?.projectStatusNew === "suspended" ? (
              // Инвестиции ниже минимльных? РИСУЕМ СЕРУЮ КАРТОЧКУ
              //1. Общий блок
              <div
                className="small_mobail_project__card"
                key={elem.uuid + "small_mobail_project__card"}
              >
                {/* 2.  Изменяемый Див с видимой и невидимой частью карточки */}
                <div
                  className={
                    openFullProjectDescription === true &&
                    useProjectKey === elem.uuid
                      ? "small_mobail_project_card__grey small_mobail_project_card__grey__open"
                      : "small_mobail_project_card__grey"
                  }
                >
                  {/* 3. Всегда видимая часть */}
                  <div
                    className={elem.uuid}
                    onClick={(event) => findProjectOnTap(event, elem.uuid)}
                  >
                    <div className="small_mobail_project_card_grey__visible">
                      <div
                        className="small_mobail_project_card__grey_logo"
                        style={{
                          backgroundImage: `url('https://frontiersserver.ru${elem.projectImages.logo.substring(
                            26
                          )}')`,
                        }}
                      ></div>
                      {/* инфа, если проект на стопе */}
                      {elem.projectStatusNew !== "suspended" ? (
                        <div className="small_mobail_project_card__min_invest">
                          <p className="small_mobail_project_card_min_invest__numbers">
                            {Number(elem.minInvestment).toLocaleString(
                              "ru-RU"
                            ) + " ₽"}
                          </p>
                          <p className="small_mobail_project_card_min_invest__description">
                            {language === "RU"
                              ? "Мин. размер инвестиций"
                              : "Min. investment size"}
                          </p>
                        </div>
                      ) : (
                        <p className="small_mobail_project_card__cadrs_block_stop">
                          {language === "RU"
                            ? "Привлечение средств"
                            : "Raising funds"}
                          <br></br>{" "}
                          {language === "RU"
                            ? "временно приостановлено"
                            : "is temporarily suspended"}
                        </p>
                      )}
                      <div
                        className={
                          openFullProjectDescription &&
                          useProjectKey === elem.uuid
                            ? "small_mobail_project_card__arrow_to_down_grey small_mobail_project_card__arrow_to_up_grey"
                            : "small_mobail_project_card__arrow_to_down_grey"
                        }
                      ></div>
                    </div>
                  </div>
                  {/* 4. Раскрывающийся блок */}
                  <div className="small_mobail_after_click__project_block">
                    <div className="small_mobail_after_click__information_about_project">
                      <p className="small_mobail_after_click__description">
                        {language === "RU"
                          ? elem?.description
                          : elem?.descriptionEn}
                      </p>
                      {/* скрыть, если проект на стопе */}
                      {elem.projectStatusNew !== "suspended" ? (
                        <div>
                          <div className="small_mobail_after_click__handle_invest">
                            <p className="small_mobail_after_click__handle_invest__numbers">
                              {Number(handleValue).toLocaleString("ru-RU") +
                                " ₽"}
                            </p>
                            <p className="small_mobail_after_click__handle_invest__description">
                              {language === "RU"
                                ? "Размер инвестиций"
                                : "Investment size"}
                            </p>
                          </div>
                          <div className="small_mobail_after_click____income_money">
                            {/* счетчик суммы */}
                            {language === "RU" ? (
                              <p className="small_mobail_after_click____income_money__numbers">
                                {
                                  //проверка на существование 5го в массиве порогов
                                  elem?.investThresholds[4]
                                    ? handleValue >=
                                      elem?.investThresholds[4].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[4]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") + " ₽ в год"
                                      : handleValue >=
                                          elem?.investThresholds[3].sum &&
                                        handleValue <
                                          elem?.investThresholds[4].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[3]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") + " ₽ в год"
                                      : handleValue >=
                                          elem?.investThresholds[2].sum &&
                                        handleValue <
                                          elem?.investThresholds[3].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[2]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") + " ₽ в год"
                                      : handleValue >=
                                          elem?.investThresholds[1].sum &&
                                        handleValue <
                                          elem?.investThresholds[2].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[1]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") + " ₽ в год"
                                      : handleValue >=
                                          elem?.investThresholds[0].sum &&
                                        handleValue <
                                          elem?.investThresholds[1].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[0]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") + " ₽ в год"
                                      : Number(
                                          (handleValue *
                                            elem?.investThresholds[0]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") + " ₽ в год"
                                    : //проверка на существование 4го в массиве порогов
                                    elem?.investThresholds[3]
                                    ? handleValue >=
                                      elem?.investThresholds[3].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[3]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") + " ₽ в год"
                                      : handleValue >=
                                          elem?.investThresholds[2].sum &&
                                        handleValue <
                                          elem?.investThresholds[3].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[2]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") + " ₽ в год"
                                      : handleValue >=
                                          elem?.investThresholds[1].sum &&
                                        handleValue <
                                          elem?.investThresholds[2].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[1]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") + " ₽ в год"
                                      : handleValue >=
                                          elem?.investThresholds[0].sum &&
                                        handleValue <
                                          elem?.investThresholds[1].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[0]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") + " ₽ в год"
                                      : Number(
                                          (handleValue *
                                            elem?.investThresholds[0]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") + " ₽ в год"
                                    : //проверка на существование 3го в массиве порогов
                                    elem?.investThresholds[2]
                                    ? handleValue >=
                                      elem?.investThresholds[2].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[2]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") + " ₽ в год"
                                      : handleValue >=
                                          elem?.investThresholds[1].sum &&
                                        handleValue <
                                          elem?.investThresholds[2].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[1]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") + " ₽ в год"
                                      : handleValue >=
                                          elem?.investThresholds[0].sum &&
                                        handleValue <
                                          elem?.investThresholds[1].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[0]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") + " ₽ в год"
                                      : Number(
                                          (handleValue *
                                            elem?.investThresholds[0]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") + " ₽ в год"
                                    : //проверка на существование 2го в массиве порогов
                                    elem?.investThresholds[1]
                                    ? handleValue >=
                                      elem?.investThresholds[1].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[1]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") + " ₽ в год"
                                      : handleValue >=
                                          elem?.investThresholds[0].sum &&
                                        handleValue <
                                          elem?.investThresholds[1].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[0]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") + " ₽ в год"
                                      : Number(
                                          (handleValue *
                                            elem?.investThresholds[0]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") + " ₽ в год"
                                    : //если есть только 1, то его и берем
                                      Number(
                                        (handleValue *
                                          elem?.investThresholds[0]?.percent) /
                                          100
                                      ).toLocaleString("ru-RU") + " ₽ в год"
                                }
                              </p>
                            ) : (
                              <p className="small_mobail_after_click____income_money__numbers">
                                {
                                  //проверка на существование 5го в массиве порогов
                                  elem?.investThresholds[4]
                                    ? handleValue >=
                                      elem?.investThresholds[4].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[4]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") +
                                        " ₽ per year"
                                      : handleValue >=
                                          elem?.investThresholds[3].sum &&
                                        handleValue <
                                          elem?.investThresholds[4].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[3]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") +
                                        " ₽ per year"
                                      : handleValue >=
                                          elem?.investThresholds[2].sum &&
                                        handleValue <
                                          elem?.investThresholds[3].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[2]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") +
                                        " ₽ per year"
                                      : handleValue >=
                                          elem?.investThresholds[1].sum &&
                                        handleValue <
                                          elem?.investThresholds[2].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[1]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") +
                                        " ₽ per year"
                                      : handleValue >=
                                          elem?.investThresholds[0].sum &&
                                        handleValue <
                                          elem?.investThresholds[1].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[0]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") +
                                        " ₽ per year"
                                      : Number(
                                          (handleValue *
                                            elem?.investThresholds[0]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") +
                                        " ₽ per year"
                                    : //проверка на существование 4го в массиве порогов
                                    elem?.investThresholds[3]
                                    ? handleValue >=
                                      elem?.investThresholds[3].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[3]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") +
                                        " ₽ per year"
                                      : handleValue >=
                                          elem?.investThresholds[2].sum &&
                                        handleValue <
                                          elem?.investThresholds[3].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[2]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") +
                                        " ₽ per year"
                                      : handleValue >=
                                          elem?.investThresholds[1].sum &&
                                        handleValue <
                                          elem?.investThresholds[2].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[1]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") +
                                        " ₽ per year"
                                      : handleValue >=
                                          elem?.investThresholds[0].sum &&
                                        handleValue <
                                          elem?.investThresholds[1].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[0]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") +
                                        " ₽ per year"
                                      : Number(
                                          (handleValue *
                                            elem?.investThresholds[0]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") +
                                        " ₽ per year"
                                    : //проверка на существование 3го в массиве порогов
                                    elem?.investThresholds[2]
                                    ? handleValue >=
                                      elem?.investThresholds[2].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[2]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") +
                                        " ₽ per year"
                                      : handleValue >=
                                          elem?.investThresholds[1].sum &&
                                        handleValue <
                                          elem?.investThresholds[2].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[1]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") +
                                        " ₽ per year"
                                      : handleValue >=
                                          elem?.investThresholds[0].sum &&
                                        handleValue <
                                          elem?.investThresholds[1].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[0]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") +
                                        " ₽ per year"
                                      : Number(
                                          (handleValue *
                                            elem?.investThresholds[0]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") +
                                        " ₽ per year"
                                    : //проверка на существование 2го в массиве порогов
                                    elem?.investThresholds[1]
                                    ? handleValue >=
                                      elem?.investThresholds[1].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[1]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") +
                                        " ₽ per year"
                                      : handleValue >=
                                          elem?.investThresholds[0].sum &&
                                        handleValue <
                                          elem?.investThresholds[1].sum
                                      ? Number(
                                          (handleValue *
                                            elem?.investThresholds[0]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") +
                                        " ₽ per year"
                                      : Number(
                                          (handleValue *
                                            elem?.investThresholds[0]
                                              ?.percent) /
                                            100
                                        ).toLocaleString("ru-RU") +
                                        " ₽ per year"
                                    : //если есть только 1, то его и берем
                                      Number(
                                        (handleValue *
                                          elem?.investThresholds[0]?.percent) /
                                          100
                                      ).toLocaleString("ru-RU") + " ₽ per year"
                                }
                              </p>
                            )}
                            <p className="small_mobail_after_click____income_money__description">
                              {language === "RU"
                                ? "Доходность в год"
                                : "Profitability per year"}
                            </p>
                          </div>
                          <div className="small_mobail_after_click____income_percent">
                            {/* процент */}
                            {language === "RU" ? (
                              <p className="small_mobail_after_click____income_percent__numbers">
                                {
                                  //проверка на существование 5го в массиве порогов
                                  elem?.investThresholds[4]
                                    ? handleValue >=
                                      elem?.investThresholds[4].sum
                                      ? elem?.investThresholds[4]?.percent +
                                        "% годовых"
                                      : handleValue >=
                                          elem?.investThresholds[3].sum &&
                                        handleValue <
                                          elem?.investThresholds[4].sum
                                      ? elem?.investThresholds[3]?.percent +
                                        "% годовых"
                                      : handleValue >=
                                          elem?.investThresholds[2].sum &&
                                        handleValue <
                                          elem?.investThresholds[3].sum
                                      ? elem?.investThresholds[2]?.percent +
                                        "% годовых"
                                      : handleValue >=
                                          elem?.investThresholds[1].sum &&
                                        handleValue <
                                          elem?.investThresholds[2].sum
                                      ? elem?.investThresholds[1]?.percent +
                                        "% годовых"
                                      : handleValue >=
                                          elem?.investThresholds[0].sum &&
                                        handleValue <
                                          elem?.investThresholds[1].sum
                                      ? elem?.investThresholds[0]?.percent + {}
                                      : elem?.investThresholds[0]?.percent +
                                        "% годовых"
                                    : //проверка на существование 4го в массиве порогов
                                    elem?.investThresholds[3]
                                    ? handleValue >=
                                      elem?.investThresholds[3].sum
                                      ? elem?.investThresholds[3]?.percent +
                                        "% годовых"
                                      : handleValue >=
                                          elem?.investThresholds[2].sum &&
                                        handleValue <
                                          elem?.investThresholds[3].sum
                                      ? elem?.investThresholds[2]?.percent +
                                        "% годовых"
                                      : handleValue >=
                                          elem?.investThresholds[1].sum &&
                                        handleValue <
                                          elem?.investThresholds[2].sum
                                      ? elem?.investThresholds[1]?.percent +
                                        "% годовых"
                                      : handleValue >=
                                          elem?.investThresholds[0].sum &&
                                        handleValue <
                                          elem?.investThresholds[1].sum
                                      ? elem?.investThresholds[0]?.percent +
                                        "% годовых"
                                      : elem?.investThresholds[0]?.percent +
                                        "% годовых"
                                    : //проверка на существование 3го в массиве порогов
                                    elem?.investThresholds[2]
                                    ? handleValue >=
                                      elem?.investThresholds[2].sum
                                      ? elem?.investThresholds[2]?.percent +
                                        "% годовых"
                                      : handleValue >=
                                          elem?.investThresholds[1].sum &&
                                        handleValue <
                                          elem?.investThresholds[2].sum
                                      ? elem?.investThresholds[1]?.percent +
                                        "% годовых"
                                      : handleValue >=
                                          elem?.investThresholds[0].sum &&
                                        handleValue <
                                          elem?.investThresholds[1].sum
                                      ? elem?.investThresholds[0]?.percent +
                                        "% годовых"
                                      : elem?.investThresholds[0]?.percent +
                                        "% годовых"
                                    : //проверка на существование 2го в массиве порогов
                                    elem?.investThresholds[1]
                                    ? handleValue >=
                                      elem?.investThresholds[1].sum
                                      ? elem?.investThresholds[1]?.percent +
                                        "% годовых"
                                      : handleValue >=
                                          elem?.investThresholds[0].sum &&
                                        handleValue <
                                          elem?.investThresholds[1].sum
                                      ? elem?.investThresholds[0]?.percent +
                                        "% годовых"
                                      : elem?.investThresholds[0]?.percent +
                                        "% годовых"
                                    : //если есть только 1, то его и берем
                                      elem?.investThresholds[0]?.percent +
                                      "% годовых"
                                }
                              </p>
                            ) : (
                              <p className="small_mobail_after_click____income_percent__numbers">
                                {
                                  //проверка на существование 5го в массиве порогов
                                  elem?.investThresholds[4]
                                    ? handleValue >=
                                      elem?.investThresholds[4].sum
                                      ? elem?.investThresholds[4]?.percent +
                                        "% per year"
                                      : handleValue >=
                                          elem?.investThresholds[3].sum &&
                                        handleValue <
                                          elem?.investThresholds[4].sum
                                      ? elem?.investThresholds[3]?.percent +
                                        "% per year"
                                      : handleValue >=
                                          elem?.investThresholds[2].sum &&
                                        handleValue <
                                          elem?.investThresholds[3].sum
                                      ? elem?.investThresholds[2]?.percent +
                                        "% per year"
                                      : handleValue >=
                                          elem?.investThresholds[1].sum &&
                                        handleValue <
                                          elem?.investThresholds[2].sum
                                      ? elem?.investThresholds[1]?.percent +
                                        "% per year"
                                      : handleValue >=
                                          elem?.investThresholds[0].sum &&
                                        handleValue <
                                          elem?.investThresholds[1].sum
                                      ? elem?.investThresholds[0]?.percent + {}
                                      : elem?.investThresholds[0]?.percent +
                                        "% per year"
                                    : //проверка на существование 4го в массиве порогов
                                    elem?.investThresholds[3]
                                    ? handleValue >=
                                      elem?.investThresholds[3].sum
                                      ? elem?.investThresholds[3]?.percent +
                                        "% per year"
                                      : handleValue >=
                                          elem?.investThresholds[2].sum &&
                                        handleValue <
                                          elem?.investThresholds[3].sum
                                      ? elem?.investThresholds[2]?.percent +
                                        "% per year"
                                      : handleValue >=
                                          elem?.investThresholds[1].sum &&
                                        handleValue <
                                          elem?.investThresholds[2].sum
                                      ? elem?.investThresholds[1]?.percent +
                                        "% per year"
                                      : handleValue >=
                                          elem?.investThresholds[0].sum &&
                                        handleValue <
                                          elem?.investThresholds[1].sum
                                      ? elem?.investThresholds[0]?.percent +
                                        "% per year"
                                      : elem?.investThresholds[0]?.percent +
                                        "% per year"
                                    : //проверка на существование 3го в массиве порогов
                                    elem?.investThresholds[2]
                                    ? handleValue >=
                                      elem?.investThresholds[2].sum
                                      ? elem?.investThresholds[2]?.percent +
                                        "% per year"
                                      : handleValue >=
                                          elem?.investThresholds[1].sum &&
                                        handleValue <
                                          elem?.investThresholds[2].sum
                                      ? elem?.investThresholds[1]?.percent +
                                        "% per year"
                                      : handleValue >=
                                          elem?.investThresholds[0].sum &&
                                        handleValue <
                                          elem?.investThresholds[1].sum
                                      ? elem?.investThresholds[0]?.percent +
                                        "% per year"
                                      : elem?.investThresholds[0]?.percent +
                                        "% per year"
                                    : //проверка на существование 2го в массиве порогов
                                    elem?.investThresholds[1]
                                    ? handleValue >=
                                      elem?.investThresholds[1].sum
                                      ? elem?.investThresholds[1]?.percent +
                                        "% per year"
                                      : handleValue >=
                                          elem?.investThresholds[0].sum &&
                                        handleValue <
                                          elem?.investThresholds[1].sum
                                      ? elem?.investThresholds[0]?.percent +
                                        "% per year"
                                      : elem?.investThresholds[0]?.percent +
                                        "% per year"
                                    : //если есть только 1, то его и берем
                                      elem?.investThresholds[0]?.percent +
                                      "% per year"
                                }
                              </p>
                            )}
                            <p className="small_mobail_after_click____income_percent__numbers_description">
                              {language === "RU" ? "Доход" : "Income"}
                            </p>
                          </div>
                          {/* <div className="small_mobail_after_click_protections">
                            {elem.support[0] !== undefined ? (
                              <div>
                                <p className="small_mobail_after_click_protections__title">Обеспечение по проекту:</p>
                                {elem.support.map((element) =>
                                  handleValue >= +element.need_to_invest ? (
                                    <div
                                      key={element.key + "small_mobail_after_click_protections"}
                                      className={element.key}
                                      onClick={(event) => findElementOnTap(event, element.key)}
                                    >
                                      <div className="small_mobail_after_click_protections__list">
                                        <p className="small_mobail_after_click_protections__no_provide">{element.provide}</p>
                                        <div className="small_mobail_after_click_protections__question"></div>
                                        <p
                                          className="small_mobail_after_click_protections__provide_description"
                                          style={{ display: useKey === element.key && showDescription ? "block" : "none" }}
                                        >
                                          {element.provide_descriptoin}
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className="small_mobail_after_click_protections__no_provide"
                                      // style={{ display: element.need_to_invest ? "none" : "block" }}
                                      key={element.key + Math.random()}
                                    >
                                      Для получения дополнительных гарантий увеличьте сумму инестиций
                                    </div>
                                  )
                                )}
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div> */}
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <div className="small_mobail_after_click__links">
                        <a
                          className="small_mobail_after_click__telegramm"
                          href={elem.socialNetworks}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                        </a>
                        <Link
                          to={elem.name}
                          className="small_mobail_after_click__outside_link"
                        >
                          {language === "RU" ? "Подробнее" : "More"}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              // 5. Инвестиции больше минимального? РИСУЕМ ЦВЕТНУЮ карточку
              <div
                className="small_mobail_project__card"
                key={elem.uuid + "small_mobail_project__card2"}
              >
                {/* 2.  Див с видимой и невидимой частью карточки */}
                <div
                  className={
                    openFullProjectDescription && useProjectKey === elem.uuid
                      ? "small_mobail_project_card__color small_mobail_project_card__color__open"
                      : "small_mobail_project_card__color"
                  }
                >
                  {/* 3. Всегда видимая часть */}
                  <div
                    className={elem.uuid}
                    onClick={(event) => findProjectOnTap(event, elem.uuid)}
                  >
                    <div
                      className="small_mobail_project_card__color_visible"
                      style={{
                        backgroundImage: `url('https://frontiersserver.ru${elem.projectImages.background.substring(
                          24
                        )}')`,
                      }}
                      onClick={() =>
                        setOpenFullProjectDescription(
                          !openFullProjectDescription
                        )
                      }
                    >
                      <div
                        className="small_mobail_project_card__logo"
                        style={{
                          backgroundImage: `url('https://frontiersserver.ru${elem.projectImages.logo.substring(
                            24
                          )}')`,
                        }}
                      ></div>
                      <div className="small_mobail_project_card__income">
                        {/* процент */}
                        <p className="small_mobail_project_card_income_percent_numbers">
                          {
                            //проверка на существование 5го в массиве порогов
                            elem?.investThresholds[4]
                              ? handleValue >= elem?.investThresholds[4].sum
                                ? elem?.investThresholds[4]?.percent +
                                  "% годовых"
                                : handleValue >=
                                    elem?.investThresholds[3].sum &&
                                  handleValue < elem?.investThresholds[4].sum
                                ? elem?.investThresholds[3]?.percent +
                                  "% годовых"
                                : handleValue >=
                                    elem?.investThresholds[2].sum &&
                                  handleValue < elem?.investThresholds[3].sum
                                ? elem?.investThresholds[2]?.percent +
                                  "% годовых"
                                : handleValue >=
                                    elem?.investThresholds[1].sum &&
                                  handleValue < elem?.investThresholds[2].sum
                                ? elem?.investThresholds[1]?.percent +
                                  "% годовых"
                                : handleValue >=
                                    elem?.investThresholds[0].sum &&
                                  handleValue < elem?.investThresholds[1].sum
                                ? elem?.investThresholds[0]?.percent +
                                  "% годовых"
                                : elem?.investThresholds[0]?.percent +
                                  "% годовых"
                              : //проверка на существование 4го в массиве порогов
                              elem?.investThresholds[3]
                              ? handleValue >= elem?.investThresholds[3].sum
                                ? elem?.investThresholds[3]?.percent +
                                  "% годовых"
                                : handleValue >=
                                    elem?.investThresholds[2].sum &&
                                  handleValue < elem?.investThresholds[3].sum
                                ? elem?.investThresholds[2]?.percent +
                                  "% годовых"
                                : handleValue >=
                                    elem?.investThresholds[1].sum &&
                                  handleValue < elem?.investThresholds[2].sum
                                ? elem?.investThresholds[1]?.percent +
                                  "% годовых"
                                : handleValue >=
                                    elem?.investThresholds[0].sum &&
                                  handleValue < elem?.investThresholds[1].sum
                                ? elem?.investThresholds[0]?.percent +
                                  "% годовых"
                                : elem?.investThresholds[0]?.percent +
                                  "% годовых"
                              : //проверка на существование 3го в массиве порогов
                              elem?.investThresholds[2]
                              ? handleValue >= elem?.investThresholds[2].sum
                                ? elem?.investThresholds[2]?.percent +
                                  "% годовых"
                                : handleValue >=
                                    elem?.investThresholds[1].sum &&
                                  handleValue < elem?.investThresholds[2].sum
                                ? elem?.investThresholds[1]?.percent +
                                  "% годовых"
                                : handleValue >=
                                    elem?.investThresholds[0].sum &&
                                  handleValue < elem?.investThresholds[1].sum
                                ? elem?.investThresholds[0]?.percent +
                                  "% годовых"
                                : elem?.investThresholds[0]?.percent +
                                  "% годовых"
                              : //проверка на существование 2го в массиве порогов
                              elem?.investThresholds[1]
                              ? handleValue >= elem?.investThresholds[1].sum
                                ? elem?.investThresholds[1]?.percent +
                                  "% годовых"
                                : handleValue >=
                                    elem?.investThresholds[0].sum &&
                                  handleValue < elem?.investThresholds[1].sum
                                ? elem?.investThresholds[0]?.percent +
                                  "% годовых"
                                : elem?.investThresholds[0]?.percent +
                                  "% годовых"
                              : //если есть только 1, то его и берем
                                elem?.investThresholds[0]?.percent + "% годовых"
                          }
                        </p>
                        <div className="small_mobail_project_card__income_money">
                          {/* счетчик суммы */}
                          <p className="small_mobail_project_card_income_money_numbers">
                            {
                              //проверка на существование 5го в массиве порогов
                              elem?.investThresholds[4]
                                ? handleValue >= elem?.investThresholds[4].sum
                                  ? Number(
                                      (handleValue *
                                        elem?.investThresholds[4]?.percent) /
                                        100
                                    ).toLocaleString("ru-RU") + " ₽ в год"
                                  : handleValue >=
                                      elem?.investThresholds[3].sum &&
                                    handleValue < elem?.investThresholds[4].sum
                                  ? Number(
                                      (handleValue *
                                        elem?.investThresholds[3]?.percent) /
                                        100
                                    ).toLocaleString("ru-RU") + " ₽ в год"
                                  : handleValue >=
                                      elem?.investThresholds[2].sum &&
                                    handleValue < elem?.investThresholds[3].sum
                                  ? Number(
                                      (handleValue *
                                        elem?.investThresholds[2]?.percent) /
                                        100
                                    ).toLocaleString("ru-RU") + " ₽ в год"
                                  : handleValue >=
                                      elem?.investThresholds[1].sum &&
                                    handleValue < elem?.investThresholds[2].sum
                                  ? Number(
                                      (handleValue *
                                        elem?.investThresholds[1]?.percent) /
                                        100
                                    ).toLocaleString("ru-RU") + " ₽ в год"
                                  : handleValue >=
                                      elem?.investThresholds[0].sum &&
                                    handleValue < elem?.investThresholds[1].sum
                                  ? Number(
                                      (handleValue *
                                        elem?.investThresholds[0]?.percent) /
                                        100
                                    ).toLocaleString("ru-RU") + " ₽ в год"
                                  : Number(
                                      (handleValue *
                                        elem?.investThresholds[0]?.percent) /
                                        100
                                    ).toLocaleString("ru-RU") + " ₽ в год"
                                : //проверка на существование 4го в массиве порогов
                                elem?.investThresholds[3]
                                ? handleValue >= elem?.investThresholds[3].sum
                                  ? Number(
                                      (handleValue *
                                        elem?.investThresholds[3]?.percent) /
                                        100
                                    ).toLocaleString("ru-RU") + " ₽ в год"
                                  : handleValue >=
                                      elem?.investThresholds[2].sum &&
                                    handleValue < elem?.investThresholds[3].sum
                                  ? Number(
                                      (handleValue *
                                        elem?.investThresholds[2]?.percent) /
                                        100
                                    ).toLocaleString("ru-RU") + " ₽ в год"
                                  : handleValue >=
                                      elem?.investThresholds[1].sum &&
                                    handleValue < elem?.investThresholds[2].sum
                                  ? Number(
                                      (handleValue *
                                        elem?.investThresholds[1]?.percent) /
                                        100
                                    ).toLocaleString("ru-RU") + " ₽ в год"
                                  : handleValue >=
                                      elem?.investThresholds[0].sum &&
                                    handleValue < elem?.investThresholds[1].sum
                                  ? Number(
                                      (handleValue *
                                        elem?.investThresholds[0]?.percent) /
                                        100
                                    ).toLocaleString("ru-RU") + " ₽ в год"
                                  : Number(
                                      (handleValue *
                                        elem?.investThresholds[0]?.percent) /
                                        100
                                    ).toLocaleString("ru-RU") + " ₽ в год"
                                : //проверка на существование 3го в массиве порогов
                                elem?.investThresholds[2]
                                ? handleValue >= elem?.investThresholds[2].sum
                                  ? Number(
                                      (handleValue *
                                        elem?.investThresholds[2]?.percent) /
                                        100
                                    ).toLocaleString("ru-RU") + " ₽ в год"
                                  : handleValue >=
                                      elem?.investThresholds[1].sum &&
                                    handleValue < elem?.investThresholds[2].sum
                                  ? Number(
                                      (handleValue *
                                        elem?.investThresholds[1]?.percent) /
                                        100
                                    ).toLocaleString("ru-RU") + " ₽ в год"
                                  : handleValue >=
                                      elem?.investThresholds[0].sum &&
                                    handleValue < elem?.investThresholds[1].sum
                                  ? Number(
                                      (handleValue *
                                        elem?.investThresholds[0]?.percent) /
                                        100
                                    ).toLocaleString("ru-RU") + " ₽ в год"
                                  : Number(
                                      (handleValue *
                                        elem?.investThresholds[0]?.percent) /
                                        100
                                    ).toLocaleString("ru-RU") + " ₽ в год"
                                : //проверка на существование 2го в массиве порогов
                                elem?.investThresholds[1]
                                ? handleValue >= elem?.investThresholds[1].sum
                                  ? Number(
                                      (handleValue *
                                        elem?.investThresholds[1]?.percent) /
                                        100
                                    ).toLocaleString("ru-RU") + " ₽ в год"
                                  : handleValue >=
                                      elem?.investThresholds[0].sum &&
                                    handleValue < elem?.investThresholds[1].sum
                                  ? Number(
                                      (handleValue *
                                        elem?.investThresholds[0]?.percent) /
                                        100
                                    ).toLocaleString("ru-RU") + " ₽ в год"
                                  : Number(
                                      (handleValue *
                                        elem?.investThresholds[0]?.percent) /
                                        100
                                    ).toLocaleString("ru-RU") + " ₽ в год"
                                : //если есть только 1, то его и берем
                                  Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                            }
                          </p>
                        </div>
                      </div>
                      <div
                        className={
                          openFullProjectDescription &&
                          useProjectKey === elem.uuid
                            ? "small_mobail_project_card__arrow_to_down small_mobail_project_card__arrow_to_up"
                            : "small_mobail_project_card__arrow_to_down"
                        }
                      ></div>
                    </div>
                  </div>
                  {/* 4. Раскрывающийся блок */}
                  <div className="small_mobail_after_click__project_block">
                    <div className="small_mobail_after_click__information_about_project">
                      <p className="small_mobail_after_click__description">
                        {language === "RU"
                          ? elem?.description
                          : elem?.descriptionEn}
                      </p>
                      <div className="small_mobail_after_click__handle_invest">
                        <p className="small_mobail_after_click__handle_invest__numbers">
                          {Number(handleValue).toLocaleString("ru-RU") + " ₽"}
                        </p>
                        <p className="small_mobail_after_click__handle_invest__description">
                          {language === "RU"
                            ? "Размер инвестиций"
                            : "Investment size"}
                        </p>
                      </div>
                      <div className="small_mobail_after_click____income_money">
                        {/* процент */}
                        <p className="small_mobail_after_click____income_money__numbers">
                          {
                            //проверка на существование 5го в массиве порогов
                            elem?.investThresholds[4]
                              ? handleValue >= elem?.investThresholds[4].sum
                                ? elem?.investThresholds[4]?.percent +
                                  "% годовых"
                                : handleValue >=
                                    elem?.investThresholds[3].sum &&
                                  handleValue < elem?.investThresholds[4].sum
                                ? elem?.investThresholds[3]?.percent +
                                  "% годовых"
                                : handleValue >=
                                    elem?.investThresholds[2].sum &&
                                  handleValue < elem?.investThresholds[3].sum
                                ? elem?.investThresholds[2]?.percent +
                                  "% годовых"
                                : handleValue >=
                                    elem?.investThresholds[1].sum &&
                                  handleValue < elem?.investThresholds[2].sum
                                ? elem?.investThresholds[1]?.percent +
                                  "% годовых"
                                : handleValue >=
                                    elem?.investThresholds[0].sum &&
                                  handleValue < elem?.investThresholds[1].sum
                                ? elem?.investThresholds[0]?.percent +
                                  "% годовых"
                                : elem?.investThresholds[0]?.percent +
                                  "% годовых"
                              : //проверка на существование 4го в массиве порогов
                              elem?.investThresholds[3]
                              ? handleValue >= elem?.investThresholds[3].sum
                                ? elem?.investThresholds[3]?.percent +
                                  "% годовых"
                                : handleValue >=
                                    elem?.investThresholds[2].sum &&
                                  handleValue < elem?.investThresholds[3].sum
                                ? elem?.investThresholds[2]?.percent +
                                  "% годовых"
                                : handleValue >=
                                    elem?.investThresholds[1].sum &&
                                  handleValue < elem?.investThresholds[2].sum
                                ? elem?.investThresholds[1]?.percent +
                                  "% годовых"
                                : handleValue >=
                                    elem?.investThresholds[0].sum &&
                                  handleValue < elem?.investThresholds[1].sum
                                ? elem?.investThresholds[0]?.percent +
                                  "% годовых"
                                : elem?.investThresholds[0]?.percent +
                                  "% годовых"
                              : //проверка на существование 3го в массиве порогов
                              elem?.investThresholds[2]
                              ? handleValue >= elem?.investThresholds[2].sum
                                ? elem?.investThresholds[2]?.percent +
                                  "% годовых"
                                : handleValue >=
                                    elem?.investThresholds[1].sum &&
                                  handleValue < elem?.investThresholds[2].sum
                                ? elem?.investThresholds[1]?.percent +
                                  "% годовых"
                                : handleValue >=
                                    elem?.investThresholds[0].sum &&
                                  handleValue < elem?.investThresholds[1].sum
                                ? elem?.investThresholds[0]?.percent +
                                  "% годовых"
                                : elem?.investThresholds[0]?.percent +
                                  "% годовых"
                              : //проверка на существование 2го в массиве порогов
                              elem?.investThresholds[1]
                              ? handleValue >= elem?.investThresholds[1].sum
                                ? elem?.investThresholds[1]?.percent +
                                  "% годовых"
                                : handleValue >=
                                    elem?.investThresholds[0].sum &&
                                  handleValue < elem?.investThresholds[1].sum
                                ? elem?.investThresholds[0]?.percent +
                                  "% годовых"
                                : elem?.investThresholds[0]?.percent +
                                  "% годовых"
                              : //если есть только 1, то его и берем
                                elem?.investThresholds[0]?.percent + "% годовых"
                          }
                        </p>
                        <p className="small_mobail_after_click____income_money__description">
                          {language === "RU"
                            ? "Доходность в год"
                            : "Profitability per year"}
                        </p>
                      </div>
                      <div className="small_mobail_after_click____income_percent">
                        {/* счетчик суммы */}
                        <p className="small_mobail_after_click____income_percent__numbers">
                          {
                            //проверка на существование 5го в массиве порогов
                            elem?.investThresholds[4]
                              ? handleValue >= elem?.investThresholds[4].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[4]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : handleValue >=
                                    elem?.investThresholds[3].sum &&
                                  handleValue < elem?.investThresholds[4].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[3]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : handleValue >=
                                    elem?.investThresholds[2].sum &&
                                  handleValue < elem?.investThresholds[3].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[2]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : handleValue >=
                                    elem?.investThresholds[1].sum &&
                                  handleValue < elem?.investThresholds[2].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[1]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : handleValue >=
                                    elem?.investThresholds[0].sum &&
                                  handleValue < elem?.investThresholds[1].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                              : //проверка на существование 4го в массиве порогов
                              elem?.investThresholds[3]
                              ? handleValue >= elem?.investThresholds[3].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[3]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : handleValue >=
                                    elem?.investThresholds[2].sum &&
                                  handleValue < elem?.investThresholds[3].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[2]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : handleValue >=
                                    elem?.investThresholds[1].sum &&
                                  handleValue < elem?.investThresholds[2].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[1]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : handleValue >=
                                    elem?.investThresholds[0].sum &&
                                  handleValue < elem?.investThresholds[1].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                              : //проверка на существование 3го в массиве порогов
                              elem?.investThresholds[2]
                              ? handleValue >= elem?.investThresholds[2].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[2]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : handleValue >=
                                    elem?.investThresholds[1].sum &&
                                  handleValue < elem?.investThresholds[2].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[1]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : handleValue >=
                                    elem?.investThresholds[0].sum &&
                                  handleValue < elem?.investThresholds[1].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                              : //проверка на существование 2го в массиве порогов
                              elem?.investThresholds[1]
                              ? handleValue >= elem?.investThresholds[1].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[1]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : handleValue >=
                                    elem?.investThresholds[0].sum &&
                                  handleValue < elem?.investThresholds[1].sum
                                ? Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                                : Number(
                                    (handleValue *
                                      elem?.investThresholds[0]?.percent) /
                                      100
                                  ).toLocaleString("ru-RU") + " ₽ в год"
                              : //если есть только 1, то его и берем
                                Number(
                                  (handleValue *
                                    elem?.investThresholds[0]?.percent) /
                                    100
                                ).toLocaleString("ru-RU") + " ₽ в год"
                          }
                        </p>
                        <p className="small_mobail_after_click____income_percent__numbers_description">
                          {language === "RU" ? "Доход" : "Income"}
                        </p>
                      </div>
                      <div className="small_mobail_after_click_protections">
                        {elem.support[0] !== undefined ? (
                          <div>
                            <p className="small_mobail_after_click_protections__title">
                              {language === "RU"
                                ? "Обеспечение по проекту:"
                                : "Project support:"}
                            </p>
                            {elem.support.map((element) =>
                              handleValue >= +element.need_to_invest ? (
                                <div
                                  key={
                                    element.key +
                                    "small_mobail_after_click_protections"
                                  }
                                  className={element.key}
                                  onClick={(event) =>
                                    findElementOnTap(event, element.key)
                                  }
                                >
                                  <div className="small_mobail_after_click_protections__list">
                                    <p className="small_mobail_after_click_protections__no_provide">
                                      {element.provide}
                                    </p>
                                    <div className="small_mobail_after_click_protections__question"></div>
                                    <p
                                      className="small_mobail_after_click_protections__provide_description"
                                      style={{
                                        display:
                                          useKey === element.key &&
                                          showDescription
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      {element.provide_descriptoin}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="small_mobail_after_click_protections__no_provide"
                                  // style={{ display: element.need_to_invest ? "none" : "block" }}
                                  key={element.key + Math.random()}
                                >
                                  {language === "RU"
                                    ? "Для получения дополнительных гарантий увеличьте сумму инестиций"
                                    : "To get additional guarantees, increase the amount of investment"}
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>

                      <div className="small_mobail_after_click__links">
                        <a
                          className="small_mobail_after_click__telegramm"
                          href={elem.socialNetworks}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                        </a>
                        <Link
                          to={elem.name}
                          className="small_mobail_after_click__outside_link"
                        >
                          {language === "RU" ? "Подробнее" : "More"}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : (
            <div
              className="projects_filter__cadrs_block_archive"
              key={elem.uuid + "project_filter__card_archive"}
            ></div>
          )
        )}
      </div>
    </div>
  );
}
