import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_PROJECT } from "../../apollo/query/query_projects";
import { Container } from "./Container/Container";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { Banner } from "./Banner/Banner";
import { Purpose } from "./Purpose/Purpose";
import { AboutProject } from "./AboutProject/AboutProject";
import { InfoList } from "./InfoList/InfoList";
import { NotFound } from "../NotFound/NotFound";
import { Gallery } from "./Gallery/Gallery";
import { OtherProjects } from "./OtherProjects/OtherProjects";
import { LoaderWrapper } from "./LoaderWrapper/LoaderWrapper";
import LastScreen from "../home/lastScreen/LastScreen";
import s from "./ProjectPage.module.css";
import { Section } from "./Section/Section";
import { useLanguage } from "../../providers/LanguageContext";

export const ProjectPage = ({ width, isTurnDevice, clientWidth }) => {
  const { currentLanguage } = useLanguage();
  const [isGallery, setIsGallery] = useState(false);
  const [arrGallery, setArrGallery] = useState([]);

  const { project } = useParams();

  const { loading, error, data } = useQuery(GET_PROJECT, {
    variables: {
      filter: {
        name: project,
      },
    },
  });

  useEffect(() => {
    // если данные не пришли
    if (!data) return;
    // если данные пришли, но неправильно указано имя
    if (data.projects === null) return;

    if (Object.keys(data.projects[0].projectImages.gallery).length === 0) {
      setIsGallery(false);
    } else {
      setIsGallery(true);
      setArrGallery([...data.projects[0].projectImages.gallery]);
    }
  }, [data]);

  useEffect(() => {
    // Applying on mount
    document.body.style.overflow = "scroll";
    // Applying on unmount
    return () => {
      document.body.style.overflow = "hidden";
    };
  }, []);

  if (loading) {
    return <LoaderWrapper />;
  }
  if (error) return <div>error</div>;

  return (
    <>
      <div
        className={
          data.projects !== null ? s.page__wrapper : s.page__wrapper_notFound
        }
      >
        {data.projects !== null && (
          <Header width={width} language={currentLanguage} />
        )}
        {data.projects !== null && (
          <>
            <Container className={s.project__wrapper} id={"returnToTop"}>
              <Banner project={project} language={currentLanguage} />
              <InfoList project={project} language={currentLanguage} />
              <Purpose project={project} language={currentLanguage} />
              <AboutProject project={project} language={currentLanguage} />
              {isGallery && (
                <Gallery
                  width={width}
                  project={project}
                  isGallery={isGallery}
                  arrGallery={arrGallery}
                  isTurnDevice={isTurnDevice}
                  clientWidth={clientWidth}
                  language={currentLanguage}
                />
              )}
              {/* <Section className={s.section__margin}> */}
              <LastScreen language={currentLanguage} />
              {/* </Section> */}
              <OtherProjects
                width={width}
                isTurnDevice={isTurnDevice}
                clientWidth={clientWidth}
                language={currentLanguage}
              />
            </Container>
          </>
        )}
        {data.projects !== null && <Footer language={currentLanguage} />}
        {data.projects === null && <NotFound />}
      </div>
    </>
  );
};
