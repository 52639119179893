import React, { useState, useRef, useEffect } from "react"
import { PopUp } from "../PopUp/PopUp"
import s from "./SliderGallery.module.css"

export const SliderGallery = ({ width, project, isGallery, arrGallery, isTurnDevice, clientWidth }) => {
  const slider = useRef(null)
  let [position, setPosition] = useState(0)
  //30.185vh - размер слайда + размер геп
  const [slideWidth] = useState(16.98)
  const [slideWidthMediumScreen] = useState(32.422)
  const [slideWidthSmallScreen] = useState(32.9427083333333)
  const [slideWidthExtraSmallScreen] = useState(89.096)
  const [counterPage, setCounterPage] = useState(1)
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)
  const minSwipeDistance = 50
  const [currentSlide, setCurrentSlide] = useState(0)
  const [picturePerSlide, setPicturePerSlide] = useState(width > 1024 ? 5 : width > 376 ? 3 : 1)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setPosition(0)
    setCounterPage(1)

    if (isTurnDevice) {
      slider.current.childNodes.forEach((el) => (el.style = `transform: translateX(${0}px`))
    }
  }, [width])

  useEffect(() => {
    setPicturePerSlide(clientWidth > 1024 ? 5 : clientWidth > 376 ? 3 : 1)
  }, [width])

  const onTouchStart = (e) => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe || isRightSwipe) isLeftSwipe ? nextHandler() : prevHandler()
  }

  const nextHandler = () => {
    if (clientWidth > 1024) {
      if (arrGallery.length < 5) return null
      if (position === -(slideWidth * (arrGallery.length - 5))) {
        setCounterPage(Math.ceil(arrGallery.length / 5))
        return null
      }
      setCounterPage(counterPage + 1)
      counterPage + 1 === Math.ceil(arrGallery.length / 5)
        ? setPosition((position -= (arrGallery.length - 5 * counterPage) * slideWidth))
        : setPosition((position -= 5 * slideWidth))
      slider.current.childNodes.forEach((el) => (el.style = `transform: translateX(${position}vw)`))
    } else if (clientWidth <= 1024 && clientWidth > 768) {
      if (position === -(slideWidthMediumScreen * (arrGallery.length - 3))) {
        setCounterPage(Math.ceil(arrGallery.length / 3))
        return null
      }
      setCounterPage(counterPage + 1)
      counterPage + 1 === Math.ceil(arrGallery.length / 3)
        ? setPosition((position -= (arrGallery.length - 3 * counterPage) * slideWidthMediumScreen))
        : setPosition((position -= 3 * slideWidthMediumScreen))
      slider.current.childNodes.forEach((el) => (el.style = `transform: translateX(${position}vw)`))
    } else if (clientWidth <= 768 && clientWidth > 376) {
      if (arrGallery.length < 3) return null
      if (position === -(slideWidthSmallScreen * (arrGallery.length - 3))) {
        setCounterPage(Math.ceil(arrGallery.length / 3))
        return null
      }
      setCounterPage(counterPage + 1)
      counterPage + 1 === Math.ceil(arrGallery.length / 3)
        ? setPosition((position -= (arrGallery.length - 3 * counterPage) * slideWidthSmallScreen))
        : setPosition((position -= 3 * slideWidthSmallScreen))
      slider.current.childNodes.forEach((el) => (el.style = `transform: translateX(${position}vw)`))
    } else {
      if (position === -(slideWidthExtraSmallScreen * (arrGallery.length - 1))) {
        setCounterPage(Math.ceil(arrGallery.length))
        return null
      }
      setCounterPage(counterPage + 1)
      counterPage + 1 === Math.ceil(arrGallery.length)
        ? setPosition((position = position - (arrGallery.length - 1 * counterPage) * slideWidthExtraSmallScreen))
        : setPosition((position = position - 1 * slideWidthExtraSmallScreen))
      slider.current.childNodes.forEach((el) => (el.style = `transform: translateX(${position}vw)`))
    }
  }

  const prevHandler = () => {
    if (clientWidth > 1024) {
      if (position === 0) {
        setCounterPage(1)
        return null
      }
      if (counterPage === 1) return setPosition(0)

      if (counterPage === Math.ceil(arrGallery.length / 5)) {
        setPosition((position = position + (arrGallery.length % 5 === 0 ? 5 : arrGallery.length % 5) * slideWidth))
        setCounterPage(counterPage - 1)
      } else {
        setPosition((position = position + 5 * slideWidth))
        setCounterPage(counterPage - 1)
      }
      slider.current.childNodes.forEach((el) => (el.style = `transform: translateX(${position}vw)`))
    } else if (clientWidth <= 1024 && clientWidth > 768) {
      if (position === 0) {
        setCounterPage(1)
        return null
      }
      if (counterPage === 1) return setPosition(0)

      if (counterPage === Math.ceil(arrGallery.length / 3)) {
        setPosition((position = position + (arrGallery.length % 3 === 0 ? 3 : arrGallery.length % 3) * slideWidthMediumScreen))
        setCounterPage(counterPage - 1)
      } else {
        setPosition((position = position + 3 * slideWidthMediumScreen))
        setCounterPage(counterPage - 1)
      }
      slider.current.childNodes.forEach((el) => (el.style = `transform: translateX(${position}vw)`))
    } else if (clientWidth <= 768 && clientWidth > 376) {
      if (position === 0) {
        setCounterPage(1)
        return null
      }
      if (counterPage === 1) return setPosition(0)

      if (counterPage === Math.ceil(arrGallery.length / 3)) {
        setPosition((position = position + (arrGallery.length % 3 === 0 ? 3 : arrGallery.length % 3) * slideWidthSmallScreen))
        setCounterPage(counterPage - 1)
      } else {
        setPosition((position = position + 3 * slideWidthSmallScreen))
        setCounterPage(counterPage - 1)
      }
      slider.current.childNodes.forEach((el) => (el.style = `transform: translateX(${position}vw)`))
    } else {
      if (position === 0) {
        setCounterPage(1)
        return null
      }
      if (counterPage === 1) return setPosition(0)

      if (counterPage === Math.ceil(arrGallery.length)) {
        setPosition((position = position + 1 * slideWidthExtraSmallScreen))

        setCounterPage(counterPage - 1)
      } else {
        setPosition((position = position + 1 * slideWidthExtraSmallScreen))
        setCounterPage(counterPage - 1)
      }
      slider.current.childNodes.forEach((el) => (el.style = `transform: translateX(${position}vw)`))
    }
  }

  const openPopUp = (e) => {
    setIsOpen(!isOpen)
    setCurrentSlide(e.target.id)
  }

  return (
    <>
      {isGallery && (
        <div className={s.slider} ref={slider} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
          {arrGallery.map((el, index) => (
            <div className={s.slider__image__wrapper} key={`arrGallery_${index}`}>
              <div
                id={index}
                className={s.slider__image}
                key={`arrGallery_${index}`}
                onClick={(index) => openPopUp(index)}
                style={{
                  backgroundImage: `url('${el}')`
                }}
              ></div>
            </div>
          ))}
        </div>
      )}

      <div className={s.navigation}>
        <div className={s.navigation_wrapper}>
          <div className={s.navigation_prevBtn} onClick={prevHandler}>
            <div className={s.navigation_prevBtn_image}></div>
          </div>
          <div className={s.navigation__values}>
            <span>{counterPage}</span>
            {/* <span id="goToForm">/</span> */}
            <span>/</span>
            <span>{Math.ceil(arrGallery.length / picturePerSlide)}</span>
          </div>
          <div className={s.navigation_nextBtn} onClick={nextHandler}>
            <div className={s.navigation_nextBtn_image}></div>
          </div>
        </div>
      </div>

      <PopUp openPopUp={openPopUp} data={arrGallery} currentSlide={currentSlide} arrLength={arrGallery.length} isOpen={isOpen} />
    </>
  )
}
