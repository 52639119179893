import React, { useState, useRef, useEffect } from "react";
import s from "./DropdownCountry.module.css";
import arrow_up from "../img/arrow-up.svg";
import arrow_down from "../img/arrow-down.svg";
import questionIcon from "../img/quastion.svg";
import { DropdownCountryItem } from "./DropdownCountryItem/DropdownCountryItem";
import { countries } from "../DataProjects";

export const DropdownCountry = ({
  className,
  selectedCountries = [],
  changeSelectedCountries,
  setSelectedCountries,
  language,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  // Контролирует отображение подсказки
  const [showDescription, setShowDescription] = useState(false);

  const openHandler = () => {
    if (selectedCountries.length === 0) {
      setSelectedCountries([countries[0]]);
    }
    setIsOpen(!isOpen);
  };

  // Закрытие dropdown при клике вне его
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className={`${s.dropdown} ${className}`} ref={dropdownRef}>
      <div className={s.dropdown__label}>
        <p
          className={s.dropdown__description}
          style={{
            display: showDescription ? "block" : "none", // Контролирует отображение подсказки в зависимости от состояния
          }}
        >
          {language === "RU"
            ? "Выберите страну в которой хотите инвестировать"
            : "Choose a country you would like to invest into"}
        </p>

        <p>{language === "RU" ? "Выбрать страну" : "Choose a country"}</p>
        <img
          className={s.dropdown__question}
          src={questionIcon}
          alt="prompt"
          onClick={() => setShowDescription(!showDescription)} // Меняет состояние подсказки при клике на иконку вопроса
        />
      </div>
      <div className={` ${s.dropdown__container} `}>
        <div className={s.dropdown__header} onClick={openHandler}>
          {selectedCountries.map((country) => {
            return (
              <img
                key={country.country}
                className={s.dropdown__flag}
                src={country.flagUrl}
                alt={country.country}
              />
            );
          })}
          <img
            className={s.dropdown__arrow}
            src={isOpen ? arrow_up : arrow_down}
            alt="arrow"
          />
        </div>
        {isOpen &&
          countries.map((country, index) => {
            return (
              <DropdownCountryItem
                className={`${s.dropdown__item} ${
                  index === 0 ? s.dropdown__item_first : ""
                }`}
                key={country.country}
                changeSelectedCountries={changeSelectedCountries}
                flag={country.flagUrl}
                country={country.country}
                countryEn={country.countryEn}
                language={language}
                isChecked={selectedCountries.some(
                  (c) => c.country === country.country
                )}
              />
            );
          })}
      </div>
    </div>
  );
};

export default DropdownCountry;
