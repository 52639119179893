import React from "react";
import { useQuery } from "@apollo/client";
import { GET_MAINPAGE } from "../../../apollo/query/query_mainPage";
import { useLanguage } from "../../../providers/LanguageContext";

export function FirstScreen({ width, language }) {
  const { data } = useQuery(GET_MAINPAGE);


  return (
    <section className="firstscreen">
      <div className="firstscreen__content" id="top">
        <div className="firstscreen__content_left_text">
          <p>{language === "RU" ? "ИНВЕСТИЦИИ" : "INVESTMENT"}</p>
          <div>
            <p className="firstscreen__content_left_text_hard">
              {language === "RU" ? "СЛОЖНО" : "EASY"}
            </p>
          </div>
        </div>
        <div className="firstscreen__content_rihgt_text">
          <div className="firstscreen__content_rihgt_text_up">
            <p>
              {language === "RU" ? data?.page?.heading : data?.page?.headingEn}
            </p>
          </div>
          <div>
            <p className="firstscreen__content_rihgt_text_down">
              {language === "RU" ? "НЕ" : "is"}
            </p>
          </div>
        </div>
      </div>
      <div className="firstscreen__content_bottom_content">
        <div className="firstscreen__content_bottom_content_left">
          <p className="firstscreen__content_bottom_content_left_scrolldown">
            {language === "RU" ? "ПРОКРУТИТЕ ВНИЗ" : "SCROLL DOWN"}
          </p>
        </div>
        <div className="firstscreen__text_description">
          <p>
            {" "}
            {language === "RU" ? data?.page?.heading : data?.page?.headingEn}
          </p>
        </div>
        <div
          className="firstscreen__content_bottom_content_right"
          style={{
            backgroundImage:
              width > 1024
                ? `url('${data?.page?.photo}')`
                : `url('./img/main_img_1024.webp')`,
          }}
        ></div>
      </div>
    </section>
  );
}
