import React from "react";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { Navigation } from "./Navigation/Navigation";
import { InterviewSection } from "./InterviewSection/InterviewSection";
import { EventsSection } from "./EventsSection/EventsSection";
import { ReviewSection } from "./ReviewSection/ReviewSection";
import { NewsFeed } from "./newsFeed/NewsFeed";
import LastScreen from "../home/lastScreen/LastScreen";
import { Layout } from "../../layouts/Layout";
import { useLanguage } from "../../providers/LanguageContext";

export const Media = ({ width, clientWidth, isTurnDevice }) => {
  const { currentLanguage } = useLanguage();
  return (
    <Layout width={width}>
      {/* <TextSliderVideo clientWidth={clientWidth} /> */}
      <Navigation clientWidth={clientWidth} language={currentLanguage} />
      <InterviewSection
        width={width}
        clientWidth={clientWidth}
        id="media_interview"
        isTurnDevice={isTurnDevice}
        language={currentLanguage}
      />
      <NewsFeed language={currentLanguage} />
      <EventsSection
        width={width}
        clientWidth={clientWidth}
        isTurnDevice={isTurnDevice}
        language={currentLanguage}
      />
      <ReviewSection
        width={width}
        clientWidth={clientWidth}
        isTurnDevice={isTurnDevice}
        language={currentLanguage}
      />
      <LastScreen language={currentLanguage} />
    </Layout>
  );
};
