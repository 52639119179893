import clsx from "clsx";
import s from "./Loader.module.css";

// interface loaderType {
//     light: string,
//     warn: string
// }

// const themes: loaderType = {
//   light: s.light,
//   warn: s.warn,
// }

// type loaderProps = {
//     className?: string
//     theme?: keyof typeof themes
// }

// eslint-disable-next-line arrow-body-style
export const Loader = ({ className, theme }) => {
  return (
    // <div className={clsx(s.lds_grid, className, theme ? themes[theme] : null)}>
    <div className={clsx(s.lds_grid)}>
      <div />
      <div />
      <div />
    </div>
  );
};
