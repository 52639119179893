export const convertToDD_MMM_YYYY = (dateTimeString, language = "RU") => {
  const months = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  const monthsEN = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  const date = new Date(dateTimeString);
  const day = date.getDate();

  if (language === "RU") {
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  } else {
    const month = monthsEN[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  // const month = monthsEN[date.getMonth()];
  // const year = date.getFullYear();

  // return `${day} ${month} ${year}`;
};
