import React from "react";
import { MediaSectionTitle } from "../MediaSectionTItle/MediaSectionTitle";
import { Section } from "../../ProjectPage/Section/Section";
import s from "./ReviewSection.module.css";
import { TextSlider } from "../TextSlider/TextSlider";

export const ReviewSection = ({
  clientWidth,
  width,
  isTurnDevice,
  language,
}) => {
  return (
    <>
      <Section className={s.wrapper}>
        <MediaSectionTitle
          text1={language === "RU" ? "Что говорят" : " WHAT DO THEY SAY"}
          text2={language === "RU" ? "Наши инвесторы" : " OUR INVESTORS"}
        />
        <TextSlider
          clientWidth={clientWidth}
          width={width}
          isTurnDevice={isTurnDevice}
          language={language}
        />
      </Section>
    </>
  );
};
