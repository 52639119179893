import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const pagePath = location.pathname + location.search;

    // Трекинг Yandex.Metrika
    if (window.ym) {
      window.ym(96020637, "hit", pagePath, {
        title: document.title,
        referer: document.referrer,
      });
    }

    // Трекинг Google Analytics
    if (window.gtag) {
      window.gtag("config", "G-T4GJ74LPCS", {
        page_path: pagePath,
      });
    }
  }, [location]); // Зависимость от location, чтобы реагировать на его изменения
};